import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';
import { IRound } from '../interfaces/tournamentModels';
import commonStyles from '../styles/common.module.scss';
import ClearIcon from '@mui/icons-material/Clear';
import { ITeams } from '../interfaces/teamModels';
import { printTeamName } from '../utils/teamHelper';

interface FindFieldModalProps {
    id: string;
    roundData: IRound | undefined;
    onClose: () => void;
    open: boolean;
    teams: ITeams;
}

const FindFieldModal: React.FC<FindFieldModalProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(props.open);
    const [teamId, setTeamId] = React.useState<string>('');

    const [selectableTeams, setSelectableTeams] = React.useState<{ teamId: string, teamName: string }[] | undefined>(undefined);

    const handleTeamChange = (event: SelectChangeEvent) => {
        setTeamId(event.target.value as string);
    };

    const handleClose = (_event: object, reason: string) => {
        if (reason === 'backdropClick') return;

        props.onClose();

        setOpen(false);
    }

    React.useEffect(() => {
        const selectTeams: { teamId: string, teamName: string }[] = [];
        (props.teams as ITeams).teams.forEach(t => {
            selectTeams.push({ teamId: t.teamId, teamName: printTeamName(t) });
        })
        setSelectableTeams(selectTeams);
    }, [])

    React.useEffect(() => {
        setOpen(props.open);
        if (props.open) {
            setTeamId('');
        }
    }, [props.open])

    return (
        <Modal
            key={`find-field-modal-${props.id}`}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className={commonStyles.modalStyle}
            >
                <Grid container spacing={2} key={`find-field-modal-container-${props.id}`} justifyContent='flex-start'>
                    <Grid item xs={12} key={`find-field-modal-cancel-${props.id}`} className={commonStyles.rightAlign}>
                        <IconButton aria-label="cancel"
                            className={commonStyles.noEmptyTopBottom}
                            size="medium"
                            color="error"
                            onClick={(event) => {
                                handleClose(event, 'cancel');
                            }}>
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} key={`penalty-round-teamid`}>
                        <Box sx={{ minWidth: 120 }} className={commonStyles.noPrint}>
                            <FormControl fullWidth>
                                <InputLabel id="choose-team-label">Valitse joukkue:</InputLabel>
                                <Select
                                    labelId="choose-team-label"
                                    id="teamids"
                                    value={teamId}
                                    label="Valitse joukkue:"
                                    onChange={handleTeamChange}
                                >
                                    {selectableTeams && selectableTeams.map((val, i) => {
                                        return <MenuItem sx={{ whiteSpace: 'break-spaces' }} value={val.teamId} key={`all-team-select-${i + 1}`}>{val.teamName}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} key={`found-field-${props.id}`} className={commonStyles.leftAlign}>
                        <p><b>Kenttä: {props.roundData?.matches.find(m => m.team1Id === teamId || m.team2Id === teamId)?.fieldNumber ?? ''}</b></p>
                    </Grid>
                    <Grid item xs={12} key={`close-button-modal-yes-${props.id}`} className={commonStyles.rightAlign}>
                        <Button
                            variant="outlined"
                            aria-label="yes"
                            onClick={(event) => {
                                handleClose(event, 'close');
                            }}
                        >
                            Sulje
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>);
}

export default FindFieldModal;