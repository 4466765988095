import MatchStatus from "../common/enums/matchStatus";
import { ICupRound } from "../common/interfaces/tournamentModels";

export const getCupDefinitions = (maxTeamAmount: number, playAll: boolean, name: string, offsetPositions: number): ICupRound[] => {
  const definitions: ICupRound[] = [];
  const finalId: number = Date.now() + 10000 * offsetPositions;
  const bronzeId: number = finalId + 1;
  const semiFinalId: number = bronzeId + (playAll ? maxTeamAmount / 2 : 1);
  const quarterId: number = semiFinalId + (playAll ? maxTeamAmount / 2 : 2);
  const top16id: number = quarterId + (playAll ? maxTeamAmount / 2 : 4);
  const top32id: number = top16id + (playAll ? maxTeamAmount / 2 : 8);

  if (maxTeamAmount === 2) {
    definitions.push({
      amountOfTeamsInRound: 2,
      name: name,
      matches: [
        {
          matchId: finalId.toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 2 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          team1OriginalPosition: 1 + offsetPositions,
          team2OriginalPosition: 2 + offsetPositions,
        },
      ],
    });
  }

  if (maxTeamAmount >= 4) {
    definitions.push({
      amountOfTeamsInRound: 2,
      name: name,
      matches: [
        {
          matchId: finalId.toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 2 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
        },
        {
          matchId: bronzeId.toString(),
          winnerPosition: 3 + offsetPositions,
          loserPosition: 4 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
        },
      ],
    });

    if (playAll) {
      if (maxTeamAmount >= 8) {
        definitions
          .find((d) => d.amountOfTeamsInRound === 2)!
          .matches.push(
            ...[
              {
                matchId: (bronzeId + 1).toString(),
                winnerPosition: 5 + offsetPositions,
                loserPosition: 6 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 2).toString(),
                winnerPosition: 7 + offsetPositions,
                loserPosition: 8 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
            ]
          );
      }
      if (maxTeamAmount >= 16) {
        definitions
          .find((d) => d.amountOfTeamsInRound === 2)!
          .matches.push(
            ...[
              {
                matchId: (bronzeId + 3).toString(),
                winnerPosition: 9 + offsetPositions,
                loserPosition: 10 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 4).toString(),
                winnerPosition: 11 + offsetPositions,
                loserPosition: 12 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 5).toString(),
                winnerPosition: 13 + offsetPositions,
                loserPosition: 14 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 6).toString(),
                winnerPosition: 15 + offsetPositions,
                loserPosition: 16 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
            ]
          );
      }
      if (maxTeamAmount >= 32) {
        definitions
          .find((d) => d.amountOfTeamsInRound === 2)!
          .matches.push(
            ...[
              {
                matchId: (bronzeId + 7).toString(),
                winnerPosition: 17 + offsetPositions,
                loserPosition: 18 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 8).toString(),
                winnerPosition: 19 + offsetPositions,
                loserPosition: 20 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 9).toString(),
                winnerPosition: 21 + offsetPositions,
                loserPosition: 22 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 10).toString(),
                winnerPosition: 23 + offsetPositions,
                loserPosition: 24 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 11).toString(),
                winnerPosition: 25 + offsetPositions,
                loserPosition: 26 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 12).toString(),
                winnerPosition: 27 + offsetPositions,
                loserPosition: 28 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 13).toString(),
                winnerPosition: 29 + offsetPositions,
                loserPosition: 30 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
              {
                matchId: (bronzeId + 14).toString(),
                winnerPosition: 31 + offsetPositions,
                loserPosition: 32 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
              },
            ]
          );
      }
    }

    definitions.push({
      amountOfTeamsInRound: 4,
      name: name,
      matches: [
        {
          matchId: semiFinalId.toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 3 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: finalId.toString(),
          loserGoesId: bronzeId.toString(),
          team1OriginalPosition: 1 + offsetPositions,
          team2OriginalPosition: 4 + offsetPositions,
        },
        {
          matchId: (semiFinalId + 1).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 3 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: finalId.toString(),
          loserGoesId: bronzeId.toString(),
          team1OriginalPosition: 2 + offsetPositions,
          team2OriginalPosition: 3 + offsetPositions,
        },
      ],
    });
    if (playAll) {
      if (maxTeamAmount >= 8) {
        definitions
          .find((d) => d.amountOfTeamsInRound === 4)!
          .matches.push(
            ...[
              {
                matchId: (semiFinalId + 2).toString(),
                winnerPosition: 5 + offsetPositions,
                loserPosition: 7 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 1).toString(),
                loserGoesId: (bronzeId + 2).toString(),
              },
              {
                matchId: (semiFinalId + 3).toString(),
                winnerPosition: 5 + offsetPositions,
                loserPosition: 7 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 1).toString(),
                loserGoesId: (bronzeId + 2).toString(),
              },
            ]
          );
      }
      if (maxTeamAmount >= 16) {
        definitions
          .find((d) => d.amountOfTeamsInRound === 4)!
          .matches.push(
            ...[
              {
                matchId: (semiFinalId + 4).toString(),
                winnerPosition: 9 + offsetPositions,
                loserPosition: 11 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 3).toString(),
                loserGoesId: (bronzeId + 4).toString(),
              },
              {
                matchId: (semiFinalId + 5).toString(),
                winnerPosition: 9 + offsetPositions,
                loserPosition: 11 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 3).toString(),
                loserGoesId: (bronzeId + 4).toString(),
              },
              {
                matchId: (semiFinalId + 6).toString(),
                winnerPosition: 13 + offsetPositions,
                loserPosition: 15 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 5).toString(),
                loserGoesId: (bronzeId + 6).toString(),
              },
              {
                matchId: (semiFinalId + 7).toString(),
                winnerPosition: 13 + offsetPositions,
                loserPosition: 15 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 5).toString(),
                loserGoesId: (bronzeId + 6).toString(),
              },
            ]
          );
      }
      if (maxTeamAmount >= 32) {
        definitions
          .find((d) => d.amountOfTeamsInRound === 4)!
          .matches.push(
            ...[
              {
                matchId: (semiFinalId + 8).toString(),
                winnerPosition: 17 + offsetPositions,
                loserPosition: 19 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 7).toString(),
                loserGoesId: (bronzeId + 8).toString(),
              },
              {
                matchId: (semiFinalId + 9).toString(),
                winnerPosition: 17 + offsetPositions,
                loserPosition: 19 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 7).toString(),
                loserGoesId: (bronzeId + 8).toString(),
              },
              {
                matchId: (semiFinalId + 10).toString(),
                winnerPosition: 21 + offsetPositions,
                loserPosition: 23 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 9).toString(),
                loserGoesId: (bronzeId + 10).toString(),
              },
              {
                matchId: (semiFinalId + 11).toString(),
                winnerPosition: 21 + offsetPositions,
                loserPosition: 23 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 9).toString(),
                loserGoesId: (bronzeId + 10).toString(),
              },
              {
                matchId: (semiFinalId + 12).toString(),
                winnerPosition: 25 + offsetPositions,
                loserPosition: 27 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 11).toString(),
                loserGoesId: (bronzeId + 12).toString(),
              },
              {
                matchId: (semiFinalId + 13).toString(),
                winnerPosition: 25 + offsetPositions,
                loserPosition: 27 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 11).toString(),
                loserGoesId: (bronzeId + 12).toString(),
              },
              {
                matchId: (semiFinalId + 14).toString(),
                winnerPosition: 29 + offsetPositions,
                loserPosition: 31 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 13).toString(),
                loserGoesId: (bronzeId + 14).toString(),
              },
              {
                matchId: (semiFinalId + 15).toString(),
                winnerPosition: 29 + offsetPositions,
                loserPosition: 31 + offsetPositions,
                roundNumber: 0,
                status: MatchStatus.notStarted,
                team1Id: "",
                team2Id: "",
                winnerGoesId: (bronzeId + 13).toString(),
                loserGoesId: (bronzeId + 14).toString(),
              },
            ]
          );
      }
    }
  }

  if (maxTeamAmount >= 8) {
    definitions.push({
      amountOfTeamsInRound: 8,
      name: name,
      matches: [
        {
          matchId: quarterId.toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 5 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: semiFinalId.toString(),
          loserGoesId: playAll ? (semiFinalId + 2).toString() : undefined,
          team1OriginalPosition: 1 + offsetPositions,
          team2OriginalPosition: 8 + offsetPositions,
        },
        {
          matchId: (quarterId + 1).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 5 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: semiFinalId.toString(),
          loserGoesId: playAll ? (semiFinalId + 2).toString() : undefined,
          team1OriginalPosition: 4 + offsetPositions,
          team2OriginalPosition: 5 + offsetPositions,
        },
        {
          matchId: (quarterId + 2).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 5 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (semiFinalId + 1).toString(),
          loserGoesId: playAll ? (semiFinalId + 3).toString() : undefined,
          team1OriginalPosition: 3 + offsetPositions,
          team2OriginalPosition: 6 + offsetPositions,
        },
        {
          matchId: (quarterId + 3).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 5 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (semiFinalId + 1).toString(),
          loserGoesId: playAll ? (semiFinalId + 3).toString() : undefined,
          team1OriginalPosition: 2 + offsetPositions,
          team2OriginalPosition: 7 + offsetPositions,
        },
      ],
    });
  }

  if (playAll) {
    if (maxTeamAmount >= 16) {
      definitions
        .find((d) => d.amountOfTeamsInRound === 8)!
        .matches.push(
          ...[
            {
              matchId: (quarterId + 4).toString(),
              winnerPosition: 9 + offsetPositions,
              loserPosition: 13 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 4).toString(),
              loserGoesId: playAll ? (semiFinalId + 6).toString() : undefined,
            },
            {
              matchId: (quarterId + 5).toString(),
              winnerPosition: 9 + offsetPositions,
              loserPosition: 13 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 4).toString(),
              loserGoesId: playAll ? (semiFinalId + 6).toString() : undefined,
            },
            {
              matchId: (quarterId + 6).toString(),
              winnerPosition: 9 + offsetPositions,
              loserPosition: 13 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 5).toString(),
              loserGoesId: playAll ? (semiFinalId + 7).toString() : undefined,
            },
            {
              matchId: (quarterId + 7).toString(),
              winnerPosition: 9 + offsetPositions,
              loserPosition: 13 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 5).toString(),
              loserGoesId: playAll ? (semiFinalId + 7).toString() : undefined,
            },
          ]
        );
    }
    if (maxTeamAmount >= 32) {
      definitions
        .find((d) => d.amountOfTeamsInRound === 8)!
        .matches.push(
          ...[
            {
              matchId: (quarterId + 8).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 21 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 8).toString(),
              loserGoesId: playAll ? (semiFinalId + 10).toString() : undefined,
            },
            {
              matchId: (quarterId + 9).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 21 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 8).toString(),
              loserGoesId: playAll ? (semiFinalId + 10).toString() : undefined,
            },
            {
              matchId: (quarterId + 10).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 21 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 9).toString(),
              loserGoesId: playAll ? (semiFinalId + 11).toString() : undefined,
            },
            {
              matchId: (quarterId + 11).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 21 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 9).toString(),
              loserGoesId: playAll ? (semiFinalId + 11).toString() : undefined,
            },
            {
              matchId: (quarterId + 12).toString(),
              winnerPosition: 25 + offsetPositions,
              loserPosition: 29 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 12).toString(),
              loserGoesId: playAll ? (semiFinalId + 14).toString() : undefined,
            },
            {
              matchId: (quarterId + 13).toString(),
              winnerPosition: 25 + offsetPositions,
              loserPosition: 29 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 12).toString(),
              loserGoesId: playAll ? (semiFinalId + 14).toString() : undefined,
            },
            {
              matchId: (quarterId + 14).toString(),
              winnerPosition: 25 + offsetPositions,
              loserPosition: 29 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 13).toString(),
              loserGoesId: playAll ? (semiFinalId + 15).toString() : undefined,
            },
            {
              matchId: (quarterId + 15).toString(),
              winnerPosition: 25 + offsetPositions,
              loserPosition: 29 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (semiFinalId + 13).toString(),
              loserGoesId: playAll ? (semiFinalId + 15).toString() : undefined,
            },
          ]
        );
    }
  }

  if (maxTeamAmount >= 16) {
    definitions.push({
      amountOfTeamsInRound: 16,
      name: name,
      matches: [
        {
          matchId: top16id.toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: quarterId.toString(),
          loserGoesId: playAll ? (quarterId + 4).toString() : undefined,
          team1OriginalPosition: 1 + offsetPositions,
          team2OriginalPosition: 16 + offsetPositions,
        },
        {
          matchId: (top16id + 1).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: quarterId.toString(),
          loserGoesId: playAll ? (quarterId + 4).toString() : undefined,
          team1OriginalPosition: 8 + offsetPositions,
          team2OriginalPosition: 9 + offsetPositions,
        },
        {
          matchId: (top16id + 2).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (quarterId + 1).toString(),
          loserGoesId: playAll ? (quarterId + 5).toString() : undefined,
          team1OriginalPosition: 4 + offsetPositions,
          team2OriginalPosition: 13 + offsetPositions,
        },
        {
          matchId: (top16id + 3).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (quarterId + 1).toString(),
          loserGoesId: playAll ? (quarterId + 5).toString() : undefined,
          team1OriginalPosition: 5 + offsetPositions,
          team2OriginalPosition: 12 + offsetPositions,
        },
        {
          matchId: (top16id + 4).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (quarterId + 2).toString(),
          loserGoesId: playAll ? (quarterId + 6).toString() : undefined,
          team1OriginalPosition: 3 + offsetPositions,
          team2OriginalPosition: 14 + offsetPositions,
        },
        {
          matchId: (top16id + 5).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (quarterId + 2).toString(),
          loserGoesId: playAll ? (quarterId + 6).toString() : undefined,
          team1OriginalPosition: 6 + offsetPositions,
          team2OriginalPosition: 11 + offsetPositions,
        },
        {
          matchId: (top16id + 6).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (quarterId + 3).toString(),
          loserGoesId: playAll ? (quarterId + 7).toString() : undefined,
          team1OriginalPosition: 2 + offsetPositions,
          team2OriginalPosition: 15 + offsetPositions,
        },
        {
          matchId: (top16id + 7).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 9 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (quarterId + 3).toString(),
          loserGoesId: playAll ? (quarterId + 7).toString() : undefined,
          team1OriginalPosition: 7 + offsetPositions,
          team2OriginalPosition: 10 + offsetPositions,
        },
      ],
    });
  }

  if (playAll) {
    if (maxTeamAmount >= 32) {
      definitions
        .find((d) => d.amountOfTeamsInRound === 16)!
        .matches.push(
          ...[
            {
              matchId: (top16id + 8).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 8).toString(),
              loserGoesId: playAll ? (quarterId + 12).toString() : undefined,
            },
            {
              matchId: (top16id + 9).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 8).toString(),
              loserGoesId: playAll ? (quarterId + 12).toString() : undefined,
            },
            {
              matchId: (top16id + 10).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 9).toString(),
              loserGoesId: playAll ? (quarterId + 13).toString() : undefined,
            },
            {
              matchId: (top16id + 11).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 9).toString(),
              loserGoesId: playAll ? (quarterId + 13).toString() : undefined,
            },
            {
              matchId: (top16id + 12).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 10).toString(),
              loserGoesId: playAll ? (quarterId + 14).toString() : undefined,
            },
            {
              matchId: (top16id + 13).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 10).toString(),
              loserGoesId: playAll ? (quarterId + 14).toString() : undefined,
            },
            {
              matchId: (top16id + 14).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 11).toString(),
              loserGoesId: playAll ? (quarterId + 15).toString() : undefined,
            },
            {
              matchId: (top16id + 15).toString(),
              winnerPosition: 17 + offsetPositions,
              loserPosition: 25 + offsetPositions,
              roundNumber: 0,
              status: MatchStatus.notStarted,
              team1Id: "",
              team2Id: "",
              winnerGoesId: (quarterId + 11).toString(),
              loserGoesId: playAll ? (quarterId + 15).toString() : undefined,
            },
          ]
        );
    }
  }

  if (maxTeamAmount >= 32) {
    definitions.push({
      amountOfTeamsInRound: 32,
      name: name,
      matches: [
        {
          matchId: top32id.toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: top16id.toString(),
          loserGoesId: playAll ? (top16id + 8).toString() : undefined,
          team1OriginalPosition: 1 + offsetPositions,
          team2OriginalPosition: 32 + offsetPositions,
        },
        {
          matchId: (top32id + 1).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: top16id.toString(),
          loserGoesId: playAll ? (top16id + 8).toString() : undefined,
          team1OriginalPosition: 16 + offsetPositions,
          team2OriginalPosition: 17 + offsetPositions,
        },
        {
          matchId: (top32id + 2).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 1).toString(),
          loserGoesId: playAll ? (top16id + 9).toString() : undefined,
          team1OriginalPosition: 8 + offsetPositions,
          team2OriginalPosition: 25 + offsetPositions,
        },
        {
          matchId: (top32id + 3).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 1).toString(),
          loserGoesId: playAll ? (top16id + 9).toString() : undefined,
          team1OriginalPosition: 9 + offsetPositions,
          team2OriginalPosition: 24 + offsetPositions,
        },
        {
          matchId: (top32id + 4).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 2).toString(),
          loserGoesId: playAll ? (top16id + 10).toString() : undefined,
          team1OriginalPosition: 4 + offsetPositions,
          team2OriginalPosition: 29 + offsetPositions,
        },
        {
          matchId: (top32id + 5).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 2).toString(),
          loserGoesId: playAll ? (top16id + 10).toString() : undefined,
          team1OriginalPosition: 13 + offsetPositions,
          team2OriginalPosition: 20 + offsetPositions,
        },
        {
          matchId: (top32id + 6).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 3).toString(),
          loserGoesId: playAll ? (top16id + 11).toString() : undefined,
          team1OriginalPosition: 5 + offsetPositions,
          team2OriginalPosition: 28 + offsetPositions,
        },
        {
          matchId: (top32id + 7).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 3).toString(),
          loserGoesId: playAll ? (top16id + 11).toString() : undefined,
          team1OriginalPosition: 12 + offsetPositions,
          team2OriginalPosition: 21 + offsetPositions,
        },
        {
          matchId: (top32id + 8).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 4).toString(),
          loserGoesId: playAll ? (top16id + 12).toString() : undefined,
          team1OriginalPosition: 3 + offsetPositions,
          team2OriginalPosition: 30 + offsetPositions,
        },
        {
          matchId: (top32id + 9).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 4).toString(),
          loserGoesId: playAll ? (top16id + 12).toString() : undefined,
          team1OriginalPosition: 14 + offsetPositions,
          team2OriginalPosition: 19 + offsetPositions,
        },
        {
          matchId: (top32id + 10).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 5).toString(),
          loserGoesId: playAll ? (top16id + 13).toString() : undefined,
          team1OriginalPosition: 6 + offsetPositions,
          team2OriginalPosition: 27 + offsetPositions,
        },
        {
          matchId: (top32id + 11).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 5).toString(),
          loserGoesId: playAll ? (top16id + 13).toString() : undefined,
          team1OriginalPosition: 11 + offsetPositions,
          team2OriginalPosition: 22 + offsetPositions,
        },
        {
          matchId: (top32id + 12).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 6).toString(),
          loserGoesId: playAll ? (top16id + 14).toString() : undefined,
          team1OriginalPosition: 2 + offsetPositions,
          team2OriginalPosition: 31 + offsetPositions,
        },
        {
          matchId: (top32id + 13).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 6).toString(),
          loserGoesId: playAll ? (top16id + 14).toString() : undefined,
          team1OriginalPosition: 15 + offsetPositions,
          team2OriginalPosition: 18 + offsetPositions,
        },
        {
          matchId: (top32id + 14).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 7).toString(),
          loserGoesId: playAll ? (top16id + 15).toString() : undefined,
          team1OriginalPosition: 7 + offsetPositions,
          team2OriginalPosition: 26 + offsetPositions,
        },
        {
          matchId: (top32id + 15).toString(),
          winnerPosition: 1 + offsetPositions,
          loserPosition: 17 + offsetPositions,
          roundNumber: 0,
          status: MatchStatus.notStarted,
          team1Id: "",
          team2Id: "",
          winnerGoesId: (top16id + 7).toString(),
          loserGoesId: playAll ? (top16id + 15).toString() : undefined,
          team1OriginalPosition: 10 + offsetPositions,
          team2OriginalPosition: 23 + offsetPositions,
        },
      ],
    });
  }

  return definitions;
};
