import React from 'react';
import styles from './common/styles/common.module.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './common/features/layout';
import { ROUTES } from './common/consts/routes';

const App: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.HOME} element={<Layout route={ROUTES.HOME} />} />
          <Route path={ROUTES.LOGIN} element={<Layout route={ROUTES.LOGIN} />} />
          <Route path={ROUTES.TOURNAMENT} element={<Layout route={ROUTES.TOURNAMENT} />} />
          <Route path={ROUTES.PRIVACY} element={<Layout route={ROUTES.PRIVACY} />} />
          <Route path={ROUTES.ADDEVENT} element={<Layout route={ROUTES.ADDEVENT} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
