import { ITeam } from "../interfaces/teamModels";

export const printTeamName = (team: ITeam): string => {
  let teamName: string = `${team?.isRanked ? "* " : ""}`;

  if (team?.players) {
    team.players.forEach((player) => {
      teamName += `${player.name} (${player.club}) `;
    });
  }

  return teamName;
};
