import * as React from "react";
import Box from "@mui/material/Box";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Logout from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";
import commonStyles from "../styles/common.module.scss";
import { observer } from "mobx-react";
import store from "../../stores/eventStore";
import { autorun } from "mobx";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const AppMenu: React.FC = observer(() => {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();

  const [eventId, setEventId] = React.useState<string>(store.eventId);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  autorun(() => {
    if (eventId !== store.eventId) {
      setEventId(store.eventId);
    }
  });

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={commonStyles.noPrint}>
        <AppBar position="static" color={"primary"}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                store.clearStore();
                navigate("/");
              }}
            >
              <HomeIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {eventId && (
                  <Typography
                    component="div"
                    sx={{ marginBottom: 0, fontSize: 13 }}
                  >
                    {dayjs(store.eventDate).format("D.M.YYYY")}
                  </Typography>
                )}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1, marginTop: 0 }}
                >
                  {eventId ? `${store.eventName}` : "Petanque Online Tulospalvelu"}
                </Typography>
              </div>
              {isAuthenticated && (
                <>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar src={user?.picture} />
                  </IconButton>
                </>
              )}
              {!isAuthenticated && (
                <>
                  <Button
                    color="inherit"
                    onClick={() => {
                      loginWithRedirect();
                    }}
                  >
                    Login
                  </Button>
                </>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <Menu
        className={commonStyles.noPrint}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            displayPrint: "none",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem disabled>{user?.email}</MenuItem>
        <MenuItem
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
});

export default AppMenu;
