import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react";
import * as React from "react";
import LoadingSpinner from "../common/components/loadingSpinner";
import { IEvents } from "../common/interfaces/IEvent";
import { fetchData } from "../services/blobService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../common/consts/routes";
import { useAuth0 } from "@auth0/auth0-react";
import { hasRole, ROLES } from "../services/authorizationService";
import ListEvents from "../common/features/events/listEvents";
import dayjs from "dayjs";
import store from "../stores/eventStore";
import TournamentCalendar from "../common/components/tournamentCalendar";

const TournamentListPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  const [getDataDone, setGetDataDone] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [allEvents, setAllEvents] = React.useState<IEvents | undefined>(
    undefined
  );
  const [eventsData, setEventsData] = React.useState<IEvents | undefined>(
    undefined
  );
  const [pastEvents, setPastEvents] = React.useState<IEvents | undefined>(
    undefined
  );

  const [adminEvents, setAdminEvents] = React.useState<IEvents | undefined>(
    undefined
  );

  const [showAllEvents, setShowAllEvents] = React.useState<boolean>(false);

  const blobName = "events.json";
  const containerName = "events";

  React.useEffect(() => {
    if (!getDataDone) {
      setGetDataDone(true);
      const today = dayjs();

      fetchData(blobName, containerName)
        .then((item) => {
          let events = JSON.parse(item as string) as IEvents;

          const all: IEvents = {
            events: events.events,
          };

          const testEvents: IEvents = {
            events: events.events.filter((e) => e.adminOnly === true),
          };

          const pastEvents: IEvents = {
            events: events.events
              .filter(
                (e) =>
                  !e.adminOnly && dayjs(e.startingTime).add(1, "day") < today
              )
              .sort(
                (a, b) =>
                  new Date(b.startingTime).getTime() -
                  new Date(a.startingTime).getTime()
              ),
          };

          events.events = events.events
            .filter(
              (e) => !e.adminOnly && dayjs(e.startingTime).add(1, "day") > today
            )
            .sort(
              (a, b) =>
                new Date(a.startingTime).getTime() -
                new Date(b.startingTime).getTime()
            );

          setEventsData(events);
          setAdminEvents(testEvents);
          setPastEvents(pastEvents);
          setAllEvents(all);

          setLoading(false);
        })
        .catch((e: any) => {
          setEventsData(undefined);
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <React.Fragment>
          {isAuthenticated && hasRole(user, ROLES.organizer) && (
            <>
              <Button
                sx={{ marginTop: 2, marginLeft: 2 }}
                variant="contained"
                startIcon={<AddIcon />}
                aria-label="save"
                size="medium"
                onClick={() => {
                  store.clearStore();
                  navigate(ROUTES.ADDEVENT);
                }}
              >
                <Typography>Lisää turnaus</Typography>
              </Button>
              <br></br>
            </>
          )}

          <Button
            sx={{ marginTop: 2, marginLeft: 2 }}
            variant={showAllEvents ? "contained" : "outlined"}
            aria-label="save"
            size="medium"
            onClick={() => {
              setShowAllEvents(!showAllEvents);
            }}
          >
            <Typography>
              {showAllEvents ? "Näytä kalenteri" : "Näytä kaikki turnaukset"}
            </Typography>
          </Button>

          {showAllEvents ? (
            <>
              <Typography variant="h6" sx={{ marginTop: 5, marginLeft: 2 }}>
                Tulevat turnaukset
              </Typography>
              <ListEvents
                name="upcoming-events"
                events={eventsData}
                canEdit={true}
              />
              <Typography variant="h6" sx={{ marginTop: 5, marginLeft: 2 }}>
                Menneet turnaukset
              </Typography>
              <ListEvents
                name="past-events"
                events={pastEvents}
                canEdit={false}
              />{" "}
            </>
          ) : (
            <TournamentCalendar events={allEvents?.events ?? []} />
          )}

          {isAuthenticated && hasRole(user, ROLES.organizer) && (
            <>
              <Typography variant="h6" sx={{ marginTop: 5, marginLeft: 2 }}>
                TESTIALUE!
              </Typography>
              <ListEvents
                name="test-events"
                events={adminEvents}
                canEdit={false}
                isTestArea={true}
              />
            </>
          )}
        </React.Fragment>
      )}
    </>
  );
});

export default TournamentListPage;
