import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { Grid } from "@mui/material";
import * as React from "react";
import { ICupMatch, ICupRound, ITournament } from "../../interfaces/tournamentModels";
import commonStyles from "../../styles/common.module.scss";
import CupMatch from "./cupMatch";
import MatchStatus from "../../enums/matchStatus";
import { isClubOrganizerOrAdmin } from "../../../services/authorizationService";
import store from "../../../stores/eventStore";

interface CupRoundProps {
  amountOfTeamsLeft: number;
  cupRound: ICupRound | undefined;
  onEdit: () => void;
  tournamentData: ITournament;
  cupName: string;
  offset: number;
}

const CupRound: React.FC<CupRoundProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();

  const [hasChanges, setHasChanges] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);

  const updateRoundData = () => {
    if (props.cupRound) {
      props.onEdit();
      setHasChanges(false);
    }
    setUpdating(false);
  };

  const handleMatchEdit = (matchData: ICupMatch) => {
    if (props.amountOfTeamsLeft > 2) {
      const sameBracketMatch = props.tournamentData.cupRounds
        .find((cr) => cr.amountOfTeamsInRound === props.amountOfTeamsLeft && cr.name === props.cupName)
        ?.matches.find((m) => m.winnerGoesId === matchData.winnerGoesId && m.matchId !== matchData.matchId)!;
      const isUpper = matchData.matchId < sameBracketMatch.matchId;
      const nextRoundToEdit = props.tournamentData.cupRounds.find((cr) => cr.amountOfTeamsInRound === props.amountOfTeamsLeft / 2 && cr.name === props.cupName);

      if (nextRoundToEdit) {
        const winnerMatch = nextRoundToEdit.matches.find((m) => m.matchId === matchData.winnerGoesId);

        if (matchData.team1Points !== undefined && matchData.team2Points !== undefined && winnerMatch) {
          if (isUpper) {
            if (matchData.team1Points > matchData.team2Points) {
              winnerMatch.team1Id = matchData.team1Id;
              winnerMatch.team1OriginalPosition = matchData.team1OriginalPosition;
            }
            if (matchData.team1Points < matchData.team2Points) {
              winnerMatch.team1Id = matchData.team2Id;
              winnerMatch.team1OriginalPosition = matchData.team2OriginalPosition;
            }
          } else {
            if (matchData.team1Points > matchData.team2Points) {
              winnerMatch.team2Id = matchData.team1Id;
              winnerMatch.team2OriginalPosition = matchData.team1OriginalPosition;
            }
            if (matchData.team1Points < matchData.team2Points) {
              winnerMatch.team2Id = matchData.team2Id;
              winnerMatch.team2OriginalPosition = matchData.team2OriginalPosition;
            }
          }
        }

        const loserMatch = nextRoundToEdit.matches.find((m) => m.matchId === matchData.loserGoesId);

        if (matchData.team1Points !== undefined && matchData.team2Points !== undefined && loserMatch) {
          if (isUpper) {
            if (matchData.team1Points > matchData.team2Points) {
              loserMatch.team1Id = matchData.team2Id;
              loserMatch.team1OriginalPosition = matchData.team2OriginalPosition;
            }
            if (matchData.team1Points < matchData.team2Points) {
              loserMatch.team1Id = matchData.team1Id;
              loserMatch.team1OriginalPosition = matchData.team1OriginalPosition;
            }
          } else {
            if (matchData.team1Points > matchData.team2Points) {
              loserMatch.team2Id = matchData.team2Id;
              loserMatch.team2OriginalPosition = matchData.team2OriginalPosition;
            }
            if (matchData.team1Points < matchData.team2Points) {
              loserMatch.team2Id = matchData.team1Id;
              loserMatch.team2OriginalPosition = matchData.team1OriginalPosition;
            }
          }
        }
      }
    }

    setHasChanges(true);
  };

  return (
    <>
      <Grid container spacing={0.5} key={`cup-${props.amountOfTeamsLeft.toString()}-pairs`} justifyContent="flex-start">
        {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
          <>
            <Grid item xs={12} className={`${commonStyles.leftAlign} ${commonStyles.noPrint}`}>
              <LoadingButton
                disabled={!hasChanges}
                loading={updating}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={() => {
                  setUpdating(true);
                  updateRoundData();
                }}
              >
                Vie muutokset
              </LoadingButton>
            </Grid>
          </>
        )}
        {props.cupRound &&
          props.tournamentData.playAllCupMatches === true &&
          props.cupRound.matches.map((m, i) => {
            return (
              <React.Fragment key={m.matchId}>
                {(i === 0 || props.cupRound!.matches[i - 1].winnerPosition !== props.cupRound!.matches[i].winnerPosition) && (
                  <>
                    <Grid item xs={0.5}>
                      <p className={commonStyles.noEmptyTopBottom}>
                        <b>#</b>
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className={commonStyles.noEmptyTopBottom}>
                        <b>
                          Sijat {m.winnerPosition - props.offset} - {m.winnerPosition + props.amountOfTeamsLeft - 1 - props.offset}
                        </b>
                      </p>
                    </Grid>
                    <Grid item xs={1}>
                      <p className={commonStyles.noEmptyTopBottom}></p>
                    </Grid>
                    <Grid item xs={0.5}>
                      <p className={commonStyles.noEmptyTopBottom}></p>
                    </Grid>
                    <Grid item xs={1}>
                      <p className={commonStyles.noEmptyTopBottom}></p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className={commonStyles.noEmptyTopBottom}></p>
                    </Grid>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </>
                )}

                <CupMatch matchData={m} teamData={props.tournamentData.teams} onMatchEdit={handleMatchEdit} />
              </React.Fragment>
            );
          })}
        {props.tournamentData.playAllCupMatches !== true && (
          <>
            <Grid item xs={0.5}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>#</b>
              </p>
            </Grid>
            <Grid item xs={4}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>Sijat 1 - {props.amountOfTeamsLeft}</b>
              </p>
            </Grid>
            <Grid item xs={1}>
              <p className={commonStyles.noEmptyTopBottom}></p>
            </Grid>
            <Grid item xs={0.5}>
              <p className={commonStyles.noEmptyTopBottom}></p>
            </Grid>
            <Grid item xs={1}>
              <p className={commonStyles.noEmptyTopBottom}></p>
            </Grid>
            <Grid item xs={4}>
              <p className={commonStyles.noEmptyTopBottom}></p>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </>
        )}

        {props.cupRound &&
          props.amountOfTeamsLeft > 2 &&
          props.tournamentData.playAllCupMatches !== true &&
          props.cupRound.matches.map((m) => {
            return <CupMatch key={`cup-match-${m.matchId}-container`} matchData={m} teamData={props.tournamentData.teams} onMatchEdit={handleMatchEdit} />;
          })}

        {props.amountOfTeamsLeft === 2 && props.tournamentData.playAllCupMatches !== true && (
          <>
            <CupMatch matchData={props.cupRound!.matches[0]!} teamData={props.tournamentData.teams} onMatchEdit={handleMatchEdit} />

            {props.cupRound!.matches[1] && (
              <>
                <Grid item xs={0.5}>
                  <p className={commonStyles.noEmptyTopBottom}>
                    <b>#</b>
                  </p>
                </Grid>
                <Grid item xs={4}>
                  <p className={commonStyles.noEmptyTopBottom}>
                    <b>Sijat 3 - 4</b>
                  </p>
                </Grid>
                <Grid item xs={1}>
                  <p className={commonStyles.noEmptyTopBottom}></p>
                </Grid>
                <Grid item xs={0.5}>
                  <p className={commonStyles.noEmptyTopBottom}></p>
                </Grid>
                <Grid item xs={1}>
                  <p className={commonStyles.noEmptyTopBottom}></p>
                </Grid>
                <Grid item xs={4}>
                  <p className={commonStyles.noEmptyTopBottom}></p>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <CupMatch matchData={props.cupRound!.matches[1]!} teamData={props.tournamentData.teams} onMatchEdit={handleMatchEdit} />
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default CupRound;
