import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/fi";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { GameMode } from "../../enums/gameMode";
import { ITeams } from "../../interfaces/teamModels";
import { LoadingButton } from "@mui/lab";
import { RoundRule, ITournament, IBoule, ICup } from "../../interfaces/tournamentModels";
import { Category } from "../../enums/category";
import TournamentStatus from "../../enums/tournamentStatus";
import { postBlobData } from "../../../services/blobService";
import commonStyles from "../../styles/common.module.scss";
import { TOURNAMENT } from "../../consts/tournamentConsts";
import { getCupDefinitions } from "../../../services/cupService";
import TournamentFormat from "../../enums/tournamentFormat";
import { PairAllRound } from "../../../services/tournamentService";

interface FillTournamentDataProps {
  teams: ITeams;
  category: string;
  gameMode: GameMode;
  blobName: string;
  tournamentData: ITournament | undefined;
  onSave: (tournamentData: ITournament) => void;
  containerName: string;
}

type CupBase = {
  id: number;
  teamAmount: string;
};

const FillTournamentData: React.FC<FillTournamentDataProps> = (props) => {
  const cupLetter: string[] = ["B", "C", "D", "E", "F", "G", "H", "I"];
  const [loading, setLoading] = React.useState(false);

  const startTime = props.tournamentData ? props.tournamentData.startTime : new Date();
  const [amountOfRounds, setAmountOfRounds] = React.useState<string>(
    props.tournamentData && props.tournamentData.roundRules.amountOfRounds !== null && props.tournamentData.roundRules.format === TournamentFormat.swiss
      ? props.tournamentData.roundRules.amountOfRounds.toString()
      : ""
  );
  const getCupTeamAmount = () => {
    if (props.tournamentData && props.tournamentData.roundRules) {
      const t = props.tournamentData.roundRules.cups?.find(
        (c) => c.name === TOURNAMENT.CHAMPION_CUP_NAME && (c.maxTeams !== undefined || c.amountOfPlayers !== undefined)
      );
      return t?.maxTeams?.toString() ?? t?.amountOfPlayers?.toString() ?? "";
    }
    return "";
  };
  const getAdditionalCupTeamAmount = () => {
    if (props.tournamentData && props.tournamentData.roundRules) {
      const t = props.tournamentData.roundRules.cups?.filter(
        (c) => c.name !== TOURNAMENT.CHAMPION_CUP_NAME && (c.maxTeams !== undefined || c.amountOfPlayers !== undefined)
      );
      return t?.map((ac) => {
        return {
          id: cupLetter.indexOf(ac.name),
          teamAmount: ac?.maxTeams?.toString() ?? "",
        } as CupBase;
      });
    }
    return [];
  };
  const [cupTeamAmount, setCupTeamAmount] = React.useState<string>(getCupTeamAmount());
  const [startingFieldNumber, setStartingFieldNumber] = React.useState<number | undefined>(props.tournamentData?.startingFieldNumber);
  const [excludedFields, setExcludedFields] = React.useState<string | undefined>(props.tournamentData?.excludedFields);
  const [playAllCupMatches, setPlayAllCupMatches] = React.useState<boolean | undefined>(props.tournamentData?.playAllCupMatches ?? false);
  const [format, setFormat] = React.useState(props.tournamentData?.roundRules.format ?? "");
  const [additionalCups, setAdditionalCups] = React.useState<CupBase[]>(getAdditionalCupTeamAmount() ?? []);
  const [randomFields, setRandomFields] = React.useState<boolean | undefined>(props.tournamentData?.randomFields ?? false);
  const [endFieldNumber, setEndFieldNumber] = React.useState<number | undefined>(props.tournamentData?.endFieldNumber);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setStartingFieldNumber(value);
    } else {
      setStartingFieldNumber(undefined);
    }
  };

  const handleEndFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value)) {
      setEndFieldNumber(value);
    } else {
      setEndFieldNumber(undefined);
    }
  };

  const getFullCupTeamAmount = (maxTeams: number) => {
    let result = 4;
    for (let i = 4; maxTeams > i; i = i * 2) {
      result = i * 2;
    }

    return result;
  };

  const getFullCupTeamAmountForAdditionalCup = (maxTeams: number) => {
    let result = 2;
    for (let i = 2; maxTeams > i; i = i * 2) {
      result = i * 2;
    }

    return result;
  };

  const handleExcludedFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setExcludedFields(event.target.value);
  };

  const maxSwissRounds = (): number => {
    let maxRounds: number = 2;

    for (let i = 2; i < 20; i++) {
      maxRounds = i;
      if (props.teams.teams.length <= Math.pow(2, i)) break;
    }

    return maxRounds;
  };

  const possibleCupTeamAmount = (): number[] => {
    const cupAmounts: number[] = [];

    for (let i = 4; i <= Math.min(props.teams.teams.length, 32); i++) {
      cupAmounts.push(i);
    }

    return cupAmounts;
  };

  const possibleCupTeamAmountForAdditionalCup = (index: number): number[] => {
    const cupAmounts: number[] = [];
    const maxTeams = props.teams.teams.length;
    let maxLeft = maxTeams - Number.parseInt(cupTeamAmount);

    if (index !== 0) {
      additionalCups.forEach((ac) => {
        if (ac.id < index) {
          maxLeft = maxLeft - Number.parseInt(ac.teamAmount);
        }
      });
    }

    for (let i = 2; i <= Math.min(maxLeft, 32); i++) {
      cupAmounts.push(i);
    }

    return cupAmounts;
  };

  const amountOfBoules = (): number => {
    const numberOfTeams = props.teams.teams.length;

    if (numberOfTeams) {
      const amountOfBoules = Math.ceil(numberOfTeams / 4);
      return amountOfBoules;
    }
    return 0;
  };

  const circelBouleCupAmounts = (): number => {
    return amountOfBoules() * 2;
  };

  const initializeBoules = (): IBoule[] => {
    const boules: IBoule[] = [];
    const amountofBoules = amountOfBoules();

    for (let i = 0; i < amountofBoules; i++) {
      boules.push({
        name: TOURNAMENT.BOULE_NAMES.charAt(i),
        nextBoule: i + 1 < amountofBoules ? TOURNAMENT.BOULE_NAMES.charAt(i + 1) : TOURNAMENT.BOULE_NAMES.charAt(0),
        teamIds: [],
      });
    }

    return boules;
  };

  const handleRoundAmountChange = (event: SelectChangeEvent) => {
    setAmountOfRounds(event.target.value as string);
  };

  const handleCupTeamsAmountChange = (event: SelectChangeEvent) => {
    setCupTeamAmount(event.target.value as string);
    setAdditionalCups([]);
  };

  const handleAdditionalCupTeamsAmountChange = (event: SelectChangeEvent, index: number) => {
    const tempAdditionalCups = additionalCups.slice(0, index + 1);
    tempAdditionalCups[index].teamAmount = event.target.value as string;

    setAdditionalCups([...tempAdditionalCups]);
  };

  const removeAdditionalCup = (index: number) => {
    const tempAdditionalCups = additionalCups;
    tempAdditionalCups.splice(index, 1);
    tempAdditionalCups.forEach((tac, j) => {
      tac.id = j;
    });
    setAdditionalCups([...tempAdditionalCups]);
  };

  const handlePlayAllMatchesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlayAllCupMatches(event.target.checked);
  };

  const handleRandomFieldsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRandomFields(event.target.checked);
  };

  const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormat((event.target as HTMLInputElement).value as TournamentFormat);
  };

  const saveProperties = () => {
    let newTournament: ITournament | undefined = undefined;
    const extraCups: ICup[] =
      additionalCups === undefined || additionalCups.length === 0
        ? []
        : additionalCups.map((ac) => {
            return {
              amountOfPlayers: getFullCupTeamAmountForAdditionalCup(parseInt(ac.teamAmount)),
              maxTeams: parseInt(ac.teamAmount),
              name: cupLetter[ac.id],
            };
          });
    if (props.tournamentData) {
      props.tournamentData.startTime = startTime;
      props.tournamentData.startingFieldNumber = startingFieldNumber;
      props.tournamentData.randomFields = randomFields;
      props.tournamentData.endFieldNumber = endFieldNumber;
      if (format === TournamentFormat.swiss) {
        props.tournamentData.roundRules = {
          amountOfRounds: parseInt(amountOfRounds),
          cups: [
            {
              maxTeams: parseInt(cupTeamAmount),
              amountOfPlayers: getFullCupTeamAmount(parseInt(cupTeamAmount)),
              name: TOURNAMENT.CHAMPION_CUP_NAME,
            },
            ...extraCups,
          ],
          byePoints: 7,
          format: TournamentFormat.swiss,
        } as RoundRule;

        let cupRounds = cupTeamAmount
          ? getCupDefinitions(getFullCupTeamAmount(parseInt(cupTeamAmount)), playAllCupMatches ?? false, TOURNAMENT.CHAMPION_CUP_NAME, 0)
          : [];
        let offset = parseInt(cupTeamAmount);
        if (extraCups && extraCups.length > 0) {
          extraCups.forEach((cup) => {
            cupRounds.push(...getCupDefinitions(cup.amountOfPlayers, playAllCupMatches ?? false, cup.name, offset));
            offset += cup.maxTeams;
          });
        }

        props.tournamentData.cupRounds = cupRounds;
      } else if (format === TournamentFormat.allround) {
        props.tournamentData.roundRules = {
          amountOfRounds: props.teams.teams.length % 2 === 0 ? props.teams.teams.length - 1 : props.teams.teams.length,
          cups: [
            {
              maxTeams: parseInt(cupTeamAmount),
              amountOfPlayers: getFullCupTeamAmount(parseInt(cupTeamAmount)),
              name: TOURNAMENT.CHAMPION_CUP_NAME,
            },
            ...extraCups,
          ],
          byePoints: 0,
          format: TournamentFormat.allround,
        } as RoundRule;

        let cupRounds = cupTeamAmount
          ? getCupDefinitions(getFullCupTeamAmount(parseInt(cupTeamAmount)), playAllCupMatches ?? false, TOURNAMENT.CHAMPION_CUP_NAME, 0)
          : [];
        let offset = parseInt(cupTeamAmount);
        if (extraCups && extraCups.length > 0) {
          extraCups.forEach((cup) => {
            cupRounds.push(...getCupDefinitions(cup.amountOfPlayers, playAllCupMatches ?? false, cup.name, offset));
            offset += cup.maxTeams;
          });
        }

        props.tournamentData.cupRounds = cupRounds;
      } else if (format === TournamentFormat.circelBoule) {
        props.tournamentData.roundRules = {
          amountOfRounds: 3,
          cups: [
            {
              maxTeams: circelBouleCupAmounts(),
              amountOfPlayers: circelBouleCupAmounts(),
              name: TOURNAMENT.CHAMPION_CUP_NAME,
            },
          ],
          byePoints: 0,
          format: TournamentFormat.circelBoule,
          bouleAmount: amountOfBoules(),
        } as RoundRule;

        props.tournamentData.cupRounds = getCupDefinitions(circelBouleCupAmounts(), playAllCupMatches ?? false, TOURNAMENT.CHAMPION_CUP_NAME, 0);
      }

      props.tournamentData.excludedFields = excludedFields;
      props.tournamentData.playAllCupMatches = playAllCupMatches;
    } else {
      let cupRounds = cupTeamAmount
        ? getCupDefinitions(getFullCupTeamAmount(parseInt(cupTeamAmount)), playAllCupMatches ?? false, TOURNAMENT.CHAMPION_CUP_NAME, 0)
        : [];
      let offset = parseInt(cupTeamAmount);
      if (extraCups && extraCups.length > 0) {
        extraCups.forEach((cup) => {
          cupRounds.push(...getCupDefinitions(cup.amountOfPlayers, playAllCupMatches ?? false, cup.name, offset));
          offset += cup.maxTeams;
        });
      }

      newTournament = {
        category: Category[props.category as keyof typeof Category],
        description: "",
        name: "",
        gameMode: props.gameMode,
        rounds:
          format === TournamentFormat.swiss || format === TournamentFormat.circelBoule ? [] : PairAllRound(props.teams, startingFieldNumber, excludedFields),
        cupRounds:
          format === TournamentFormat.circelBoule
            ? getCupDefinitions(circelBouleCupAmounts(), playAllCupMatches ?? false, TOURNAMENT.CHAMPION_CUP_NAME, 0)
            : cupRounds,
        roundRules:
          format === TournamentFormat.swiss
            ? ({
                amountOfRounds: parseInt(amountOfRounds),
                byePoints: 7,
                cups: [
                  {
                    maxTeams: parseInt(cupTeamAmount),
                    amountOfPlayers: getFullCupTeamAmount(parseInt(cupTeamAmount)),
                    name: TOURNAMENT.CHAMPION_CUP_NAME,
                  },
                  ...extraCups,
                ],
                format: TournamentFormat.swiss,
              } as RoundRule)
            : format === TournamentFormat.allround
            ? ({
                amountOfRounds: props.teams.teams.length % 2 === 0 ? props.teams.teams.length - 1 : props.teams.teams.length,
                byePoints: 0,
                cups: [
                  {
                    maxTeams: parseInt(cupTeamAmount),
                    amountOfPlayers: getFullCupTeamAmount(parseInt(cupTeamAmount)),
                    name: TOURNAMENT.CHAMPION_CUP_NAME,
                  },
                  ...extraCups,
                ],
                format: TournamentFormat.allround,
              } as RoundRule)
            : {
                amountOfRounds: 3,
                byePoints: 0,
                cups: [
                  {
                    maxTeams: circelBouleCupAmounts(),
                    amountOfPlayers: circelBouleCupAmounts(),
                    name: TOURNAMENT.CHAMPION_CUP_NAME,
                  },
                ],
                format: TournamentFormat.circelBoule,
                bouleAmount: amountOfBoules(),
              },
        startTime: startTime,
        status: TournamentStatus.notStarted,
        teams: props.teams,
        startingFieldNumber: startingFieldNumber,
        excludedFields: excludedFields,
        playAllCupMatches: playAllCupMatches,
        boules: format === TournamentFormat.circelBoule ? initializeBoules() : undefined,
        endFieldNumber: endFieldNumber,
        randomFields: randomFields,
      };
    }

    postBlobData(newTournament ?? props.tournamentData!, props.blobName, props.containerName)
      .then(() => {
        props.onSave(newTournament ?? props.tournamentData!);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const startSaving = () => {
    setLoading(true);
    saveProperties();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Accordion className={`${commonStyles.noPrint}`} sx={{ borderColor: "blue", backgroundColor: "#dfe3ee" }} disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <p>Turnaustiedot</p>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0.5} justifyContent="flex-start" alignItems="center" className={commonStyles.bottomMargin}>
            <Grid item xs={10} key={`tournament-${props.category}-${props.gameMode}-format`} className={commonStyles.bottomMargin}>
              <FormControl>
                <FormLabel id="tournament-format-group">Formaatti</FormLabel>
                <RadioGroup aria-labelledby="tournament-format-group" name="tournament-format-radio-button-group" value={format} onChange={handleFormatChange}>
                  <FormControlLabel value={TournamentFormat.swiss} control={<Radio />} label="Swiss" />
                  <FormControlLabel value={TournamentFormat.allround} control={<Radio />} label="Sarja" />
                  <FormControlLabel value={TournamentFormat.circelBoule} control={<Radio />} label="Circel Boule" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {format === TournamentFormat.swiss && (
              <>
                <Grid item xs={10} sx={{ marginBottom: 1 }}>
                  <FormLabel id="tournament-fields-label">Kierrokset</FormLabel>
                </Grid>
                <Grid item xs={10} key={`tournament-${props.category}-${props.gameMode}-round-amount`} className={commonStyles.bottomMargin}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="tournament-round-amount-label">Kierroslukumäärä</InputLabel>
                      <Select
                        labelId="tournament-round-amount-label"
                        id="tournament-round-amount"
                        value={amountOfRounds}
                        label="Kierroslukumäärä"
                        onChange={handleRoundAmountChange}
                      >
                        {[...Array(maxSwissRounds())].map((_e, i) => {
                          return (
                            <MenuItem value={i + 1} key={`round-amount-select-${i + 1}`}>
                              {i + 1}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </>
            )}
            {format !== TournamentFormat.allround && (
              <>
                <Grid item xs={10} sx={{ marginBottom: 1 }}>
                  <FormLabel id="tournament-fields-label">Kentät</FormLabel>
                </Grid>
                <Grid item xs={12} key={`tournament-${props.category}-${props.gameMode}-random-fields`} className={commonStyles.bottomMargin}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={randomFields} onChange={handleRandomFieldsChange} inputProps={{ "aria-label": "controlled" }} />}
                      label="Satunnaiset kentät"
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={randomFields ? 5 : 10}
                  key={`tournament-${props.category}-${props.gameMode}-starting-field-edit`}
                  className={commonStyles.bottomMargin}
                >
                  <FormControl fullWidth>
                    <TextField
                      id={`tournament-${props.category}-${props.gameMode}-starting-field`}
                      label="Ensimmäisen kentän numero"
                      type="number"
                      value={startingFieldNumber}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleFieldChange}
                    />
                  </FormControl>
                </Grid>
                {randomFields && (
                  <Grid item xs={5} key={`tournament-${props.category}-${props.gameMode}-last-field-edit`} className={commonStyles.bottomMargin}>
                    <FormControl fullWidth>
                      <TextField
                        id={`tournament-${props.category}-${props.gameMode}-last-field`}
                        label="Viimeisen kentän numero"
                        type="number"
                        value={endFieldNumber}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleEndFieldChange}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={10} key={`tournament-${props.category}-${props.gameMode}-excluded-fields-edit`} className={commonStyles.bottomMargin}>
                  <FormControl fullWidth>
                    <TextField
                      id={`tournament-${props.category}-${props.gameMode}-excluded-fields`}
                      label="Poistetut kentät (pilkulla erotettuna)"
                      type="text"
                      value={excludedFields ?? ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleExcludedFieldChange}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {format !== TournamentFormat.circelBoule && (
              <>
                <Grid item xs={10}>
                  <FormLabel id="tournament-cups-label">Cupit</FormLabel>
                </Grid>
                <Grid item xs={10} key={`tournament-${props.category}-${props.gameMode}-cup-amount`} className={commonStyles.bottomMargin}>
                  <p>A-CUP</p>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="tournament-cup-amount-label">Jatkavien joukkueiden määrä cuppiin.</InputLabel>
                      <Select
                        labelId="tournament-cup-amount-label"
                        id="tournament-cup-amount"
                        value={cupTeamAmount}
                        label="Jatkavien joukkueiden määrä cuppiin."
                        onChange={handleCupTeamsAmountChange}
                      >
                        {possibleCupTeamAmount().map((val, i) => {
                          return (
                            <MenuItem value={val} key={`cup-amount-select-${i}`}>
                              {val} joukkuetta
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </>
            )}
            {additionalCups.map((ac) => {
              return (
                <Grid item xs={10} className={commonStyles.bottomMargin} key={`${ac.id}-tournament-${props.category}-${props.gameMode}-cup-amount`}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{cupLetter[ac.id]}-CUP</p>
                    <IconButton
                      key={`${ac.id}-delete-icon`}
                      aria-label="delete"
                      size="small"
                      color="primary"
                      onClick={() => {
                        removeAdditionalCup(ac.id);
                      }}
                    >
                      <DeleteIcon fontSize="inherit" key={`${ac.id}-delete-icon-icon`} />
                    </IconButton>
                  </div>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id={`${ac.id}-tournament-cup-amount-label`}>Jatkavien joukkueiden määrä cuppiin.</InputLabel>
                      <Select
                        labelId={`${ac.id}-tournament-cup-amount-label`}
                        id={`${ac.id}-tournament-cup-amount`}
                        value={ac.teamAmount}
                        label="Jatkavien joukkueiden määrä cuppiin."
                        onChange={(e) => handleAdditionalCupTeamsAmountChange(e, ac.id)}
                      >
                        {possibleCupTeamAmountForAdditionalCup(ac.id).map((val, i) => {
                          return (
                            <MenuItem value={val} key={`${ac.id}cup-amount-select-${i}`}>
                              {val} joukkuetta
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              );
            })}
            {format !== TournamentFormat.circelBoule &&
              cupTeamAmount &&
              (additionalCups.length === 0 || additionalCups[additionalCups.length - 1].teamAmount) &&
              possibleCupTeamAmountForAdditionalCup(additionalCups.length).length > 0 && (
                <Grid item xs={12} key={`joukkue-${props.category}-${props.gameMode}-add-new`}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    aria-label="add"
                    onClick={() => {
                      setAdditionalCups([...additionalCups, { id: additionalCups.length, teamAmount: "" }]);
                    }}
                  >
                    Lisää CUP
                  </Button>
                </Grid>
              )}
            <Grid item xs={12} key={`tournament-${props.category}-${props.gameMode}-cup-playall`} className={commonStyles.bottomMargin}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={playAllCupMatches} onChange={handlePlayAllMatchesChange} inputProps={{ "aria-label": "controlled" }} />}
                  label="Pelataan kaikki sijoitukset"
                />
              </FormGroup>
            </Grid>
            <Grid container item xs={12} key={`tournament-${props.category}-${props.gameMode}-properties-save`} className={commonStyles.leftAlign}>
              <LoadingButton loading={loading} loadingPosition="start" startIcon={<SaveIcon />} variant="outlined" onClick={startSaving}>
                {props.tournamentData ? "Päivitä" : "Tallenna"}
              </LoadingButton>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </LocalizationProvider>
  );
};

export default FillTournamentData;
