import { Box, Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Switch, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import * as React from 'react';
import { IMatch, ITournament } from '../../interfaces/tournamentModels';
import commonStyles from '../../styles/common.module.scss';
import { printTeamName } from '../../utils/teamHelper';
import MatchStatus from '../../enums/matchStatus';
import { TOURNAMENT } from '../../consts/tournamentConsts';

interface ManualPairProps {
    open: boolean;
    tournamentData: ITournament;
    onCancel: () => void;
    onSave: (matches: IMatch[]) => void;
    roundNumber: number;
}

const ManualPair: React.FC<ManualPairProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(props.open);
    const [matches, setMatches] = React.useState<IMatch[]>([]);
    const [usedTeams, setUsedTeams] = React.useState<string[]>([]);
    const [field, setField] = React.useState<number | undefined>(undefined);
    const [team1, setTeam1] = React.useState<string>('');
    const [team2, setTeam2] = React.useState<string>('');
    const [isBye, setIsBye] = React.useState<boolean>(false);
    const [matchId, setMatchId] = React.useState<number>(Date.now());

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            setField(value);
        } else {
            setField(undefined);
        }
    };

    const handleTeam1Change = (event: SelectChangeEvent) => {
        setTeam1(event.target.value as string);
    };

    const handleTeam2Change = (event: SelectChangeEvent) => {
        setTeam2(event.target.value as string);
    };

    const handleIsByeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsBye(event.target.checked);
    };


    const handleClose = (_event: object, reason: string) => {
        if (reason === 'backdropClick') return;

        if (reason === 'save') {
            props.onSave(matches);
        }

        if (reason === 'cancel') {
            props.onCancel();
        }

        setOpen(false);
    }

    const addMatch = () => {
        if (team1 !== '' && (isBye || team2 !== '')) {
            const newMatch: IMatch = {
                matchId: matchId.toString(),
                roundNumber: props.roundNumber,
                status: isBye ? MatchStatus.byeWin : MatchStatus.notStarted,
                team1Id: team1,
                team2Id: isBye ? TOURNAMENT.BYE_ID : team2,
                fieldNumber: field,
                team1Points: isBye ? 13 : undefined,
                team2Points: isBye ? props.tournamentData.roundRules.byePoints : undefined
            }
            setMatchId(matchId + 1);
            setMatches([...matches, newMatch]);
            setUsedTeams([...usedTeams, team1, team2]);
            setTeam1('');
            setTeam2('');
            setField(undefined);
            setIsBye(false);
        }
    }

    const getTeams1 = () => {
        return props.tournamentData.teams.teams
            .filter(t => t.teamId !== team2 && usedTeams.every(ut => ut !== t.teamId))
            .map(t => {
                return {
                    teamId: t.teamId,
                    name: printTeamName(t),
                }
            })
    }

    const getTeams2 = () => {
        return props.tournamentData.teams.teams
            .filter(t => t.teamId !== team1 && usedTeams.every(ut => ut !== t.teamId))
            .map(t => {
                return {
                    teamId: t.teamId,
                    name: printTeamName(t),
                }
            })
    }

    const enableSave = () => {
        return team1 === '' && team2 === '' && props.tournamentData.teams.teams
            .filter(t => usedTeams.every(ut => ut !== t.teamId)).length === 0
    }

    React.useEffect(() => {
        setOpen(props.open);
        if (props.open) {
            setMatches([]);
            setUsedTeams([]);
        }
    }, [props.open])


    return (
        <Modal
            key={`manual-pair-modal`}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className={commonStyles.modalStyle}
            >
                <Grid container spacing={2} key={`manual-pair-container`} justifyContent='flex-start'>
                    <Grid item xs={12} key={`manual-pair-cancel`} className={commonStyles.rightAlign}>
                        <IconButton aria-label="cancel"
                            className={commonStyles.noEmptyTopBottom}
                            size="medium"
                            color="error"
                            onClick={(event) => {
                                handleClose(event, 'cancel');
                            }}>
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} key={`manual-pair-field`}>
                        <FormControl fullWidth>
                            <TextField
                                id={`manual-pair-field-edit`}
                                label="Kenttä"
                                type="number"
                                value={field ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFieldChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} key={`manual-pair-team1`}>
                        <FormControl fullWidth>
                            <InputLabel id="team1-label-id">Joukkue 1</InputLabel>
                            <Select
                                labelId="team1-label-id"
                                id="team1-select"
                                value={team1}
                                label="Joukkue 1"
                                onChange={handleTeam1Change}
                            >
                                {getTeams1().map((t, i) => {
                                    return <MenuItem value={t.teamId} key={`team1-select-${i + 1}`}>{t.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} key={`manual-pair-team2-isbye`}>
                        <FormControlLabel control={
                            <Switch checked={isBye}
                                onChange={handleIsByeChange}
                                inputProps={{ 'aria-label': 'controlled' }} />} label="Onko BYE" />
                    </Grid>
                    {!isBye && (
                        <Grid item xs={12} key={`manual-pair-team2`}>
                            <FormControl fullWidth>
                                <InputLabel id="team2-label-id">Joukkue 2</InputLabel>
                                <Select
                                    labelId="team2-label-id"
                                    id="team2-select"
                                    value={team2}
                                    label="Joukkue 2"
                                    onChange={handleTeam2Change}
                                >
                                    {getTeams2().map((t, i) => {
                                        return <MenuItem value={t.teamId} key={`team2-select-${i + 1}`}>{t.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item xs={12} key={`manual-pair-add`} className={commonStyles.leftAlign}>
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            aria-label="add"
                            onClick={addMatch}
                        >
                            Lisää pelipari
                        </Button>
                    </Grid>
                    <Grid item xs={12} key={`manual-pair-matches`}>
                        {matches.map((m, i) => {
                            return (
                                <FormControl fullWidth key={`manual-pair-match-${i + 1}`}>
                                    <p className={commonStyles.noEmptyTopBottom}>{`${m.fieldNumber ? m.fieldNumber : '-'}: ${printTeamName(props.tournamentData.teams.teams.find(t => t.teamId === m.team1Id)!)} - ${TOURNAMENT.BYE_ID === m.team2Id ? 'BYE' : printTeamName(props.tournamentData.teams.teams.find(t => t.teamId === m.team2Id)!)}`}</p>
                                </FormControl>

                            )
                        })}
                    </Grid>

                    <Grid item xs={12} key={`manual-pair-save`} className={commonStyles.rightAlign}>
                        <Button
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            aria-label="save"
                            onClick={(event) => {
                                handleClose(event, 'save');
                            }}
                            disabled={!enableSave()}
                        >
                            Tallenna peliparit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default ManualPair;