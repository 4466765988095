import * as React from "react";
import dayjs, { locale, Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { IEvent } from "../interfaces/IEvent";
import { Typography } from "@mui/material";
import ListEvents from "../features/events/listEvents";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";

interface TournamentCalendarProps {
  events: IEvent[];
}

const TournamentCalendar: React.FC<TournamentCalendarProps> = (props) => {
  const [value, setValue] = React.useState(dayjs());
  const [highlightedDays, setHighlightedDays] = React.useState(
    props.events
      .filter(
        (e) => dayjs(e.startingTime).format("YYYYMM") === value.format("YYYYMM")
      )
      .map((event) => dayjs(event.startingTime).get("date"))
  );

  const valueEvents = () => {
    return {
      events: props.events.filter(
        (e) =>
          dayjs(e.startingTime).format("YYYYMMDD") === value.format("YYYYMMDD")
      ),
    };
  };

  const isPast = () => {
    return value.isBefore(dayjs().add(-1, "day"));
  };

  function ServerDay(
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? "🔵" : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  locale("fi");
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={value}
          displayWeekNumber
          onChange={(newValue: any) => {
            setValue(newValue);
          }}
          onMonthChange={(month) => {
            setHighlightedDays(
              props.events
                .filter(
                  (e) =>
                    dayjs(e.startingTime).format("YYYYMM") ===
                    month.format("YYYYMM")
                )
                .map((event) => dayjs(event.startingTime).get("date"))
            );
          }}
          timezone="Europe/Helsinki"
          views={["month", "day"]}
          dayOfWeekFormatter={(_day, weekday: any) =>
            `${(weekday as dayjs.Dayjs).format("dd")}`
          }
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
            } as any,
          }}
          sx={{ width: "330px" }}
        />
      </LocalizationProvider>

      <Typography variant="h6" sx={{ marginTop: 5, marginLeft: 2 }}>
        {value.format("D.M.YYYY")}{" "}
        {isPast() ? "järjestetyt turnaukset" : "järjestettävät turnaukset"}
      </Typography>
      <ListEvents
        name="upcoming-events"
        events={valueEvents()}
        canEdit={isPast() ? false : true}
      />
    </>
  );
};

export default TournamentCalendar;
