import { Grid } from "@mui/material";
import * as React from "react";
import { ITournament } from "../../interfaces/tournamentModels";
import { getAllRoundScore } from "../../../services/tournamentService";
import RoundStatus from "../../enums/roundStatus";
import { printTeamName } from "../../utils/teamHelper";
import commonStyles from "../../styles/common.module.scss";

interface AllRoundScoreProps {
  tournamentData: ITournament;
}

const AllRoundScore: React.FC<AllRoundScoreProps> = (props) => {
  const [rows, setRows] = React.useState([]);
  const [scoreReady, setScoreReady] = React.useState<boolean>(false);

  const showDivider = (position: number) => {
    const dividers: number[] = [];

    props.tournamentData.roundRules.cups?.forEach((c) => {
      if (dividers.length === 0) {
        dividers.push(c.maxTeams);
      } else {
        dividers.push(c.maxTeams + dividers[dividers.length - 1]);
      }
    });

    return dividers.some((d) => d === position);
  };

  React.useEffect(() => {
    let scoreRows: any = [];

    let scores = getAllRoundScore(props.tournamentData);

    scores.forEach((s, index) => {
      scoreRows.push({
        id: index + 1,
        team: printTeamName(props.tournamentData.teams.teams.find((t) => t.teamId === s.teamId)!),
        wins: s.wins,
        plus: s.pointsDone,
        minus: s.pointsAgainst,
        v2: s.tieBreakerWins,
        plusMinus2: s.tieBreakerPointsDone - s.tieBreakerPointsAgainst,
        plus2: s.tieBreakerPointsDone,
        needTieBreaker: s.needTieBreaker,
      });
    });
    setRows(scoreRows);
    setScoreReady(true);
  }, []);

  return (
    <>
      {scoreReady && (
        <>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={1} className={commonStyles.leftAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>#</b>
              </p>
            </Grid>
            <Grid item xs={4} className={commonStyles.leftAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>Joukkue</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>V</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>+/-</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>+</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>-</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>V2</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>+/-</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>+</b>
              </p>
            </Grid>
            <Grid item xs={12}>
              <hr className={commonStyles.noEmptyTopBottom} />
            </Grid>
          </Grid>

          {rows.map((r: any, i) => {
            return (
              <Grid container spacing={0} key={`allroundscores-container-${i + 1}`} justifyContent="flex-start">
                <Grid item xs={1} className={`${commonStyles.leftAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{i + 1}</p>
                </Grid>
                <Grid item xs={4} className={`${commonStyles.leftAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.team}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.wins}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.plus - r.minus}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.plus}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.minus}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.needTieBreaker ? r.v2 : "-"}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.needTieBreaker ? r.plusMinus2 : "-"}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.needTieBreaker ? r.plus2 : "-"}</p>
                </Grid>
                <Grid item xs={12}>
                  <hr className={`${commonStyles.noEmptyTopBottom} ${showDivider(i + 1) ? commonStyles.thickDivider : ""}`} />
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

export default AllRoundScore;
