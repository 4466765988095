import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import { observer } from "mobx-react";
import * as React from "react";
import { IEvent, IEvents } from "../../interfaces/IEvent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ForwardIcon from "@mui/icons-material/Forward";
import EditIcon from "@mui/icons-material/Edit";
import styles from "../../styles/common.module.scss";
import { useNavigate } from "react-router-dom";
import store from "../../../stores/eventStore";
import { ROUTES } from "../../consts/routes";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useAuth0 } from "@auth0/auth0-react";
import { hasRole, isFromClub } from "../../../services/authorizationService";
import splLogo from "../../../images/spl_iso.jpg";
import { clubs } from "../../consts/clubs";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Helsinki");

interface ListEventsProps {
  name: string;
  events: IEvents | undefined;
  canEdit: boolean;
  isTestArea?: boolean;
}

const ListEvents: React.FC<ListEventsProps> = observer((props) => {
  const navigate = useNavigate();

  const { isAuthenticated, user } = useAuth0();

  const isNationalOrganized = (event: IEvent): boolean => {
    return event.organizerClubs.some((e) => e === "LIITTO" || e === "L-S" || e === "HAA");
  };

  const getUiName = (event: IEvent): string => {
    return props.isTestArea ? event.name : `(${event.organizerClubs.join("-")}) ${event.name}`;
  };

  const showTeams = (event: IEvent): string => {
    if (dayjs(event.startingTime) > dayjs()) {
      return "&showTeams";
    }
    return "";
  };

  return (
    <>
      {(props.events === undefined || props.events?.events.length === 0) && (
        <Typography variant="h6" sx={{ marginTop: 1, marginLeft: 5, fontSize: 18 }}>
          Ei turnauksia valitulla päivällä!
        </Typography>
      )}
      {props.events &&
        props.events.events.map((t, i) => {
          return (
            <Accordion key={`${props.name}-${i + 1}`} sx={{ marginBottom: "0px !important" }} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  marginTop: 0,
                  height: "100%",
                  flexDirection: "row-reverse",
                }}
              >
                <Typography sx={{ marginLeft: 1 }}>{dayjs(t.startingTime).tz().format("DD.MM.YYYY [klo] HH:mm")}</Typography>
                <Typography sx={{ marginLeft: 1 }}>
                  {getUiName(t)}
                  {isNationalOrganized(t) ? (
                    <img
                      src={splLogo}
                      style={{
                        width: 25,
                        margin: 0,
                        position: "absolute",
                        paddingTop: 3,
                        paddingLeft: 5,
                      }}
                      alt="Suomen Petanqueliiton järjestämä"
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  height: "100%",
                  alignItems: "flex-start",
                  padding: 0,
                  justifyContent: "flex-start",
                  display: "flex",
                  backgroundColor: "#f1f1f1",
                  paddingTop: "15px",
                }}
              >
                <div className={styles.flexContainer}>
                  <div className={styles.tournamentListFullRow}>
                    <p dangerouslySetInnerHTML={{ __html: t.description }}></p>
                  </div>
                  {!props.isTestArea && (
                    <div className={styles.tournamentListFullRow}>
                      <p>Järjestävä taho: {t.organizerClubs.map((oc) => clubs.find((c) => c.tunnus === oc)?.name ?? "").join("-")}</p>
                    </div>
                  )}
                  <div className={styles.flexColumn}>
                    <Button
                      sx={{
                        marginRight: 2,
                        marginBottom: 2,
                        color: "Black",
                        borderColor: "Black",
                        justifyContent: "flex-start",
                        display: "flex",
                        marginLeft: "15px",
                      }}
                      variant="outlined"
                      startIcon={<ForwardIcon />}
                      aria-label="save"
                      size="small"
                      onClick={() => {
                        store.updateEventId(t);
                        navigate(`${ROUTES.TOURNAMENT}?id=${t.eventId}${showTeams(t)}`);
                      }}
                    >
                      Tulospalvelu
                    </Button>
                    {isAuthenticated && isFromClub(user, t.organizerClubs) && (props.canEdit || hasRole(user)) && (
                      <Button
                        sx={{
                          marginRight: 2,
                          marginBottom: 2,
                          color: "Black",
                          borderColor: "Black",
                          justifyContent: "flex-start",
                          display: "flex",
                          marginLeft: "15px",
                        }}
                        variant="outlined"
                        startIcon={<EditIcon />}
                        aria-label="save"
                        size="small"
                        onClick={() => {
                          store.updateEventId(t);
                          navigate(ROUTES.ADDEVENT);
                        }}
                      >
                        Muokkaa tietoja
                      </Button>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
});

export default ListEvents;
