import { useAuth0 } from '@auth0/auth0-react';
import { Box, FormControl, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import * as React from 'react';
import { fetchData, postBlobData } from '../../services/blobService';
import { IRules } from '../interfaces/IRules';
import commonStyles from '../styles/common.module.scss';

interface InstructionsProps {
    category: string;
    gameMode: string;
    containerName: string;
}

const Instructions: React.FC<InstructionsProps> = (props) => {
    const { isAuthenticated } = useAuth0();

    const blobName = `${props.category}-${props.gameMode}-saannot.json`;

    const [loading, setLoading] = React.useState(false);

    const [getDataDone, setGetDataDone] = React.useState<boolean>(false);
    const [rulesData, setRulesData] = React.useState<IRules | undefined>(undefined);
    const [rulesField, setRulesField] = React.useState<string>('');
    const [judgeField, setJudgeField] = React.useState<string>('');
    const [gameTimeField, setGameTimeField] = React.useState<string>('');
    const [otherInfoField, setOtherInfoField] = React.useState<string>('');

    React.useEffect(() => {
        if (!getDataDone) {
            setGetDataDone(true);
            fetchData(blobName, props.containerName).then((item) => {
                const rules = JSON.parse(item as string) as IRules
                setRulesData(rules);
                setRulesField(rules.rules);
                setOtherInfoField(rules.otherInfo);
                setJudgeField(rules.headJudge);
                setGameTimeField(rules.gameTime);
            }).catch(() => {
                setRulesData({ rules: '', otherInfo: '', gameTime: '', headJudge: '' });
                setRulesField('');
                setOtherInfoField('');
                setGameTimeField('');
                setJudgeField('');
            });
        }
    }, [])

    const handleSave = (evt: any) => {
        setLoading(true);
        const newData: IRules = {
            rules: rulesField,
            otherInfo: otherInfoField,
            gameTime: gameTimeField,
            headJudge: judgeField
        }

        postBlobData(newData, blobName, props.containerName).then(() => {
            setLoading(false);
        });
    };

    const handleRulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRulesField(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherInfoField(event.target.value);
    };

    const handleGameTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGameTimeField(event.target.value);
    };

    const handleJudgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setJudgeField(event.target.value);
    };

    return (
        <>
            {!isAuthenticated && rulesData && (
                <>                                    
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" key={`rules-container`}>
                        <Grid item xs={12} key={`gametime`}>
                            <FormControl fullWidth>
                                <TextField
                                    id="gametime"
                                    label="Peliaika"
                                    multiline
                                    rows={4}
                                    value={gameTimeField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`judge`}>
                            <FormControl fullWidth>

                                <TextField
                                    id="headjudge"
                                    label="Päätuomari"
                                    multiline
                                    rows={2}
                                    value={judgeField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`rules`}>
                            <FormControl fullWidth>

                                <TextField
                                    id="rules"
                                    label="Säännöt"
                                    multiline
                                    rows={8}
                                    value={rulesField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`otherInfo`}>
                            <FormControl fullWidth>
                                <TextField
                                    id="otherInfo"
                                    label="Muuta tärkeää"
                                    multiline
                                    rows={8}
                                    value={otherInfoField}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            )}

            {isAuthenticated && rulesData && (
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                >
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="flex-start" key={`rules-container`}>
                        <Grid item xs={12} key={`gametime`}>
                            <FormControl fullWidth>
                                <TextField
                                    id="gametime"
                                    label="Peliaika"
                                    multiline
                                    rows={4}
                                    value={gameTimeField}
                                    onChange={handleGameTimeChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`judge`}>
                            <FormControl fullWidth>

                                <TextField
                                    id="headjudge"
                                    label="Päätuomari"
                                    multiline
                                    rows={2}
                                    value={judgeField}
                                    onChange={handleJudgeChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`rules`}>
                            <FormControl fullWidth>

                                <TextField
                                    id="rules"
                                    label="Säännöt"
                                    multiline
                                    rows={8}
                                    value={rulesField}
                                    onChange={handleRulesChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`otherInfo`}>
                            <FormControl fullWidth>

                                <TextField
                                    id="otherInfo"
                                    label="Muuta tärkeää"
                                    multiline
                                    rows={8}
                                    value={otherInfoField}
                                    onChange={handleDescriptionChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key={`save`}>

                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                onClick={handleSave}
                            >
                                Tallenna
                            </LoadingButton>
                        </Grid>
                    </Grid>

                </Box>
            )}
        </>
    );
}

export default Instructions;