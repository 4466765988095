import { TOURNAMENT } from "../common/consts/tournamentConsts";
import MatchStatus from "../common/enums/matchStatus";
import RoundStatus from "../common/enums/roundStatus";
import { IAllroundScore, IScore } from "../common/interfaces/scoreModels";
import { ITeams } from "../common/interfaces/teamModels";
import { IMatch, IRound, ITournament } from "../common/interfaces/tournamentModels";
import { GetNextFreeNumber, ParseExcludedFields } from "./generalHelper";

function shuffle<T>(array: T[]): T[] {
  let shuffled = array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffled;
}

const getRandomFields = (start: number, end: number, excluded: number[]) => {
  let fields: number[] = [];

  for (let i = start; i <= end; i++) {
    if (excluded.some((e) => e === i)) {
      continue;
    }
    fields.push(i);
  }

  let randomized = shuffle(fields);

  return randomized;
};

const takeRandomTeam = (teamsToChoose: string[], forTeam: string, teamsToExclude: string[]): string => {
  const finalList = teamsToChoose.filter((ttc) => ttc !== forTeam && !teamsToExclude.includes(ttc));
  return finalList[Math.floor(Math.random() * finalList.length)];
};

const pairFirstRound = (roundNumber: number, tournament: ITournament): IMatch[] => {
  let pairAgain: boolean = true;
  let matches: IMatch[] = [];
  let fieldNumber: number = tournament.startingFieldNumber ?? 1;
  const excludedFields: number[] = tournament.excludedFields
    ? tournament.excludedFields.split(",").map((splitted) => {
        const parsed = parseInt(splitted);
        if (!isNaN(parsed)) {
          return parsed;
        }
        return 0;
      })
    : [];

  while (excludedFields.some((ef) => ef === fieldNumber)) {
    fieldNumber += 1;
  }

  while (pairAgain) {
    let teams = tournament.teams.teams.map((t) => t.teamId);
    let rankedTeams = tournament.teams.teams.filter((t) => t.isRanked === true).map((t) => t.teamId);

    if (teams.length % 2 === 1) {
      teams.push(TOURNAMENT.BYE_ID);
    }

    let usedTeams: string[] = [];
    matches = [];
    let matchId: number = Date.now();

    for (let index = 0; index < teams.length; index++) {
      const team = teams[index];
      if (usedTeams.some((ut) => ut === team)) continue;

      const isRankedTeam = rankedTeams.some((rt) => rt === team);

      const against = takeRandomTeam(teams, team, isRankedTeam ? [...usedTeams, ...rankedTeams] : usedTeams);

      usedTeams.push(team);
      usedTeams.push(against);
      matchId++;

      matches.push({
        matchId: matchId.toString(),
        roundNumber: roundNumber,
        status: against === TOURNAMENT.BYE_ID ? MatchStatus.byeWin : MatchStatus.notStarted,
        team1Id: team,
        team1Points: against === TOURNAMENT.BYE_ID ? 13 : undefined,
        team2Id: against,
        team2Points: against === TOURNAMENT.BYE_ID ? tournament.roundRules.byePoints : undefined,
      });
    }

    if (matches.every((m) => m.team1Id && m.team2Id)) {
      pairAgain = false;
    }
  }
  const shuffled = shuffle(matches);

  if (shuffled.some((s) => s.team1Id === TOURNAMENT.BYE_ID || s.team2Id === TOURNAMENT.BYE_ID)) {
    const ghostMatch = shuffled.find((s) => s.team1Id === TOURNAMENT.BYE_ID || s.team2Id === TOURNAMENT.BYE_ID)!;
    const ghostMatchIndex = shuffled.indexOf(ghostMatch);
    shuffled.splice(ghostMatchIndex, 1);
    shuffled.push(ghostMatch);
  }

  const randomFields =
    tournament.randomFields === true ? getRandomFields(tournament.startingFieldNumber ?? 1, tournament.endFieldNumber ?? 0, excludedFields) : [];

  shuffled.forEach((s, sIndex) => {
    if (s.team2Id !== TOURNAMENT.BYE_ID) {
      if (tournament.randomFields === true) {
        s.fieldNumber = randomFields[sIndex] ? randomFields[sIndex] : undefined;
      } else {
        s.fieldNumber = fieldNumber;
        fieldNumber += 1;
        while (excludedFields.some((ef) => ef === fieldNumber)) {
          fieldNumber += 1;
        }
      }
    }
  });

  return shuffled;
};

export const pairRound = (roundNumber: number, tournament: ITournament): IMatch[] => {
  if (roundNumber === 1) {
    return pairFirstRound(roundNumber, tournament);
  }

  let teams = tournament.teams.teams.map((t) => t.teamId);
  let oldMatches: IMatch[] = [];
  let finishedPairs: IMatch[] = [];
  const excludedFields: number[] = tournament.excludedFields
    ? tournament.excludedFields.split(",").map((splitted) => {
        const parsed = parseInt(splitted);
        if (!isNaN(parsed)) {
          return parsed;
        }
        return 0;
      })
    : [];

  const randomFields =
    tournament.randomFields === true ? getRandomFields(tournament.startingFieldNumber ?? 1, tournament.endFieldNumber ?? 0, excludedFields) : [];

  tournament.rounds.forEach((r) => {
    if (r.roundNumber < roundNumber) {
      oldMatches.push(...r.matches);
    }
  });

  let scores: IScore[] = [];
  teams.forEach((t) => {
    scores.push({
      teamId: t,
      b1: 0,
      b2: 0,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      hasBye: false,
    });
  });

  oldMatches.forEach((om) => {
    let homeScore = scores.find((s) => s.teamId === om.team1Id);
    if (homeScore) {
      homeScore.wins += om.status === MatchStatus.team1Wins || om.status === MatchStatus.byeWin ? 1 : 0;
      homeScore.playedAgainst.push(om.team2Id);
      homeScore.hasBye = homeScore.hasBye || om.status === MatchStatus.byeWin;
    }

    let awayScore = scores.find((s) => s.teamId === om.team2Id);
    if (awayScore) {
      awayScore.wins += om.status === MatchStatus.team2Wins ? 1 : 0;
      awayScore.playedAgainst.push(om.team1Id);
    }
  });

  let pairTries: number = 1;
  let matchId: number = Date.now();

  while (pairTries < 100) {
    let minWins = Math.min(...scores.map((s) => s.wins), 0);
    let maxWins = Math.max(...scores.map((s) => s.wins), 0);
    let usedTeams: string[] = [];
    let possibleForBye: string[] = [];

    // Pair possible BYE first
    if (teams.length % 2 === 1) {
      for (let i = minWins; i <= maxWins; i++) {
        const teamsNotAgainstBye = scores.filter((s) => s.wins === i && !s.hasBye);
        possibleForBye.push(...teamsNotAgainstBye.map((tnab) => tnab.teamId));

        if (possibleForBye.length > 0) break;
      }
      const team1ForBye = takeRandomTeam(possibleForBye, TOURNAMENT.BYE_ID, []);
      finishedPairs.push({
        matchId: matchId.toString(),
        roundNumber: roundNumber,
        status: MatchStatus.byeWin,
        team1Id: team1ForBye,
        team2Id: TOURNAMENT.BYE_ID,
        team1Points: 13,
        team2Points: tournament.roundRules.byePoints,
      });
      matchId++;
      usedTeams.push(team1ForBye);
    }

    let teamsToPair: string[] = [];
    let teamsToPairNext: string[] = [];

    for (let i = maxWins; i >= minWins; i--) {
      teamsToPairNext = scores.filter((s) => s.wins === i && usedTeams.every((ut) => ut !== s.teamId)).map((s) => s.teamId);

      if (teamsToPairNext.length > 0) {
        if (teamsToPair.length > 0) {
          const downPairedMatch: IMatch = {
            matchId: matchId.toString(),
            roundNumber: roundNumber,
            status: MatchStatus.notStarted,
            team1Id: teamsToPair[0],
            team2Id: takeRandomTeam(
              teamsToPairNext,
              teamsToPair[0],
              teamsToPairNext.filter((next) => scores.find((s) => s.teamId === teamsToPair[0])!.playedAgainst.some((pa) => pa === next))
            ),
          };
          matchId++;
          teamsToPair.splice(0, 1);
          let indexToRemoveAwayTeam = teamsToPairNext.findIndex((next) => next === downPairedMatch.team2Id);
          teamsToPairNext.splice(indexToRemoveAwayTeam, 1);
          usedTeams.push(downPairedMatch.team1Id);
          usedTeams.push(downPairedMatch.team2Id);
          finishedPairs.push(downPairedMatch);
        }

        teamsToPair.push(...teamsToPairNext);
        let pairedMatches: IMatch[] = [];

        for (let j = 0; j < teamsToPair.length - 1; j = 0) {
          if (teamsToPair.length === 2 && scores.find((s) => s.teamId === teamsToPair[0])!.playedAgainst.some((pa) => pa === teamsToPair[1])) {
            pairTries++;
            finishedPairs.splice(0);
            continue;
          }

          const pairedMatch: IMatch = {
            matchId: matchId.toString(),
            roundNumber: roundNumber,
            status: MatchStatus.notStarted,
            team1Id: teamsToPair[j],
            team2Id: takeRandomTeam(
              teamsToPair,
              teamsToPair[j],
              teamsToPair.filter((next) => scores.find((s) => s.teamId === teamsToPair[j])!.playedAgainst.some((pa) => pa === next))
            ),
          };
          matchId++;
          pairedMatches.push(pairedMatch);
          teamsToPair.splice(j, 1);
          let indexToRemoveAwayTeam = teamsToPair.findIndex((next) => next === pairedMatch.team2Id);
          teamsToPair.splice(indexToRemoveAwayTeam, 1);
          usedTeams.push(pairedMatch.team1Id);
          usedTeams.push(pairedMatch.team2Id);
        }

        const shuffled = shuffle(pairedMatches);
        finishedPairs.push(...shuffled);
      }
    }

    console.log("Paritusyritys: ", pairTries);

    if (teamsToPair.length > 0 || usedTeams.length !== teams.length || finishedPairs.some((fp) => !fp.team1Id || !fp.team2Id)) {
      pairTries++;
      finishedPairs.splice(0);
    } else {
      break;
    }
  }

  if (finishedPairs.some((s) => s.team1Id === TOURNAMENT.BYE_ID || s.team2Id === TOURNAMENT.BYE_ID)) {
    const ghostMatch = finishedPairs.find((s) => s.team1Id === TOURNAMENT.BYE_ID || s.team2Id === TOURNAMENT.BYE_ID)!;
    const ghostMatchIndex = finishedPairs.indexOf(ghostMatch);
    finishedPairs.splice(ghostMatchIndex, 1);
    finishedPairs.push(ghostMatch);
  }

  let fieldNumber: number = tournament.startingFieldNumber ?? 1;
  while (excludedFields.some((ef) => ef === fieldNumber)) {
    fieldNumber += 1;
  }
  finishedPairs.forEach((s, sIndex) => {
    if (tournament.randomFields === true) {
      s.fieldNumber = randomFields[sIndex] ? randomFields[sIndex] : undefined;
    } else {
      s.fieldNumber = fieldNumber;
      fieldNumber += 1;
      while (excludedFields.some((ef) => ef === fieldNumber)) {
        fieldNumber += 1;
      }
    }
  });

  return finishedPairs;
};

export const getSwissScore = (roundNumber: number, tournament: ITournament): IScore[] => {
  let teams = tournament.teams.teams.map((t) => t.teamId);
  let oldMatches: IMatch[] = [];

  tournament.rounds.forEach((r) => {
    if (r.roundNumber <= roundNumber) {
      oldMatches.push(...r.matches);
    }
  });

  let scores: IScore[] = [];
  teams.forEach((t) => {
    scores.push({
      teamId: t,
      b1: 0,
      b2: 0,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      hasBye: false,
    });
  });

  oldMatches.forEach((om) => {
    let homeScore = scores.find((s) => s.teamId === om.team1Id);
    if (homeScore) {
      homeScore.wins += om.status === MatchStatus.team1Wins || om.status === MatchStatus.byeWin ? 1 : 0;
      homeScore.pointsDone += om.team1Points ?? 0;
      homeScore.pointsAgainst += om.team2Points ?? 0;
      homeScore.playedAgainst.push(om.team2Id);
    }

    let awayScore = scores.find((s) => s.teamId === om.team2Id);
    if (awayScore) {
      awayScore.wins += om.status === MatchStatus.team2Wins ? 1 : 0;
      awayScore.pointsDone += om.team2Points ?? 0;
      awayScore.pointsAgainst += om.team1Points ?? 0;
      awayScore.playedAgainst.push(om.team1Id);
    }
  });

  scores.forEach((s) => {
    s.b1 = 0;
    if (s.playedAgainst.length > 0) {
      s.b1 = scores
        .filter((opponents) => s.playedAgainst.some((pa) => pa === opponents.teamId))
        .map((opponent) => opponent.wins)
        .reduce((acc, wins) => {
          return acc + wins;
        }, 0);
    }
  });

  scores.forEach((s) => {
    s.b2 = 0;
    if (s.playedAgainst.length > 0) {
      s.b2 = scores
        .filter((opponents) => s.playedAgainst.some((pa) => pa === opponents.teamId))
        .map((opponent) => opponent.b1)
        .reduce((acc, b1) => {
          return acc + b1;
        }, 0);
    }
  });

  scores.sort(
    (a, b) => b.wins - a.wins || b.b1 - a.b1 || b.b2 - a.b2 || b.pointsDone - b.pointsAgainst - (a.pointsDone - a.pointsAgainst) || b.pointsDone - a.pointsDone
  );

  return scores;
};

export const getCircelBouleCupTeams = (tournament: ITournament): IScore[] => {
  let scores: IScore[] = [];
  const teamsToStay: string[] = [];
  const doubleLossTeams: string[] = [];
  const thirdRoundLosers: string[] = [];

  for (let i = 0; i < (tournament.boules?.length ?? -1); i++) {
    const boule = tournament.boules![i];

    const firstRoundMatches = tournament.rounds
      .find((r) => r.roundNumber === 1 && r.status === RoundStatus.completed)
      ?.matches.filter((m) => boule.teamIds.some((t) => t === m.team1Id) || boule.teamIds.some((t) => t === m.team2Id));
    const secondRoundMatches = tournament.rounds
      .find((r) => r.roundNumber === 2 && r.status === RoundStatus.completed)
      ?.matches.filter((m) => boule.teamIds.some((t) => t === m.team1Id) || boule.teamIds.some((t) => t === m.team2Id));
    const statusAfterSecondRound = GetBouleTeamsToThirdRound(firstRoundMatches!, secondRoundMatches!);

    teamsToStay.push(statusAfterSecondRound.teamToStay);
    doubleLossTeams.push(statusAfterSecondRound.doubleLoss);

    scores.push({
      teamId: statusAfterSecondRound.doubleWin,
      b1: 0,
      b2: 0,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      hasBye: false,
    });
  }

  const thirdRoundMatches = tournament.rounds
    .find((r) => r.roundNumber === 3 && r.status === RoundStatus.completed)
    ?.matches.filter((m) => teamsToStay.some((t) => t === m.team1Id) || teamsToStay.some((t) => t === m.team2Id));

  for (let i = 0; i < teamsToStay.length; i++) {
    const tid = teamsToStay[i];
    const match = thirdRoundMatches!.find((trm) => trm.team1Id === tid || trm.team2Id === tid)!;

    scores.push({
      teamId: match.status === MatchStatus.team1Wins ? match.team1Id : match.team2Id,
      b1: 0,
      b2: 0,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      hasBye: false,
    });

    thirdRoundLosers.push(match.status === MatchStatus.team1Wins ? match.team2Id : match.team1Id);
  }

  thirdRoundLosers.forEach((trl) => {
    scores.push({
      teamId: trl,
      b1: 0,
      b2: 0,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      hasBye: false,
    });
  });

  doubleLossTeams.forEach((dlt) => {
    scores.push({
      teamId: dlt,
      b1: 0,
      b2: 0,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      hasBye: false,
    });
  });

  return scores;
};

export const getAllRoundScore = (tournament: ITournament): IAllroundScore[] => {
  let teams = tournament.teams.teams.map((t) => t.teamId);
  let oldMatches: IMatch[] = [];

  tournament.rounds.forEach((r) => {
    oldMatches.push(...r.matches);
  });

  let scores: IAllroundScore[] = [];
  teams.forEach((t) => {
    scores.push({
      teamId: t,
      playedAgainst: [],
      pointsAgainst: 0,
      pointsDone: 0,
      wins: 0,
      tieBreakerPointsAgainst: 0,
      tieBreakerPointsDone: 0,
      tieBreakerWins: 0,
      needTieBreaker: false,
    });
  });

  oldMatches.forEach((om) => {
    let homeScore = scores.find((s) => s.teamId === om.team1Id);
    if (homeScore) {
      homeScore.wins += om.status === MatchStatus.team1Wins || om.status === MatchStatus.byeWin ? 1 : 0;
      homeScore.pointsDone += om.team1Points ?? 0;
      homeScore.pointsAgainst += om.team2Points ?? 0;
      homeScore.playedAgainst.push(om.team2Id);
    }

    let awayScore = scores.find((s) => s.teamId === om.team2Id);
    if (awayScore) {
      awayScore.wins += om.status === MatchStatus.team2Wins ? 1 : 0;
      awayScore.pointsDone += om.team2Points ?? 0;
      awayScore.pointsAgainst += om.team1Points ?? 0;
      awayScore.playedAgainst.push(om.team1Id);
    }
  });

  scores.forEach((s) => {
    if (scores.filter((s2) => s2.wins === s.wins).length > 1) {
      s.needTieBreaker = true;
      s.playedAgainst.forEach((opponent) => {
        if (opponent !== TOURNAMENT.BYE_ID) {
          if (scores.find((opponentScore) => opponentScore.teamId === opponent)!.wins === s.wins) {
            const homeMatch = oldMatches.find((m) => m.team1Id === s.teamId && m.team2Id === opponent);
            if (homeMatch) {
              s.tieBreakerWins += homeMatch.status === MatchStatus.team1Wins ? 1 : 0;
              s.tieBreakerPointsDone += homeMatch.team1Points ?? 0;
              s.tieBreakerPointsAgainst += homeMatch.team2Points ?? 0;
            }

            const awayMatch = oldMatches.find((m) => m.team2Id === s.teamId && m.team1Id === opponent);
            if (awayMatch) {
              s.tieBreakerWins += awayMatch.status === MatchStatus.team2Wins ? 1 : 0;
              s.tieBreakerPointsDone += awayMatch.team2Points ?? 0;
              s.tieBreakerPointsAgainst += awayMatch.team1Points ?? 0;
            }
          }
        }
      });
    }
  });

  scores.sort(
    (a, b) =>
      b.wins - a.wins ||
      b.tieBreakerWins - a.tieBreakerWins ||
      b.tieBreakerPointsDone - b.tieBreakerPointsAgainst - (a.tieBreakerPointsDone - a.tieBreakerPointsAgainst) ||
      b.tieBreakerPointsDone - a.tieBreakerPointsDone
  );

  return scores;
};

export const PairAllRound = (teams: ITeams, fieldStart?: number, excludedFields?: string, doRandomFields?: boolean, endFieldNumber?: number): IRound[] => {
  const createdRounds: IRound[] = [];
  let matchId: number = Date.now();
  const excludedFieldsArray: number[] = ParseExcludedFields(excludedFields);

  // const randomFields = doRandomFields === true ? getRandomFields(fieldStart ?? 1, endFieldNumber ?? 40, excludedFieldsArray) : [];

  const teamIds = teams.teams.map((t) => t.teamId);

  if (teamIds.length % 2 === 1) {
    teamIds.push(TOURNAMENT.BYE_ID);
  }

  const allPossibleMatches: IMatch[] = [];

  for (let i = 0; i < teamIds.length; i++) {
    for (let j = i + 1; j < teamIds.length; j++) {
      allPossibleMatches.push({
        matchId: matchId.toString(),
        roundNumber: 0,
        status: MatchStatus.notStarted,
        team1Id: teamIds[i],
        team2Id: teamIds[j],
      });
      matchId++;
    }
  }

  const maxPairsInRound = teamIds.length / 2;

  for (let i = 1; i < teamIds.length; i++) {
    // let fieldNumber: number = GetNextFreeNumber(
    //   (fieldStart ?? 1) - 1,
    //   excludedFieldsArray
    // );
    const pairedIndexes = Array.from({ length: maxPairsInRound - 1 }, () => 0);
    for (let j = 0, pairs = maxPairsInRound; j < allPossibleMatches.length || pairs > 0; j++) {
      if (j === allPossibleMatches.length && pairs > 0) {
        const lastIndex = maxPairsInRound - 1 - pairs;
        j = pairedIndexes[lastIndex];
        allPossibleMatches[j].roundNumber = 0;
        pairedIndexes[lastIndex] = 0;
        pairs += 1;
        continue;
      }

      if (
        allPossibleMatches.some(
          (apm) =>
            (apm.roundNumber === i &&
              (apm.team1Id === allPossibleMatches[j].team1Id ||
                apm.team2Id === allPossibleMatches[j].team1Id ||
                apm.team1Id === allPossibleMatches[j].team2Id ||
                apm.team2Id === allPossibleMatches[j].team2Id)) ||
            (apm.roundNumber < i &&
              apm.roundNumber > 0 &&
              ((apm.team1Id === allPossibleMatches[j].team1Id && apm.team2Id === allPossibleMatches[j].team2Id) ||
                (apm.team1Id === allPossibleMatches[j].team2Id && apm.team2Id === allPossibleMatches[j].team1Id)))
        )
      ) {
        continue;
      }
      allPossibleMatches[j].roundNumber = i;
      if (pairs !== 1) {
        pairedIndexes[maxPairsInRound - pairs] = j;
      }
      // allPossibleMatches[j].fieldNumber = fieldNumber;
      // fieldNumber = GetNextFreeNumber(fieldNumber, excludedFieldsArray);
      pairs -= 1;
    }

    createdRounds.push({
      roundNumber: i,
      status: RoundStatus.paired,
      name: "", //possible place for cup name
      matches: allPossibleMatches.filter((apm) => apm.roundNumber === i),
    });
  }

  return createdRounds;
};

export const pairBoule = (roundNumber: number, tournament: ITournament): IMatch[] => {
  const matches: IMatch[] = [];
  let matchId: number = Date.now();
  const excludedFieldsArray: number[] = ParseExcludedFields(tournament.excludedFields);

  const randomFields =
    tournament.randomFields === true ? getRandomFields(tournament.startingFieldNumber ?? 1, tournament.endFieldNumber ?? 0, excludedFieldsArray) : [];
  let randomFieldIndex = 0;

  let fieldNumber: number =
    tournament.randomFields === true ? randomFields[randomFieldIndex] : GetNextFreeNumber((tournament.startingFieldNumber ?? 1) - 1, excludedFieldsArray);

  if (tournament.boules) {
    if (roundNumber === 1) {
      for (let i = 0; i < tournament.boules.length; i++) {
        const boule = tournament.boules[i];
        matchId++;
        matches.push({
          matchId: matchId.toString(),
          fieldNumber: fieldNumber,
          roundNumber: 1,
          status: MatchStatus.notStarted,
          team1Id: boule.teamIds[0],
          team2Id: boule.teamIds[2],
        });
        matchId++;
        randomFieldIndex++;
        fieldNumber = tournament.randomFields === true ? randomFields[randomFieldIndex] : GetNextFreeNumber(fieldNumber, excludedFieldsArray);
        matches.push({
          matchId: matchId.toString(),
          fieldNumber: fieldNumber,
          roundNumber: 1,
          status: MatchStatus.notStarted,
          team1Id: boule.teamIds[1],
          team2Id: boule.teamIds[3],
        });
        randomFieldIndex++;
        fieldNumber = tournament.randomFields === true ? randomFields[randomFieldIndex] : GetNextFreeNumber(fieldNumber, excludedFieldsArray);
      }
    } else if (roundNumber === 2) {
      for (let i = 0; i < tournament.boules.length; i++) {
        const previousRoundMatches = GetBouleRoundMatches(tournament, i, 1);

        matchId++;
        matches.push({
          matchId: matchId.toString(),
          fieldNumber: fieldNumber,
          roundNumber: 2,
          status: MatchStatus.notStarted,
          team1Id: previousRoundMatches[0].status === MatchStatus.team1Wins ? previousRoundMatches[0].team1Id : previousRoundMatches[0].team2Id,
          team2Id: previousRoundMatches[1].status === MatchStatus.team1Wins ? previousRoundMatches[1].team1Id : previousRoundMatches[1].team2Id,
        });
        randomFieldIndex++;
        fieldNumber = tournament.randomFields === true ? randomFields[randomFieldIndex] : GetNextFreeNumber(fieldNumber, excludedFieldsArray);
        matchId++;
        matches.push({
          matchId: matchId.toString(),
          fieldNumber: fieldNumber,
          roundNumber: 2,
          status: MatchStatus.notStarted,
          team1Id: previousRoundMatches[0].status === MatchStatus.team1Wins ? previousRoundMatches[0].team2Id : previousRoundMatches[0].team1Id,
          team2Id: previousRoundMatches[1].status === MatchStatus.team1Wins ? previousRoundMatches[1].team2Id : previousRoundMatches[1].team1Id,
        });
        randomFieldIndex++;
        fieldNumber = tournament.randomFields === true ? randomFields[randomFieldIndex] : GetNextFreeNumber(fieldNumber, excludedFieldsArray);
      }
    } else if (roundNumber === 3) {
      for (let i = 0; i < tournament.boules.length; i++) {
        const firstRoundMatches = GetBouleRoundMatches(tournament, i, 1);
        const secondRoundMatches = GetBouleRoundMatches(tournament, i, 2);

        const bouleTeams = GetBouleTeamsToThirdRound(firstRoundMatches, secondRoundMatches);

        const previousBouleFirstRoundMatches = GetBouleRoundMatches(tournament, i === 0 ? tournament.boules.length - 1 : i - 1, 1);
        const previousBouleSecondRoundMatches = GetBouleRoundMatches(tournament, i === 0 ? tournament.boules.length - 1 : i - 1, 2);

        const previousBouleTeams = GetBouleTeamsToThirdRound(previousBouleFirstRoundMatches, previousBouleSecondRoundMatches);

        matchId++;
        matches.push({
          matchId: matchId.toString(),
          fieldNumber: fieldNumber,
          roundNumber: 3,
          status: MatchStatus.notStarted,
          team1Id: bouleTeams.teamToStay,
          team2Id: previousBouleTeams.teamToPairNextBoule,
        });
        randomFieldIndex++;
        fieldNumber = tournament.randomFields === true ? randomFields[randomFieldIndex] : GetNextFreeNumber(fieldNumber, excludedFieldsArray);
      }
    }
  }
  return matches;
};

function GetBouleRoundMatches(tournament: ITournament, i: number, roundNumber: number) {
  const boule = tournament.boules![i];

  const previousRoundMatches = tournament.rounds
    .filter((r) => r.roundNumber === roundNumber)[0]
    .matches.filter((m) => boule.teamIds.some((t) => t === m.team1Id) || boule.teamIds.some((t) => t === m.team2Id));
  return previousRoundMatches;
}

export function GetBouleTeamsToThirdRound(firstRoundMatches: IMatch[], secondRoundMatches: IMatch[]) {
  const firstRoundWinners: string[] = [];
  firstRoundMatches.forEach((frm) => {
    firstRoundWinners.push(frm.status === MatchStatus.team1Wins ? frm.team1Id : frm.team2Id);
  });
  const secondRoundWinners: string[] = [];
  secondRoundMatches.forEach((srw) => {
    secondRoundWinners.push(srw.status === MatchStatus.team1Wins ? srw.team1Id : srw.team2Id);
  });
  const teamToStay = firstRoundWinners.filter((frw) => secondRoundWinners.every((srw) => srw !== frw))[0];
  const teamToPairNextBoule = secondRoundWinners.filter((srw) => firstRoundWinners.every((frw) => srw !== frw))[0];
  const doubleWin = firstRoundWinners.filter((frw) => secondRoundWinners.some((srw) => srw === frw))[0];
  let doubleLoss = "";
  firstRoundMatches.forEach((frm) => {
    if (firstRoundWinners.every((frw) => frw !== frm.team1Id) && secondRoundWinners.every((srw) => srw !== frm.team1Id)) {
      doubleLoss = frm.team1Id;
    }
    if (firstRoundWinners.every((frw) => frw !== frm.team2Id) && secondRoundWinners.every((srw) => srw !== frm.team2Id)) {
      doubleLoss = frm.team2Id;
    }
  });

  return {
    teamToStay,
    teamToPairNextBoule,
    doubleWin,
    doubleLoss,
  };
}
