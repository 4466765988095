import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

if (!new class { x: any }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain='dev-ho0toans.us.auth0.com'
      clientId='7aGSaB0PbqSPgAXRAH5Yi1f62kETQWF7'
      redirectUri={window.location.origin}        
      >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);
