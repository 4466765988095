import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, IconButton } from '@mui/material';
import * as React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { fetchData, postBlobData } from '../../services/blobService';
import { IPlayer, ITeams } from '../interfaces/teamModels';
import { printTeamName } from '../utils/teamHelper';
import styles from './featureStyles.module.scss';
import EditTeam from '../components/editTeam';
import { GameMode } from '../enums/gameMode';
import LoadingSpinner from '../components/loadingSpinner';
import YesNoModal from '../components/yesNoModal';
import { ITournament } from '../interfaces/tournamentModels';
import TournamentStatus from '../enums/tournamentStatus';
import { isClubOrganizerOrAdmin } from '../../services/authorizationService';
import store from '../../stores/eventStore';

interface TeamsProps {
    category: string;
    gameMode: GameMode;
    containerName: string;
}

const Teams: React.FC<TeamsProps> = (props) => {
    const { isAuthenticated, user } = useAuth0();

    const blobName = `${props.category}-${props.gameMode}-joukkueet.json`;
    const tuloksetBlobName = `${props.category}-${props.gameMode}-tulokset.json`;

    const [loading, setLoading] = React.useState<boolean>(true);
    const [getDataDone, setGetDataDone] = React.useState<boolean>(false);
    const [teamData, setTeamData] = React.useState<ITeams | undefined>(undefined);
    const [tournamentData, setTournamentData] = React.useState<ITournament | undefined>(undefined);
    const [disableAddDelete, setDisableAddDelete] = React.useState<boolean>(false);
    const [addTeam, setAddTeam] = React.useState<boolean>(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);
    const [indexToDelete, setIndexToDelete] = React.useState<number>(-1);
    const [showEditTeam, setShowEditTeam] = React.useState<boolean>(false);
    const [editIndex, setEditIndex] = React.useState<number>(-1);


    React.useEffect(() => {
        if (!getDataDone) {
            setGetDataDone(true);
            fetchData(blobName, props.containerName).then((item) => {
                const teams = JSON.parse(item as string)
                setTeamData(teams);
                setLoading(false);
            }).catch((e: any) => {
                setTeamData({ teams: [] });
                setLoading(false);
            });
        }
    }, [])

    React.useEffect(() => {
        if (isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && !tournamentData) {
            fetchData(tuloksetBlobName, props.containerName).then((item) => {
                const tournamentData = JSON.parse(item as string)
                setTournamentData(tournamentData);
                if (tournamentData && tournamentData.status !== TournamentStatus.notStarted) {
                    setDisableAddDelete(true);
                } else {
                    setDisableAddDelete(false);
                }
            }).catch(() => {
                setTournamentData(undefined);
            })
        }
    }, [isAuthenticated])

    const updateResultJson = (newTeamData: ITeams) => {
        if (tournamentData) {
            const updatedTournamentData = tournamentData;
            updatedTournamentData.teams = newTeamData;
            setTournamentData(updatedTournamentData);
            postBlobData(updatedTournamentData, tuloksetBlobName, props.containerName);
        }
    }

    const saveEdit = (index: number, players: IPlayer[], teamId: string, isEdit: boolean, isRanked: boolean) => {
        if (isEdit) {
            const newTeamData = teamData;
            if (newTeamData) {
                newTeamData.teams.forEach((t) => {
                    if (t.teamId === teamId) {
                        t.players = players;
                        t.isRanked = isRanked;
                    }
                })
                setTeamData(newTeamData);
                postBlobData(newTeamData, blobName, props.containerName).then(() => {
                    setEditIndex(-1);
                    setShowEditTeam(false);

                    updateResultJson(newTeamData);
                });
            }
        } else {
            const newTeamData = teamData;
            if (newTeamData) {
                newTeamData.teams.push({ teamId: teamId, players: players, isRanked: isRanked });
                setTeamData(newTeamData);
                postBlobData(newTeamData, blobName, props.containerName).then(() => {
                    setAddTeam(false);
                    setShowEditTeam(false);

                    updateResultJson(newTeamData);
                });
            }
        }
    }

    const deleteTeam = (index: number) => {
        if (teamData && teamData.teams[index]) {
            const newTeams = teamData.teams;
            newTeams.splice(index, 1);
            const newTeamData = { teams: newTeams };
            setTeamData(newTeamData);
            postBlobData(newTeamData, blobName, props.containerName);
            setIndexToDelete(-1);
            updateResultJson(newTeamData);
        }
        setShowDeleteConfirmation(false);
    }

    return (
        <>
            {loading ? <LoadingSpinner /> : (
                <>
                    {teamData && teamData.teams.map((t, i) => {
                        return (
                            <Grid container spacing={0.5} justifyContent="flex-start" alignItems="flex-start" key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-container`}>
                                <Grid item xs={1} key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-index`}>
                                    <p key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-index-label`} className={styles.teamRow}>{i + 1}</p>
                                </Grid>
                                {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
                                    <Grid item xs={1} key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-edit-button`}>
                                        <IconButton
                                            key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-edit-icon`}
                                            aria-label="edit"
                                            className={styles.teamRow}
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                setEditIndex(i);
                                                setShowEditTeam(true);
                                            }}>
                                            <EditIcon fontSize="inherit" key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-edit-icon-icon`} />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item xs={isAuthenticated ? 9 : 11} key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-teamname`}>
                                    <p key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-teamname-text`} className={styles.teamRow}>{printTeamName(t)}</p>
                                </Grid>
                                {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && !disableAddDelete && (
                                    <Grid item xs={1} key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-delete`}>
                                        <IconButton
                                            key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-delete-icon`}
                                            aria-label="delete"
                                            className={styles.teamRow}
                                            size="small"
                                            color="primary"
                                            onClick={() => {
                                                setIndexToDelete(i);
                                                setShowDeleteConfirmation(true);
                                            }}>
                                            <DeleteIcon fontSize="inherit" key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-delete-icon-icon`} />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item xs={12} key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-divider`}>
                                    <hr key={`joukkue-${props.category}-${props.gameMode}-${i + 1}-divider-element`} />
                                </Grid>
                            </Grid>
                        );
                    })}

                    {(!teamData || teamData.teams.length === 0) && (
                        <Grid item xs={12} key={`joukkue-${props.category}-${props.gameMode}-no-teams`}>
                            {(!isAuthenticated || !(isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs))) && (
                                <p>Ilmoittautuneet julkaistaan myöhemmin!</p>
                            )}
                        </Grid>

                    )}

                    {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
                        <Grid item xs={12} key={`joukkue-${props.category}-${props.gameMode}-add-new`}>
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon />}
                                aria-label="add"
                                onClick={() => {
                                    setAddTeam(true);
                                    setShowEditTeam(true);
                                }}
                                disabled={disableAddDelete}
                            >
                                Lisää joukkue
                            </Button>
                        </Grid>
                    )}
                </>

            )}
            <YesNoModal
                id='delete-team'
                label='Haluatko varmasti poistaa joukkueen'
                onCancel={() => {
                    setShowDeleteConfirmation(false);
                    setIndexToDelete(-1);
                }}
                onNo={() => {
                    setShowDeleteConfirmation(false);
                    setIndexToDelete(-1);
                }}
                onYes={() => { deleteTeam(indexToDelete); }}
                open={showDeleteConfirmation}
            />
            <EditTeam
                gameMode={props.gameMode}
                index={editIndex}
                isEdit={!addTeam}
                onCancel={() => {
                    setAddTeam(false);
                    setShowEditTeam(false);
                    setEditIndex(-1);
                }}
                onSave={saveEdit}
                open={showEditTeam}
                players={(!addTeam && teamData && editIndex >= 0) ? teamData.teams[editIndex].players : []}
                teamId={(!addTeam && teamData && editIndex >= 0) ? teamData.teams[editIndex].teamId : ''}
                isRanked={(!addTeam && teamData && editIndex >= 0) ? ( teamData.teams[editIndex].isRanked ?? false) : false}
            />
        </>
    );
}

export default Teams;