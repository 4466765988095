import { BlobServiceClient } from "@azure/storage-blob";
import { IConfigurationConstants } from "../common/interfaces/IConfigurationConstants";
import { IEvents } from "../common/interfaces/IEvent";
import { IRules } from "../common/interfaces/IRules";
import { IPenalty } from "../common/interfaces/penaltyModels";
import { ITeams } from "../common/interfaces/teamModels";
import { ITournament } from "../common/interfaces/tournamentModels";

const sas = "/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-04-30T13:00:22Z&st=2024-04-16T05:00:22Z&spr=https&sig=Ns4OAf0uoJamoaA5svMKx50bizb9Ms3v8FYEifM3YGU%3D";
const url = 'https://petankkisa.blob.core.windows.net' + sas;

const blobServiceClient = new BlobServiceClient(url);

export async function blobToString(blob: any) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
            if (ev && ev.target)
                resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
    });
}

export function createContainer(containerName: string) {
    blobServiceClient.createContainer(containerName);
}

export async function fetchData(blobName: string, containerName: string) {    
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);

    return downloaded;
}

export async function postBlobData(data: IRules | ITeams | ITournament | IPenalty[] | IConfigurationConstants | IEvents, blobName: string, containerName: string) {
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(blobName);

    const options = { blobHTTPHeaders: { blobContentType: 'json' } };

    await blobClient.upload(JSON.stringify(data, null, 2), 1, options);
}

