import * as React from "react";
import "dayjs/locale/fi";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import commonStyles from "../styles/common.module.scss";
import { IEvent, IEvents, IEventType } from "../interfaces/IEvent";
import dayjs, { Dayjs } from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DateTimePicker,
  LocalizationProvider,
  fiFI,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createContainer,
  fetchData,
  postBlobData,
} from "../../services/blobService";
import { IConfigurationConstants } from "../interfaces/IConfigurationConstants";
import { Makeid } from "../../services/generalHelper";
import { getClubs, hasRole, ROLES } from "../../services/authorizationService";
import { Category } from "../enums/category";
import { GameMode } from "../enums/gameMode";
import { useNavigate } from "react-router-dom";
import { clubs, getAllShortClubs } from "../consts/clubs";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  fiFI // use 'de' locale for UI texts (start, next month, ...)
);

interface EventProps {
  add: boolean;
  eventInfo?: IEvent;
}

const Event: React.FC<EventProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  const eventsBlobName = "events.json";
  const constantsBlobName = "constants.json";
  const eventsContainerName = "events";

  const [eventName, setEventName] = React.useState<string>(
    props.eventInfo?.name ?? ""
  );
  const [eventDescription, setEventDescription] = React.useState<string>(
    props.eventInfo?.description ?? ""
  );
  const [startTime, setStartTime] = React.useState<Dayjs | null>(
    props.eventInfo?.startingTime
      ? dayjs(props.eventInfo?.startingTime)
      : dayjs()
  );
  const [organizers, setOrganizers] = React.useState<string[]>(
    props.eventInfo?.organizerClubs ?? []
  );

  const [eventTypes, setEventTypes] = React.useState<IEventType[]>(
    props.eventInfo?.types ?? []
  );

  const [errorContainer, setErrorContainer] = React.useState<string[]>([]);

  const toggleOrganizer = (organizer: string, checked: boolean) => {
    const newOrganizers = [...organizers];

    if (checked) {
      newOrganizers.push(organizer);
    } else {
      newOrganizers.splice(newOrganizers.indexOf(organizer), 1);
    }

    setOrganizers(newOrganizers);
  };

  const toggleCategory = (category: Category, checked: boolean) => {
    const newEventTypes = [...eventTypes];

    if (checked) {
      newEventTypes.push({
        category: category,
        gameModes: [],
      });
    } else {
      newEventTypes.splice(
        newEventTypes.indexOf(
          newEventTypes.filter((net) => net.category === category)[0]
        ),
        1
      );
    }

    setEventTypes(newEventTypes);
  };

  const toggleGameMode = (
    category: Category,
    gameMode: GameMode,
    checked: boolean
  ) => {
    const newEventTypes = [...eventTypes];
    const newGameModes = newEventTypes.find(
      (net) => net.category === category
    )!.gameModes;

    if (checked) {
      newGameModes.push(gameMode);
    } else {
      newGameModes.splice(
        newGameModes.indexOf(newGameModes.find((ngm) => ngm === gameMode)!),
        1
      );
    }
    newEventTypes.find((net) => net.category === category)!.gameModes =
      newGameModes;

    setEventTypes(newEventTypes);
  };

  const handleStartTimeChange = (newValue: Dayjs | null) => {
    setStartTime(newValue);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventName(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEventDescription(event.target.value);
  };

  const validate = (): boolean => {
    const validationErrors: string[] = [];
    if (!eventName) validationErrors.push("name");
    if (organizers.length < 1) validationErrors.push("organizer");
    if (eventTypes.length < 1) validationErrors.push("category");
    eventTypes.forEach((et) => {
      if (et.gameModes.length < 1)
        validationErrors.push(et.category.toString());
    });
    setErrorContainer(validationErrors);
    return validationErrors.length > 0 ? false : true;
  };

  const handleSubmit = (_event: object, reason: string) => {
    if (!validate()) return;

    if (reason === "add") {
      fetchData(constantsBlobName, eventsContainerName).then((item) => {
        const configs = JSON.parse(item as string) as IConfigurationConstants;
        configs.lastUsedId++;
        let containerName = `${configs.lastUsedId.toString()}-${organizers[0].toLowerCase()}-${Makeid(
          8
        ).toLowerCase()}`;
        createContainer(containerName);

        fetchData(eventsBlobName, eventsContainerName).then((eventData) => {
          const events = JSON.parse(eventData as string) as IEvents;
          events.events.push({
            blobContainer: containerName,
            description: eventDescription,
            eventId: configs.lastUsedId.toString(),
            name: eventName,
            organizerClubs: organizers,
            startingTime: startTime!.toDate(),
            types: eventTypes,
          });
          postBlobData(events, eventsBlobName, eventsContainerName).then(() => {
            postBlobData(configs, constantsBlobName, eventsContainerName).then(
              () => {
                navigate("/");
              }
            );
          });
        });
      });
    } else if (reason === "edit") {
      fetchData(eventsBlobName, eventsContainerName).then((eventData) => {
        const events = JSON.parse(eventData as string) as IEvents;
        events.events.splice(
          events.events.indexOf(
            events.events.find((e) => e.eventId === props.eventInfo!.eventId)!
          ),
          1
        );
        events.events.push({
          blobContainer: props.eventInfo!.blobContainer,
          description: eventDescription,
          eventId: props.eventInfo!.eventId,
          name: eventName,
          organizerClubs: organizers,
          startingTime: startTime!.toDate(),
          types: eventTypes,
          adminOnly: props.eventInfo!.adminOnly,
          endDate: props.eventInfo!.endDate,
        });
        postBlobData(events, eventsBlobName, eventsContainerName).then(() => {
          navigate("/");
        });
      });
    }
  };

  const getErrorText = (name: string): string => {
    switch (name) {
      case "name":
        return "Turnauksen nimi on pakollinen.";
      case "organizer":
        return "Vähintään yksi järjestäjä on valittavat.";
      case "category":
        return "Vähintään yksi kategoria on valittava.";
      case "gameMode":
        return "Vähintään yksi pelimuoto on valittavat.";
      default:
        return "";
    }
  };

  const getOrganizerValues = () => {
    if (hasRole(user, ROLES.admin)) {
      return getAllShortClubs();
    }
    return getClubs(user);
  };

  const getEventTypes = () => {
    return [
      { value: Category.yleinen, text: "Yleinen" },
      { value: Category.naiset, text: "Naiset" },
      { value: Category.juniorit, text: "Juniorit" },
      { value: Category.vet55, text: "Veteraanit - 55" },
      { value: Category.vet65, text: "Veteraanit - 65" },
    ];
  };

  const getGameModes = () => {
    return [
      { value: GameMode.singeli, text: "Singeli" },
      { value: GameMode.dubbeli, text: "Duppeli" },
      { value: GameMode.trippeli, text: "Trippeli" },
      { value: GameMode.sekaDubbeli, text: "Sekaduppeli" },
    ];
  };

  React.useEffect(() => {
    if (props.eventInfo) {
      setEventName(props.eventInfo.name);
      setStartTime(dayjs(props.eventInfo.startingTime));
      setEventDescription(props.eventInfo.description);
      setOrganizers(props.eventInfo.organizerClubs);
      setEventTypes(props.eventInfo.types);
    }
  }, [props.eventInfo]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      style={{
        margin: 10,
      }}
    >
      <Grid container spacing={2} justifyContent="flex-start">
        {isAuthenticated && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="eventName"
                  label="Nimi*"
                  className={commonStyles.bottomMargin}
                  value={eventName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleNameChange(e);
                  }}
                />
                {errorContainer.some((ec) => ec === "name") && (
                  <Typography sx={{ color: "red" }}>
                    * {getErrorText("name")}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fi"
                  >
                    <DateTimePicker
                      label="Aloitusaika*"
                      value={startTime}
                      onChange={handleStartTimeChange}
                      ampm={false}
                      format={"DD.MM.YYYY [klo] HH.mm"}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="description"
                  label="Kuvaus"
                  type="text"
                  multiline={true}
                  rows={4}
                  value={eventDescription}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleDescriptionChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Box
                  sx={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "lightgray",
                    padding: 2,
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>Järjestävät organisaatiot*</Typography>
                  {getOrganizerValues().map((o: string) => {
                    return (
                      <FormControlLabel
                        key={o}
                        control={
                          <Checkbox
                            key={`${o}-${
                              clubs.find((c) => c.tunnus === o)!.id
                            }-cb`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              toggleOrganizer(o, e.target.checked);
                            }}
                          />
                        }
                        label={clubs.find(c => c.tunnus === o)!.name}
                        checked={organizers.some((org) => org === o)}
                      />
                    );
                  })}
                  {errorContainer.some((ec) => ec === "organizer") && (
                    <Typography sx={{ color: "red" }}>
                      * {getErrorText("organizer")}
                    </Typography>
                  )}
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Box
                  sx={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "lightgray",
                    padding: 2,
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>Pelimuodot*</Typography>
                  {getEventTypes().map((etypes: any) => {
                    return (
                      <React.Fragment key={etypes.value.toString()}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                toggleCategory(etypes.value, e.target.checked);
                              }}
                            />
                          }
                          label={etypes.text}
                          checked={eventTypes.some(
                            (et) => et.category === etypes.value
                          )}
                        />
                        {eventTypes.some(
                          (x) => x.category === etypes.value
                        ) && (
                          <Box
                            sx={{
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "lightgray",
                              padding: 2,
                              borderRadius: 1,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {getGameModes().map((gmodes) => {
                              return (
                                <FormControlLabel
                                  key={`${gmodes.value.toString()}-${etypes.value.toString()}`}
                                  control={
                                    <Checkbox
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        toggleGameMode(
                                          etypes.value,
                                          gmodes.value,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  }
                                  label={gmodes.text}
                                  checked={eventTypes
                                    .find((et) => et.category === etypes.value)!
                                    .gameModes.some(
                                      (gm) => gm === gmodes.value
                                    )}
                                />
                              );
                            })}
                            {errorContainer.some(
                              (ec) => ec === etypes.value.toString()
                            ) && (
                              <Typography sx={{ color: "red" }}>
                                * {getErrorText("gameMode")}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {errorContainer.some((ec) => ec === "category") && (
                    <Typography sx={{ color: "red" }}>
                      * {getErrorText("category")}
                    </Typography>
                  )}
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12} className={commonStyles.leftAlign}>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                aria-label="save"
                onClick={(event) => {
                  handleSubmit(event, props.add ? "add" : "edit");
                }}
              >
                {props.add ? "Lisää turnaus" : "Tallenna turnaus"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Event;
