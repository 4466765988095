import { useAuth0 } from '@auth0/auth0-react';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Modal, Switch, TextField } from '@mui/material';
import * as React from 'react';
import { GameMode } from '../enums/gameMode';
import { IPlayer } from '../interfaces/teamModels';
import commonStyles from '../styles/common.module.scss'


interface EditTeamProps {
    players: IPlayer[];
    teamId: string;
    isRanked: boolean;
    index: number;
    gameMode: GameMode;
    isEdit: boolean;
    open: boolean;
    onCancel: () => void;
    onSave: (index: number, players: IPlayer[], teamId: string, isEdit: boolean, isRanked: boolean) => void
}

const EditTeam: React.FC<EditTeamProps> = (props) => {
    const { isAuthenticated } = useAuth0();

    const [open, setOpen] = React.useState<boolean>(props.open);
    const [player1Name, setPlayer1Name] = React.useState<string>(props.players[0]?.name ?? '');
    const [player1Club, setPlayer1Club] = React.useState<string>(props.players[0]?.club ?? '');
    const [player2Name, setPlayer2Name] = React.useState<string>(props.players[1]?.name ?? '');
    const [player2Club, setPlayer2Club] = React.useState<string>(props.players[1]?.club ?? '');
    const [player3Name, setPlayer3Name] = React.useState<string>(props.players[2]?.name ?? '');
    const [player3Club, setPlayer3Club] = React.useState<string>(props.players[2]?.club ?? '');
    const [isRanked, setIsRanked] = React.useState(props.isRanked);

    const handleRankedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRanked(event.target.checked);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>, numberOfPlayer: number) => {
        switch (numberOfPlayer) {
            case 1:
                setPlayer1Name(event.target.value)
                break;
            case 2:
                setPlayer2Name(event.target.value)
                break;
            case 3:
                setPlayer3Name(event.target.value)
                break;

            default:
                break;
        }
    };

    const handleClubChange = (event: React.ChangeEvent<HTMLInputElement>, numberOfPlayer: number) => {
        switch (numberOfPlayer) {
            case 1:
                setPlayer1Club(event.target.value)
                break;
            case 2:
                setPlayer2Club(event.target.value)
                break;
            case 3:
                setPlayer3Club(event.target.value)
                break;

            default:
                break;
        }
    };

    const getPlayers = (): IPlayer[] => {
        const players: IPlayer[] = [];
        switch (props.gameMode) {
            case GameMode.singeli:
                players.push({ name: player1Name, club: player1Club });
                break;
            case GameMode.dubbeli:
            case GameMode.sekaDubbeli:
                players.push({ name: player1Name, club: player1Club });
                players.push({ name: player2Name, club: player2Club });
                break;
            case GameMode.trippeli:
                players.push({ name: player1Name, club: player1Club });
                players.push({ name: player2Name, club: player2Club });
                players.push({ name: player3Name, club: player3Club });
                break;
            default:
                break;
        }
        return players;
    }

    const handleClose = (_event: object, reason: string) => {
        if (reason === 'backdropClick') return;

        if (reason === 'save') {
            props.onSave(props.index, getPlayers(), props.isEdit ? props.teamId : Date.now().toString(), props.isEdit, isRanked);
        }

        if (reason === 'cancel') {
            props.onCancel();
        }

        setOpen(false);
    }

    React.useEffect(() => {
        setOpen(props.open);

        if (props.open) {
            setPlayer1Name(props.players[0]?.name ?? '');
            setPlayer1Club(props.players[0]?.club ?? '');
            setPlayer2Name(props.players[1]?.name ?? '');
            setPlayer2Club(props.players[1]?.club ?? '');
            setPlayer3Name(props.players[2]?.name ?? '');
            setPlayer3Club(props.players[2]?.club ?? '');
            setIsRanked(props.isRanked);
        }
    }, [props.open])

    return (
        <>
            <Modal
                key={`edit-team-modal`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
            >
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    className={commonStyles.modalStyle}
                >
                    <Grid container spacing={2} key={`edit-team-modal-container`} justifyContent='flex-start'>
                        <Grid item xs={12} key={`confirmation-modal-cancel-${props.index}`} className={commonStyles.rightAlign}>
                            <IconButton aria-label="cancel"
                                className={commonStyles.noEmptyTopBottom}
                                size="medium"
                                color="error"
                                onClick={(event) => {
                                    handleClose(event, 'cancel');
                                }}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                        {isAuthenticated && (
                            <>
                                <Grid item xs={12} key={`joukkue-${props.gameMode}-${props.index}-edit-player1`}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="player1name"
                                            label="Nimi"
                                            className={commonStyles.bottomMargin}
                                            value={player1Name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleNameChange(e, 1);
                                            }}
                                        />
                                        <TextField
                                            id="player1club"
                                            label="Seura"
                                            value={player1Club}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleClubChange(e, 1);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                {props.gameMode !== GameMode.singeli && (
                                    <Grid item xs={12} key={`joukkue-${props.gameMode}-${props.index}-edit-player2`}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="player2name"
                                                label="Nimi"
                                                className={commonStyles.bottomMargin}
                                                value={player2Name}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleNameChange(e, 2);
                                                }}
                                            />
                                            <TextField
                                                id="player2club"
                                                label="Seura"
                                                value={player2Club}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleClubChange(e, 2);
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                {props.gameMode === GameMode.trippeli && (
                                    <Grid item xs={12} key={`joukkue-${props.gameMode}-${props.index}-edit-player3`}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="player3name"
                                                label="Nimi"
                                                className={commonStyles.bottomMargin}
                                                value={player3Name}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleNameChange(e, 3);
                                                }}
                                            />
                                            <TextField
                                                id="player3club"
                                                label="Seura"
                                                value={player3Club}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleClubChange(e, 3);
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12} key={`joukkue-${props.gameMode}-${props.index}-edit-ranked`}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch
                                                checked={isRanked}
                                                onChange={handleRankedChange}
                                                inputProps={{ 'aria-label': 'controlled' }} />}
                                            label="Edellisvuoden top 8" />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} key={`manual-pair-save`} className={commonStyles.leftAlign}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<SaveIcon />}
                                        aria-label="save"
                                        onClick={(event) => {
                                            handleClose(event, 'save');
                                        }}
                                    >
                                        Tallenna joukkue
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}

export default EditTeam;