import { useAuth0 } from "@auth0/auth0-react";
import { Box, Fab, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import * as React from "react";
import { fetchData, postBlobData } from "../../../services/blobService";
import LoadingSpinner from "../../components/loadingSpinner";
import { GameMode } from "../../enums/gameMode";
import RoundStatus from "../../enums/roundStatus";
import TournamentStatus from "../../enums/tournamentStatus";
import { ITeams } from "../../interfaces/teamModels";
import { IRound, ITournament } from "../../interfaces/tournamentModels";
import FillTournamentData from "./fillTournamentData";
import Round from "./round";
import SwissScore from "./swissScore";
import commonStyles from "../../styles/common.module.scss";
import Cup from "./cup";
import FinalResult from "./finalResult";
import TournamentFormat from "../../enums/tournamentFormat";
import AllRoundScore from "./allRoundScore";
import { isClubOrganizerOrAdmin } from "../../../services/authorizationService";
import store from "../../../stores/eventStore";
import { observer } from "mobx-react";
import { TOURNAMENT } from "../../consts/tournamentConsts";
import Boule from "./boule";

interface TournamentLandingProps {
  category: string;
  gameMode: GameMode;
  containerName: string;
}

const TournamentLanding: React.FC<TournamentLandingProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();

  const joukkueetBlobName = `${props.category}-${props.gameMode}-joukkueet.json`;
  const tuloksetBlobName = `${props.category}-${props.gameMode}-tulokset.json`;

  const cupNames: string[] = [TOURNAMENT.CHAMPION_CUP_NAME, "B", "C", "D", "E", "F", "G", "H", "I"];

  const [loading, setLoading] = React.useState<boolean>(true);
  const [getDataDone, setGetDataDone] = React.useState<boolean>(false);
  const [teamData, setTeams] = React.useState<ITeams | undefined>(undefined);
  const [tournamentData, setTournament] = React.useState<ITournament | undefined>(undefined);
  const [chosenRound, setRound] = React.useState<string>("");
  const [roundChange, setRoundChange] = React.useState<boolean>(false);

  const handleRoundChange = (event: SelectChangeEvent) => {
    setRoundChange(true);
    setRound(event.target.value as string);
  };

  const updateTournamentProperties = (newData: ITournament) => {
    setTournament(newData);
  };

  const editRound = (round: number, data: IRound) => {
    const newTournament = tournamentData;
    if (newTournament) {
      const roundIndex = newTournament.rounds.findIndex((r) => r.roundNumber === round);
      if (roundIndex !== -1) {
        newTournament.rounds.splice(roundIndex, 1);
      }
      newTournament.rounds.push(data);

      if (round === 1) {
        newTournament.status = data.matches.length > 0 ? TournamentStatus.ongoing : TournamentStatus.notStarted;
      }

      postBlobData(newTournament, tuloksetBlobName, props.containerName).then(() => {
        setTournament(tournamentData);
      });
    }
  };

  const editBoule = (bouleName: string, teamIds: string[]) => {
    const newTournament = tournamentData;
    if (newTournament) {
      const bouleIndex = newTournament.boules?.findIndex((b) => b.name === bouleName);
      if (bouleIndex !== undefined && bouleIndex !== -1 && newTournament.boules) {
        newTournament.boules[bouleIndex].teamIds = teamIds;
      }

      postBlobData(newTournament, tuloksetBlobName, props.containerName).then(() => {
        setTournament(tournamentData);
      });
    }
  };

  const editCup = () => {
    if (tournamentData) {
      postBlobData(tournamentData, tuloksetBlobName, props.containerName);
    }
  };

  const setDropdownDefault = (data: ITournament) => {
    const lastPlayed = Math.max(...data.rounds.filter((r) => r.status === RoundStatus.completed).map((r) => r.roundNumber), 0);
    const LastRoundFinished = lastPlayed === data.roundRules.amountOfRounds;
    if (LastRoundFinished) {
      const ongoingCupRound = Math.min(
        ...data.cupRounds
          .filter((cr) => cr.name === TOURNAMENT.CHAMPION_CUP_NAME && cr.matches.every((m) => m.team1Id && m.team2Id))
          .map((cr) => cr.amountOfTeamsInRound),
        999
      );
      if (
        ongoingCupRound === 2 &&
        data.cupRounds
          .find((cr) => cr.amountOfTeamsInRound === ongoingCupRound)!
          .matches.every((m) => m.team1Points !== undefined && m.team2Points !== undefined)
      ) {
        setRound("finalResult");
      } else {
        setRound(`cup-${TOURNAMENT.CHAMPION_CUP_NAME}`);
      }
    } else {
      setRound((lastPlayed + 1).toString());
    }
  };

  const getCupDefaultRound = (data: ITournament, name: string) => {
    const lastPlayed = Math.max(...data.rounds.filter((r) => r.status === RoundStatus.completed).map((r) => r.roundNumber), 0);
    const LastRoundFinished = lastPlayed === data.roundRules.amountOfRounds;
    if (LastRoundFinished) {
      const ongoingCupRound = Math.min(
        ...data.cupRounds.filter((cr) => cr.name === name && cr.matches.every((m) => m.team1Id && m.team2Id)).map((cr) => cr.amountOfTeamsInRound),
        999
      );
      return ongoingCupRound === 999 ? undefined : ongoingCupRound;
    }

    return undefined;
  };

  React.useEffect(() => {
    if (!getDataDone) {
      setGetDataDone(true);

      fetchData(tuloksetBlobName, props.containerName)
        .then((item) => {
          const tournament = JSON.parse(item as string);
          if (!tournament.roundRules.format) {
            tournament.roundRules.format = TournamentFormat.swiss;
          }
          setTournament(tournament);
          setDropdownDefault(tournament);
          setLoading(false);
        })
        .catch((e: any) => {
          setTournament(undefined);
          if (isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs)) {
            fetchData(joukkueetBlobName, props.containerName)
              .then((item) => {
                const teams = JSON.parse(item as string);
                setTeams(teams);
                setLoading(false);
              })
              .catch((e: any) => {
                setTeams(undefined);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        });
    }
  }, []);

  const refreshTuloksetData = () => {
    fetchData(tuloksetBlobName, props.containerName)
      .then((item) => {
        const tournament = JSON.parse(item as string);
        setTournament(tournament);
        setLoading(false);
      })
      .catch((e: any) => {
        setTournament(undefined);
        if (isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs)) {
          fetchData(joukkueetBlobName, props.containerName)
            .then((item) => {
              const teams = JSON.parse(item as string);
              setTeams(teams);
              setLoading(false);
            })
            .catch((e: any) => {
              setTeams(undefined);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
  };

  const cups = () => {
    return tournamentData?.roundRules?.cups?.map((c) => c.name) ?? [];
  };

  const cupOffset = (cupName: string) => {
    const nameIndex = cupNames.indexOf(cupName);
    let offset = 0;
    for (let i = 0; i < nameIndex; i++) {
      offset += tournamentData?.roundRules?.cups?.find((c) => c.name === cupNames[i])?.maxTeams ?? 0;
    }

    return offset;
  };

  React.useEffect(() => {
    if (roundChange) {
      setRoundChange(false);
    }
  }, [roundChange]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            aria-label="refresh"
            className={`${commonStyles.noPrint}`}
            sx={{
              position: "fixed",
              right: 20,
              bottom: 20,
            }}
            onClick={() => {
              setLoading(true);
              refreshTuloksetData();
            }}
          >
            <RefreshIcon />
            Päivitä tulokset
          </Fab>
          {isAuthenticated && (teamData || tournamentData) && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
            <div style={{ marginBottom: 20 }}>
              <FillTournamentData
                blobName={tuloksetBlobName}
                category={props.category}
                gameMode={props.gameMode}
                teams={teamData ?? tournamentData!.teams}
                onSave={updateTournamentProperties}
                tournamentData={tournamentData}
                containerName={props.containerName}
              />
            </div>
          )}
          {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && !teamData && !tournamentData && <p>Lisää vähintään yksi joukkue!</p>}
          {tournamentData && (
            <Grid container spacing={0.5} justifyContent="flex-start" alignItems="center" className={commonStyles.bottomSpace}>
              <Grid item xs={12} key={`tournament-${props.category}-${props.gameMode}-choose-round`} className={commonStyles.bottomMargin}>
                <Box sx={{ minWidth: 120 }} className={commonStyles.noPrint}>
                  <FormControl fullWidth>
                    <InputLabel id="tournament-choose-round-label">Valitse:</InputLabel>
                    <Select
                      labelId="tournament-choose-round-label"
                      id="tournament-round-amount"
                      value={chosenRound}
                      label="Valitse:"
                      onChange={handleRoundChange}
                    >
                      {tournamentData.roundRules.format === TournamentFormat.circelBoule &&
                        tournamentData.roundRules.bouleAmount !== undefined &&
                        tournamentData.roundRules.bouleAmount > 0 &&
                        [...Array(tournamentData.roundRules.bouleAmount)].map((_b, i) => {
                          return (
                            <MenuItem value={TOURNAMENT.BOULE_NAMES.charAt(i)} key={`boule-select-${i}`}>
                              Poule {TOURNAMENT.BOULE_NAMES.charAt(i)}
                            </MenuItem>
                          );
                        })}
                      {tournamentData.roundRules.amountOfRounds !== undefined &&
                        tournamentData.roundRules.amountOfRounds > 0 &&
                        [...Array(tournamentData.roundRules.amountOfRounds)].map((_e, i) => {
                          return (
                            <MenuItem value={i + 1} key={`round-select-${i + 1}`}>
                              Kierros {i + 1}
                            </MenuItem>
                          );
                        })}
                      {tournamentData.roundRules.format !== TournamentFormat.circelBoule && (
                        <MenuItem value="total" key={`round-select-total`}>
                          Kokonaistilanne (alkusarja)
                        </MenuItem>
                      )}
                      {cups().map((c) => {
                        return (
                          <MenuItem value={`cup-${c}`} key={`round-select-cup-${c}`}>
                            {c === TOURNAMENT.CHAMPION_CUP_NAME ? "A" : c} - Cup
                          </MenuItem>
                        );
                      })}

                      <MenuItem value="finalResult" key={`round-select-final-result`}>
                        Lopputulokset
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              {!roundChange && (
                <>
                  {chosenRound && !isNaN(parseInt(chosenRound)) && (
                    <Round
                      onEdit={editRound}
                      tournamentData={tournamentData}
                      roundNumber={parseInt(chosenRound)}
                      lastFinishedRound={Math.max(...tournamentData.rounds.filter((r) => r.status === RoundStatus.completed).map((r) => r.roundNumber), 0)}
                    />
                  )}
                  {chosenRound && chosenRound === "total" && (
                    <>
                      {tournamentData.roundRules.format === TournamentFormat.swiss && <SwissScore tournamentData={tournamentData} />}
                      {tournamentData.roundRules.format === TournamentFormat.allround && <AllRoundScore tournamentData={tournamentData} />}
                    </>
                  )}
                  {chosenRound && isNaN(parseInt(chosenRound)) && chosenRound.substring(0, 3) === "cup" && (
                    <Grid item xs={12} key={`tournament-${props.category}-${props.gameMode}-chosen-cup`}>
                      <Cup
                        onEdit={editCup}
                        tournamentData={tournamentData}
                        lastRoundFinished={
                          Math.max(...tournamentData.rounds.filter((r) => r.status === RoundStatus.completed).map((r) => r.roundNumber), 0) ===
                          tournamentData.roundRules.amountOfRounds
                        }
                        defaultCupRound={getCupDefaultRound(tournamentData, chosenRound.substring(4))}
                        cupName={chosenRound.substring(4)}
                        offset={cupOffset(chosenRound.substring(4))}
                      />
                    </Grid>
                  )}
                  {chosenRound && chosenRound === "finalResult" && (
                    <Grid item xs={12} key={`tournament-${props.category}-${props.gameMode}-chosen-result`}>
                      <FinalResult
                        tournamentData={tournamentData}
                        isReady={tournamentData.cupRounds
                          .filter((cr) => cr.amountOfTeamsInRound === 2)
                          .every((x) => x.matches.every((m) => (m.team1Points ?? 0) > 0 || (m.team2Points ?? 0) > 0))}
                      />
                    </Grid>
                  )}
                  {chosenRound && TOURNAMENT.BOULE_NAMES.includes(chosenRound) && (
                    <Grid item xs={12} key={`tournament-${props.category}-${props.gameMode}-chosen-result`}>
                      <Boule tournamentData={tournamentData} bouleName={chosenRound} onEdit={editBoule} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
          {!isAuthenticated && !tournamentData && <p>Turnaus ei ole vielä käynnissä</p>}
        </>
      )}
    </>
  );
};

export default observer(TournamentLanding);
