import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormControl } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    name: string;
    paddingValue?: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, name, paddingValue, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${name}-tabpanel-${index}`}
            aria-labelledby={`${name}-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: paddingValue ?? 0, height: '100%' }}>
                    <FormControl fullWidth>
                        <Typography component={'span'} variant={'body1'}>
                            {children}
                        </Typography>
                    </FormControl>
                </Box>
            )}
        </div>
    );
}

export default TabPanel;