import { action, makeObservable, observable } from "mobx";
import { IEvent, IEventType } from "../common/interfaces/IEvent";
import { makeLocalStorage } from "./store";

class EventStore {
  eventId: string = "";
  eventName: string = "";
  types: IEventType[] = [];
  blobContainer: string = "";
  organizerClubs: string[] = [];
  eventDate: Date = new Date();
  goToTeams: boolean = false;

  constructor() {
    makeObservable(this, {
      eventId: observable,
      eventName: observable,
      types: observable,
      blobContainer: observable,
      organizerClubs: observable,
      eventDate: observable,
      goToTeams: observable,
      updateEventId: action,
      clearStore: action,
      updateGoToTeams: action,
    });

    try {
      makeLocalStorage(this, "eventStore", [
        "eventId",
        "eventName",
        "types",
        "blobContainer",
        "organizerClubs",
        "eventDate",
        "goToTeams",
      ]);
    } catch {
      this.clearStore();
      makeLocalStorage(this, "eventStore", [
        "eventId",
        "eventName",
        "types",
        "blobContainer",
        "organizerClubs",
        "eventDate",
        "goToTeams",
      ]);
    }
  }

  updateEventId(newEvent: IEvent) {
    this.eventId = newEvent.eventId;
    this.eventName = newEvent.name;
    this.types = newEvent.types;
    this.blobContainer = newEvent.blobContainer;
    this.organizerClubs = newEvent.organizerClubs;
    this.eventDate = newEvent.startingTime;
  }

  clearStore() {
    this.eventId = "";
    this.eventName = "";
    this.types = [];
    this.blobContainer = "";
    this.organizerClubs = [];
    this.eventDate = new Date();
    this.goToTeams = false;
  }

  updateGoToTeams(value: boolean) {
    this.goToTeams = value;
  }

  getEventId(): string {
    return localStorage.getItem("eventStore_eventId") ?? "";
  }
}

const store = new EventStore();

export default store;
