import * as React from 'react';
import styles from '../styles/common.module.scss'

const LoadingSpinner: React.FC = () => {
    return (
        <div className={styles.spinnerContainer}>
            <div className={styles.loadingSpinner}></div>
            <p>Ladataan...</p>
        </div>
    );
}

export default LoadingSpinner;