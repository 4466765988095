export const GetNextFreeNumber = (value: number, excludedValues: number[]) => {
  let nextValue: number = value + 1;
  while (excludedValues.some((ev) => ev === nextValue)) {
    nextValue++;
  }
  return nextValue;
};

export const ParseExcludedFields = (excludedFieldsString: string | undefined) => {
  return excludedFieldsString
    ? excludedFieldsString.split(",").map((splitted) => {
        const parsed = parseInt(splitted);
        if (!isNaN(parsed)) {
          return parsed;
        }
        return 0;
      })
    : [];
};

export const Makeid = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
