import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import { Box, Button, FormControl, Grid, IconButton, Modal, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { ITeams } from '../../interfaces/teamModels';
import { ICupMatch } from '../../interfaces/tournamentModels';
import commonStyles from '../../styles/common.module.scss'
import { printTeamName } from '../../utils/teamHelper';
import { isClubOrganizerOrAdmin } from '../../../services/authorizationService';
import store from '../../../stores/eventStore';
import { TOURNAMENT } from '../../consts/tournamentConsts';

interface CupMatchProps {
    matchData: ICupMatch;
    teamData: ITeams;    
    onMatchEdit: (matchData: ICupMatch) => void;
}
 
const CupMatch: React.FC<CupMatchProps> = (props) => {
    const { isAuthenticated, user } = useAuth0();

    const team1IsBye = props.matchData.team1Id === TOURNAMENT.BYE_ID
    const team2IsBye = props.matchData.team2Id === TOURNAMENT.BYE_ID
    const team1 = team1IsBye ? undefined : props.teamData.teams.find(t => t.teamId === props.matchData.team1Id);
    const team2 = team2IsBye ? undefined : props.teamData.teams.find(t => t.teamId === props.matchData.team2Id);

    const [field, setField] = React.useState<number | undefined>(props.matchData.fieldNumber);
    const [points1, setPoints1] = React.useState<number | undefined>(props.matchData.team1Points);
    const [points2, setPoints2] = React.useState<number | undefined>(props.matchData.team2Points);
    const [tempField, setTempField] = React.useState<number | undefined>(undefined);
    const [tempPoints1, setTempPoints1] = React.useState<number | undefined>(undefined);
    const [tempPoints2, setTempPoints2] = React.useState<number | undefined>(undefined);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if (isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs)) {
            setTempField(field);
            setTempPoints1(points1);
            setTempPoints2(points2);
            setOpen(true);
        }
    }
    const handleClose = (_event: object, reason: string) => {
        if (reason === 'backdropClick') return;

        if (reason === 'save') {
            setField(tempField);
            setPoints1(tempPoints1);
            setPoints2(tempPoints2);

            const newMatchData = props.matchData;
            newMatchData.fieldNumber = tempField;
            newMatchData.team1Points = tempPoints1;
            newMatchData.team2Points = tempPoints2;
            props.onMatchEdit(newMatchData);
        }

        setOpen(false);
    }

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = parseInt(event.target.value);
        if (!isNaN(value)) {
            setTempField(value);
        } else {
            setTempField(undefined);
        }
    };

    const handlePoints1Change = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (team2) {
            const value = parseInt(event.target.value);
            if (!isNaN(value)) {
                setTempPoints1(value);
            } else {
                setTempPoints1(undefined);
            }
        } else {
            setTempPoints1(13);
        }
    };

    const handlePoints2Change = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (team2) {
            const value = parseInt(event.target.value);
            if (!isNaN(value)) {
                setTempPoints2(value);
            } else {
                setTempPoints2(undefined);
            }
        } else {
            setTempPoints2(0);
        }
    };
    return (
        <>
            <Grid item xs={1} key={`cup-match-${props.matchData.matchId}-field`}>
                <p className={`${commonStyles.leftAlign} ${commonStyles.noEmptyTopBottom}`}><b>{field}</b></p>
            </Grid>
            <Grid item xs={isAuthenticated ? 3.75 : 4.25} key={`tournament-match-${props.matchData.matchId}-team1`}>
                <p className={`${commonStyles.leftAlign} ${commonStyles.noEmptyTopBottom}`}>{team1IsBye ? TOURNAMENT.BYE_NAME : team1 ? `${printTeamName(team1)} [${props.matchData.team1OriginalPosition}]` : ''}</p>
            </Grid>
            <Grid item xs={1} key={`tournament-match-${props.matchData.matchId}-points1`}>
                <p className={`${commonStyles.centered} ${commonStyles.noEmptyTopBottom}`}><b>{points1}</b></p>

            </Grid>
            <Grid item xs={0.5} key={`tournament-match-${props.matchData.matchId}-separator`}>
                <p className={`${commonStyles.centered} ${commonStyles.noEmptyTopBottom}`}>-</p>
            </Grid>
            <Grid item xs={1} key={`tournament-match-${props.matchData.matchId}-points2`}>
                <p className={`${commonStyles.centered} ${commonStyles.noEmptyTopBottom}`}><b>{points2}</b></p>

            </Grid>
            <Grid item xs={isAuthenticated ? 3.75 : 4.25} key={`tournament-match-${props.matchData.matchId}-team2`}>
                <p className={`${commonStyles.rightAlign} ${commonStyles.noEmptyTopBottom}`}>{team2IsBye ? TOURNAMENT.BYE_NAME : team2 ? `${printTeamName(team2)} [${props.matchData.team2OriginalPosition}]` : ''}</p>
            </Grid>
            {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
                <Grid item xs={1} key={`tournament-match-${props.matchData.matchId}-edit`} alignItems="center" className={`${commonStyles.rightAlign} ${commonStyles.noPrint}`}>
                    <IconButton aria-label="edit"
                        className={commonStyles.noEmptyTopBottom}
                        size="small"
                        color="primary"
                        onClick={handleOpen}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-divider`}>
                <hr />
            </Grid>
            <Modal
                key={`tournament-match-${props.matchData.matchId}-modal`}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
            >
                <Box className={commonStyles.modalStyle}>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                    >
                        <Grid container spacing={2} key={`tournament-match-${props.matchData.matchId}-edit`} justifyContent='flex-start'>
                            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-edit-cancel`} className={commonStyles.rightAlign}>
                                <IconButton aria-label="edit"
                                    className={commonStyles.noEmptyTopBottom}
                                    size="medium"
                                    color="error"
                                    onClick={(event) => {
                                        handleClose(event, 'cancel');
                                    }}>
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-field-edit`}>
                                <FormControl fullWidth>
                                    <TextField
                                        id={`tournament-match-${props.matchData.matchId}-field-edit`}
                                        label="Kenttä"
                                        type="number"
                                        value={tempField ?? ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFieldChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-team1-label-edit`}>
                                <FormControl fullWidth>
                                    <p className={`${commonStyles.leftAlign} ${commonStyles.noEmptyTopBottom}`}>{team1IsBye ? TOURNAMENT.BYE_NAME : team1 ? printTeamName(team1) : ''}</p>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-team1-points-edit`}>
                                <FormControl fullWidth>
                                    <TextField
                                        id={`tournament-match-${props.matchData.matchId}-points1-edit`}
                                        label="Pisteet"
                                        type="number"
                                        value={tempPoints1 ?? ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handlePoints1Change}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-team2-label-edit`}>
                                <FormControl fullWidth>
                                    <p className={`${commonStyles.leftAlign} ${commonStyles.noEmptyTopBottom}`}>{team2IsBye ? TOURNAMENT.BYE_NAME : team2 ? printTeamName(team2) : ''}</p>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} key={`tournament-match-${props.matchData.matchId}-team2-points-edit`}>
                                <FormControl fullWidth>
                                    <TextField
                                        id={`tournament-match-${props.matchData.matchId}-points2-edit`}
                                        label="Pisteet"
                                        type="number"
                                        value={tempPoints2 ?? ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handlePoints2Change}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} key={`tournament-match-${props.matchData.matchId}-edit-update`}>
                                {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
                                    <Button
                                        variant="outlined"
                                        startIcon={<SaveIcon />}
                                        aria-label="save"
                                        onClick={(event) => {
                                            handleClose(event, 'save');
                                        }}
                                    >
                                        Tallenna
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </>
      );
}
 
export default CupMatch;