import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import { IBoule, ITournament } from "../../interfaces/tournamentModels";
import commonStyles from "../../styles/common.module.scss";
import { LoadingButton } from "@mui/lab";
import { printTeamName } from "../../utils/teamHelper";
import { isClubOrganizerOrAdmin } from "../../../services/authorizationService";
import store from "../../../stores/eventStore";
import RoundStatus from "../../enums/roundStatus";
import { GetBouleTeamsToThirdRound } from "../../../services/tournamentService";
import OutboundIcon from "@mui/icons-material/Outbound";
import ForwardIcon from "@mui/icons-material/Forward";

interface BouleProps {
  tournamentData: ITournament;
  bouleName: string;
  onEdit: (bouleName: string, teamIds: string[]) => void;
}

const Boule: React.FC<BouleProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();
  const [updating, setUpdating] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [team1, setTeam1] = React.useState<string>(
    props.tournamentData.boules?.find((b) => b.name === props.bouleName)
      ?.teamIds[0] ?? ""
  );
  const [team2, setTeam2] = React.useState<string>(
    props.tournamentData.boules?.find((b) => b.name === props.bouleName)
      ?.teamIds[1] ?? ""
  );
  const [team3, setTeam3] = React.useState<string>(
    props.tournamentData.boules?.find((b) => b.name === props.bouleName)
      ?.teamIds[2] ?? ""
  );
  const [team4, setTeam4] = React.useState<string>(
    props.tournamentData.boules?.find((b) => b.name === props.bouleName)
      ?.teamIds[3] ?? ""
  );

  const handleBouleSave = () => {
    const teamIds = [team1, team2, team3, team4];
    let validationError: boolean = false;
    for (let i = 0; i < teamIds.length; i++) {
      if (i === teamIds.length - 1) break;
      if (teamIds.filter((t) => t === teamIds[i]).length > 1) {
        validationError = true;
        break;
      }
    }

    if (validationError) {
      setShowError(true);
    } else {
      props.onEdit(props.bouleName, [team1, team2, team3, team4]);
    }
    setUpdating(false);
  };

  const handleTeamChange = (event: SelectChangeEvent, teamNumber: number) => {
    if (teamNumber === 1) setTeam1(event.target.value as string);
    if (teamNumber === 2) setTeam2(event.target.value as string);
    if (teamNumber === 3) setTeam3(event.target.value as string);
    if (teamNumber === 4) setTeam4(event.target.value as string);
    setHasChanges(true);
    setShowError(false);
  };

  const getBoule = () => {
    return props.tournamentData.boules!.filter(
      (b) => b.name === props.bouleName
    )[0];
  };

  const getTeam = (index: number) => {
    const boule = getBoule();
    if (boule.teamIds.length > index) {
      return boule.teamIds[index];
    }
    return undefined;
  };

  const getPossibleTeams = () => {
    const allTeamIds = props.tournamentData.teams.teams.map((t) => t.teamId);
    return allTeamIds
      .filter((tid) =>
        props.tournamentData.boules?.every((b) =>
          b.teamIds.every((btid) => btid !== tid)
        )
      )
      .map((id) => {
        return {
          teamId: id,
          text: printTeamName(
            props.tournamentData.teams.teams.find((t) => t.teamId === id)!
          ),
        };
      });
  };

  const addChosenTeamToOptions = (index: number) => {
    switch (index) {
      case 0:
        if (
          team1 &&
          getPossibleTeams().every((team) => team.teamId !== team1)
        ) {
          return {
            teamId: team1,
            text: printTeamName(
              props.tournamentData.teams.teams.find((t) => t.teamId === team1)!
            ),
          };
        }
        break;
      case 1:
        if (
          team2 &&
          getPossibleTeams().every((team) => team.teamId !== team2)
        ) {
          return {
            teamId: team2,
            text: printTeamName(
              props.tournamentData.teams.teams.find((t) => t.teamId === team2)!
            ),
          };
        }
        break;
      case 2:
        if (
          team3 &&
          getPossibleTeams().every((team) => team.teamId !== team3)
        ) {
          return {
            teamId: team3,
            text: printTeamName(
              props.tournamentData.teams.teams.find((t) => t.teamId === team3)!
            ),
          };
        }
        break;
      case 3:
        if (
          team4 &&
          getPossibleTeams().every((team) => team.teamId !== team4)
        ) {
          return {
            teamId: team4,
            text: printTeamName(
              props.tournamentData.teams.teams.find((t) => t.teamId === team4)!
            ),
          };
        }
        break;
      default:
        break;
    }
    return undefined;
  };

  const getTeamPoints = (index: number, roundNumber: number) => {
    const teamId = getTeam(index);
    const matchData = props.tournamentData.rounds
      .find((r) => r.roundNumber === roundNumber)
      ?.matches.find((m) => m.team1Id === teamId || m.team2Id === teamId);

    if (!teamId || !matchData) return "";

    return matchData.team1Id === teamId
      ? matchData.team1Points?.toString() ?? ""
      : matchData.team2Points?.toString() ?? "";
  };

  const getOpponentIndex = (index: number, roundNumber: number) => {
    const teamId = getTeam(index);
    const matchData = props.tournamentData.rounds
      .find((r) => r.roundNumber === roundNumber)
      ?.matches.find((m) => m.team1Id === teamId || m.team2Id === teamId);
    const opponentId =
      matchData?.team1Id === teamId ? matchData?.team2Id : matchData?.team1Id;

    if (opponentId) {
      return props.tournamentData
        .boules!.find((b) => b.name === props.bouleName)!
        .teamIds.findIndex((t) => t === opponentId);
    }

    return -1;
  };

  const getBouleStatus = () => {
    const boule = getBoule();
    const firstRoundMatches = props.tournamentData.rounds
      .find((r) => r.roundNumber === 1 && r.status === RoundStatus.completed)
      ?.matches.filter(
        (m) =>
          boule.teamIds.some((t) => t === m.team1Id) ||
          boule.teamIds.some((t) => t === m.team2Id)
      );
    const secondRoundMatches = props.tournamentData.rounds
      .find((r) => r.roundNumber === 2 && r.status === RoundStatus.completed)
      ?.matches.filter(
        (m) =>
          boule.teamIds.some((t) => t === m.team1Id) ||
          boule.teamIds.some((t) => t === m.team2Id)
      );
    if (firstRoundMatches && secondRoundMatches) {
      return GetBouleTeamsToThirdRound(firstRoundMatches, secondRoundMatches);
    }
    return undefined;
  };

  const renderTeam = (index: number) => {
    return (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        className={
          (index + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""
        }
      >
        <Grid
          item
          xs={6}
          className={`${commonStyles.leftAlign} ${
            (index + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""
          }`}
          sx={{
            alignSelf: "center",
          }}
        >
          {isAuthenticated &&
          isClubOrganizerOrAdmin(user, store.organizerClubs) ? (
            <Box sx={{ minWidth: 120, padding: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="team1-label">Valitse:</InputLabel>
                <Select
                  labelId="team1-label"
                  id="team1"
                  value={
                    index === 0
                      ? team1
                      : index === 1
                      ? team2
                      : index === 2
                      ? team3
                      : team4
                  }
                  label="Valitse:"
                  onChange={(event) => {
                    handleTeamChange(event, index + 1);
                  }}
                >
                  {[
                    ...getPossibleTeams(),
                    addChosenTeamToOptions(index),
                    { teamId: "", text: "Tyhjennä" },
                  ]
                    .filter((t) => t !== undefined)
                    .map((team) => {
                      return (
                        <MenuItem
                          value={team!.teamId}
                          key={`team${index + 1}-select-${team!.teamId}`}
                        >
                          {team!.text}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <p
              className={`${commonStyles.noEmptyTopBottom} ${commonStyles.leftMargin}`}
            >
              {getTeam(index)
                ? printTeamName(
                    props.tournamentData.teams.teams.find(
                      (t) => t.teamId === getTeam(index)
                    )!
                  )
                : "Ei valittu vielä"}
            </p>
          )}
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            alignSelf: "center",
          }}
          className={`${commonStyles.centered} ${
            (index + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""
          } ${
            (index + 1) % 2 === 1
              ? getTeam(index) && getTeamPoints(index, 1)
                ? commonStyles.highlight
                : ""
              : ""
          }`}
        >
          <p className={commonStyles.noEmptyTopBottom}>
            {getTeamPoints(index, 1)}
          </p>
        </Grid>
        <Grid
          item
          xs={1}
          className={`${commonStyles.rightAlign} ${
            (index + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""
          }`}
        >
          <p className={commonStyles.noEmptyTopBottom}> </p>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            alignSelf: "center",
          }}
          className={`${commonStyles.centered} ${
            (index + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""
          } ${
            index === 0 || getOpponentIndex(0, 2) === index
              ? getTeam(index) && getTeamPoints(index, 2)
                ? commonStyles.highlight
                : ""
              : ""
          }`}
        >
          <p className={commonStyles.noEmptyTopBottom}>
            {getTeamPoints(index, 2)}
          </p>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            alignSelf: "center",
          }}
          className={`${commonStyles.centered} ${
            (index + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""
          }`}
        >
          <p className={commonStyles.noEmptyTopBottom}>
            {getTeam(index) === getBouleStatus()?.doubleWin &&
              getBouleStatus() !== undefined && (
                <OutboundIcon sx={{ paddingTop: "7px", color: "green" }} />
              )}
            {getTeam(index) === getBouleStatus()?.doubleLoss &&
              getBouleStatus() !== undefined && (
                <OutboundIcon
                  sx={{
                    paddingLeft: "7px",
                    color: "red",
                    transform: "rotate(90deg)",
                  }}
                />
              )}
            {getTeam(index) === getBouleStatus()?.teamToStay &&
              getBouleStatus() !== undefined && (
                <ForwardIcon
                  sx={{
                    paddingBottom: "7px",
                    transform: "rotate(180deg)",
                  }}
                />
              )}
            {getTeam(index) === getBouleStatus()?.teamToPairNextBoule &&
              getBouleStatus() !== undefined && (
                <ForwardIcon
                  sx={{
                    paddingTop: "7px",
                  }}
                />
              )}
          </p>
        </Grid>
        <Grid item xs={12}>
          <hr className={`${commonStyles.noEmptyTopBottom}`} />
        </Grid>
      </Grid>
    );
  };

  React.useEffect(() => {
    setTeam1(
      props.tournamentData.boules?.find((b) => b.name === props.bouleName)
        ?.teamIds[0] ?? ""
    );
    setTeam2(
      props.tournamentData.boules?.find((b) => b.name === props.bouleName)
        ?.teamIds[1] ?? ""
    );
    setTeam3(
      props.tournamentData.boules?.find((b) => b.name === props.bouleName)
        ?.teamIds[2] ?? ""
    );
    setTeam4(
      props.tournamentData.boules?.find((b) => b.name === props.bouleName)
        ?.teamIds[3] ?? ""
    );
    setShowError(false);
    setHasChanges(false);
    setUpdating(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bouleName]);

  return (
    <>
      {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
        <>
          {showError && (
            <Grid item xs={12} sx={{ color: "red" }}>
              <p>Useampi sama joukkue lisättynä!</p>
            </Grid>
          )}
          <LoadingButton
            disabled={!hasChanges}
            loading={updating}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={() => {
              setUpdating(true);
              setHasChanges(false);
              handleBouleSave();
            }}
          >
            Tallenna Poule
          </LoadingButton>
        </>
      )}
      <Grid item xs={12}>
        <p>POULE {props.bouleName}</p>
      </Grid>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid item xs={6} className={commonStyles.leftAlign}>
          <p className={commonStyles.noEmptyTopBottom}>
            <b>Joukkue</b>
          </p>
        </Grid>
        <Grid item xs={1} className={commonStyles.centered}>
          <p className={commonStyles.noEmptyTopBottom}>
            <b>K1</b>
          </p>
        </Grid>
        <Grid item xs={1} className={commonStyles.rightAlign}>
          <p className={commonStyles.noEmptyTopBottom}> </p>
        </Grid>
        <Grid item xs={1} className={commonStyles.centered}>
          <p className={commonStyles.noEmptyTopBottom}>
            <b>K2</b>
          </p>
        </Grid>
        <Grid item xs={3} className={commonStyles.leftAlign}>
          <p className={commonStyles.noEmptyTopBottom}> </p>
        </Grid>
        <Grid item xs={12}>
          <hr className={commonStyles.noEmptyTopBottom} />
        </Grid>
      </Grid>
      {renderTeam(0)}
      {renderTeam(1)}
      {renderTeam(2)}
      {renderTeam(3)}
    </>
  );
};

export default Boule;
