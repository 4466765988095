import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./tabPanel";
import Tournament from "./tournament";
import { Category } from "../enums/category";
import { GameMode } from "../enums/gameMode";
import store from "../../stores/eventStore";

interface TournamentModesProps {
  category: Category;
  containerName: string;
}

const TournamentModes: React.FC<TournamentModesProps> = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `${props.category}-tab-${index}`,
      "aria-controls": `${props.category}-tabpanel-${index}`,
    };
  }

  const decideDuppeliIndex = () => {
    if (
      store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.singeli) === true
    ) {
      return 1;
    }

    return 0;
  };

  const decideTrippeliIndex = () => {
    const hasSingeli =
      store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.singeli) === true;
    const hasDuppeli =
      store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.dubbeli) === true;

    if (hasSingeli && hasDuppeli) {
      return 2;
    } else if (hasSingeli || hasDuppeli) {
      return 1;
    }

    return 0;
  };

  const decideSekaDuppeliIndex = () => {
    const hasSingeli =
      store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.singeli) === true;
    const hasDuppeli =
      store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.dubbeli) === true;
    const hasTrippeli =
      store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.trippeli) === true;

    let howMany = 0;
    howMany += hasSingeli ? 1 : 0;
    howMany += hasDuppeli ? 1 : 0;
    howMany += hasTrippeli ? 1 : 0;

    return howMany;
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          variant="fullWidth"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {store.types
            .find((t) => t.category === props.category)
            ?.gameModes.some((gm) => gm === GameMode.singeli) === true && (
            <Tab label="Singeli" {...a11yProps(0)} />
          )}
          {store.types
            .find((t) => t.category === props.category)
            ?.gameModes.some((gm) => gm === GameMode.dubbeli) === true && (
            <Tab label="Duppeli" {...a11yProps(1)} />
          )}
          {store.types
            .find((t) => t.category === props.category)
            ?.gameModes.some((gm) => gm === GameMode.trippeli) === true && (
            <Tab label="Trippeli" {...a11yProps(2)} />
          )}
          {store.types
            .find((t) => t.category === props.category)
            ?.gameModes.some((gm) => gm === GameMode.sekaDubbeli) === true && (
            <Tab label="Sekaduppeli" {...a11yProps(3)} />
          )}
        </Tabs>
      </Box>
      {store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.singeli) === true && (
        <TabPanel value={value} index={0} name={`${props.category}-singeli`}>
          <Tournament
            gameMode={GameMode.singeli}
            category={props.category}
            containerName={props.containerName}
          />
        </TabPanel>
      )}
      {store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.dubbeli) === true && (
        <TabPanel
          value={value}
          index={decideDuppeliIndex()}
          name={`${props.category}-dubbeli`}
        >
          <Tournament
            gameMode={GameMode.dubbeli}
            category={props.category}
            containerName={props.containerName}
          />
        </TabPanel>
      )}
      {store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.trippeli) === true && (
        <TabPanel
          value={value}
          index={decideTrippeliIndex()}
          name={`${props.category}-trippeli`}
        >
          <Tournament
            gameMode={GameMode.trippeli}
            category={props.category}
            containerName={props.containerName}
          />
        </TabPanel>
      )}
      {store.types
        .find((t) => t.category === props.category)
        ?.gameModes.some((gm) => gm === GameMode.sekaDubbeli) === true && (
        <TabPanel
          value={value}
          index={decideSekaDuppeliIndex()}
          name={`${props.category}-seka-dubbeli`}
        >
          <Tournament
            gameMode={GameMode.sekaDubbeli}
            category={props.category}
            containerName={props.containerName}
          />
        </TabPanel>
      )}
    </>
  );
};

export default TournamentModes;
