import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import { TOURNAMENT } from "../../consts/tournamentConsts";
import { ICup, ITournament } from "../../interfaces/tournamentModels";
import commonStyles from "../../styles/common.module.scss";
import CupRound from "./cupRound";
import { getAllRoundScore, getCircelBouleCupTeams, getSwissScore } from "../../../services/tournamentService";
import { isClubOrganizerOrAdmin } from "../../../services/authorizationService";
import store from "../../../stores/eventStore";
import TournamentFormat from "../../enums/tournamentFormat";

interface CupProps {
  tournamentData: ITournament;
  lastRoundFinished: boolean;
  onEdit: () => void;
  defaultCupRound: number | undefined;
  cupName: string;
  offset: number;
}

const Cup: React.FC<CupProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();

  const [chosenRound, setRound] = React.useState<string>("");
  const [updating, setUpdating] = React.useState(false);
  const [cupStarted, setCupStarted] = React.useState(false);

  const handleRoundChange = (event: SelectChangeEvent) => {
    setRound(event.target.value as string);
  };

  const getPossibleRounds = (): number[] => {
    const possibleRounds: number[] = [];
    const maxTeams = props.tournamentData.roundRules.cups?.find((c) => c.name === props.cupName)?.amountOfPlayers ?? 0;

    for (let i = maxTeams; i >= 2; i = i / 2) {
      possibleRounds.push(i);
    }
    return possibleRounds;
  };

  const startCup = () => {
    const lastRound = Math.max(...props.tournamentData.rounds.map((r) => r.roundNumber), 0);
    const score =
      props.tournamentData.roundRules.format === TournamentFormat.circelBoule
        ? getCircelBouleCupTeams(props.tournamentData)
        : props.tournamentData.roundRules.format === TournamentFormat.allround 
        ? getAllRoundScore(props.tournamentData)
        : getSwissScore(lastRound, props.tournamentData);
    const cup: ICup | undefined = props.tournamentData.roundRules.cups?.find((c) => c.name === props.cupName);
    props.tournamentData.cupRounds
      .filter((cr) => cr.name === props.cupName && cr.amountOfTeamsInRound === cup?.amountOfPlayers)[0]
      .matches.forEach((x) => {
        if (x.team1OriginalPosition) {
          if (x.team1OriginalPosition > cup!.maxTeams + props.offset) {
            x.team1Id = TOURNAMENT.BYE_ID;
          } else {
            x.team1Id = score[x.team1OriginalPosition! - 1].teamId;
          }
        }
        if (x.team2OriginalPosition) {
          if (x.team2OriginalPosition > cup!.maxTeams + props.offset) {
            x.team2Id = TOURNAMENT.BYE_ID;
          } else {
            x.team2Id = score[x.team2OriginalPosition! - 1].teamId;
          }
        }
      });
    props.onEdit();
    setUpdating(false);
    setCupStarted(true);
  };

  const disableStart = (): boolean => {
    if (!props.lastRoundFinished) {
      return true;
    }

    if (props.tournamentData.roundRules.format === TournamentFormat.circelBoule) {
      return false;
    }

    const cupSize = props.tournamentData.roundRules.cups?.find((c) => c.name === props.cupName)?.amountOfPlayers;

    if (cupSize) {
      const hasMatchAssigned = props.tournamentData.cupRounds.find((cr) => cr.name === props.cupName && cr.amountOfTeamsInRound === cupSize)?.matches[0]
        .team1Id;

      return hasMatchAssigned === "" ? false : true;
    }

    return true;
  };

  React.useEffect(() => {
    if (props.defaultCupRound) {
      setRound(props.defaultCupRound.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setRound("");
  }, [props.cupName]);

  React.useEffect(() => {
    if (!chosenRound && props.defaultCupRound) {
      setRound(props.defaultCupRound.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenRound]);

  return (
    <>
      {!props.lastRoundFinished && (
        <>
          <p>{props.cupName === TOURNAMENT.CHAMPION_CUP_NAME ? "A" : props.cupName} - CUP</p>
          <p>Viimeistä kierrosta alkueristä ei ole vielä pelattu!</p>
        </>
      )}
      {props.lastRoundFinished && (
        <>
          {isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs) && (
            <>
              <Grid item xs={6} key={`cup-start`} className={`${commonStyles.leftAlign} ${commonStyles.bottomMargin} ${commonStyles.noPrint}`}>
                <LoadingButton
                  disabled={disableStart() || cupStarted}
                  loading={updating}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  onClick={() => {
                    setUpdating(true);
                    startCup();
                  }}
                >
                  Aloita Cup
                </LoadingButton>
              </Grid>
            </>
          )}
          <Grid item xs={12} key={`cup-choose-round`} className={commonStyles.bottomMargin}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="cup-choose-round-label">Valitse CUP kierros:</InputLabel>
                <Select labelId="cup-choose-round-label" id="cup-round" value={chosenRound} label="Valitse CUP kierros:" onChange={handleRoundChange}>
                  {getPossibleRounds().map((val, i) => {
                    return (
                      <MenuItem value={val} key={`round-select-${i}`}>
                        TOP {val}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </>
      )}
      {chosenRound && (
        <CupRound
          amountOfTeamsLeft={parseInt(chosenRound)}
          cupRound={props.tournamentData.cupRounds.find((cr) => cr.name === props.cupName && cr.amountOfTeamsInRound === parseInt(chosenRound))}
          onEdit={props.onEdit}
          tournamentData={props.tournamentData}
          cupName={props.cupName}
          offset={props.offset}
        />
      )}
    </>
  );
};

export default Cup;
