import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AddEventPage from '../../pages/addEventPage';
import LandingPage from '../../pages/landingPage';
import Privacy from '../../pages/privacy';
import TournamentListPage from '../../pages/tournamentListPage';
import { ROUTES } from '../consts/routes';
import AppMenu from './appMenu';
import styles from '../styles/common.module.scss'

interface LayoutProps {
    route: string
}

const Layout: React.FC<LayoutProps> = (props) => {
    const navigate = useNavigate();

    const renderRoute = () => {
        if (props.route === ROUTES.HOME) return <TournamentListPage />;
        if (props.route === ROUTES.TOURNAMENT) return <LandingPage />;
        if (props.route === ROUTES.PRIVACY) return <Privacy />;
        if (props.route === ROUTES.ADDEVENT) return <AddEventPage />;
    }

    return (
        <>
            <AppMenu />
            {renderRoute()}
            <footer style={{
                marginLeft: 20,
                fontSize: 12,                                
            }} className={styles.noPrint}>
                <p>© 2022 - Petankki-online - <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(ROUTES.PRIVACY)}>Tietosuojaseloste</span> - Versio - 24.3.2024</p>                
            </footer>
        </>
    );
}

export default Layout;