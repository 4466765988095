import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "./tabPanel";
import Instructions from "../features/instructions";
import { Category } from "../enums/category";
import { GameMode } from "../enums/gameMode";
import Teams from "../features/teams";
import TournamentLanding from "../features/tournament/tournamentLanding";
import store from "../../stores/eventStore";
// import Penalties from "../features/penalties";

interface TournamentProps {
  gameMode: GameMode;
  category: Category;
  containerName: string;
}

const Tournament: React.FC<TournamentProps> = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(store.goToTeams ? 1 : 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.goToTeams]);

  function a11yProps(index: number) {
    return {
      id: `${props.gameMode}-tab-${index}`,
      "aria-controls": `${props.gameMode}-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          variant="fullWidth"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Tulokset" {...a11yProps(0)} />
          <Tab label="Joukkueet" {...a11yProps(1)} />
          {/* <Tab label="Varoitukset" {...a11yProps(2)} /> */}
          <Tab label="Säännöt" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0} name={"tulokset"} paddingValue={3}>
        <TournamentLanding
          category={props.category}
          gameMode={props.gameMode}
          containerName={props.containerName}
        />
      </TabPanel>
      <TabPanel value={value} index={1} name={"joukkueet"} paddingValue={3}>
        <Teams
          category={props.category}
          gameMode={props.gameMode}
          containerName={props.containerName}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={2} name={'varoitukset'} paddingValue={3}>
                <Penalties category={props.category} gameMode={props.gameMode} />
            </TabPanel> */}
      <TabPanel value={value} index={2} name={"saannot"} paddingValue={3}>
        <Instructions
          category={props.category}
          gameMode={props.gameMode}
          containerName={props.containerName}
        />
      </TabPanel>
    </>
  );
};

export default Tournament;
