import { Grid } from "@mui/material";
import * as React from "react";
import { ITournament } from "../../interfaces/tournamentModels";
import { getSwissScore } from "../../../services/tournamentService";
import RoundStatus from "../../enums/roundStatus";
import { printTeamName } from "../../utils/teamHelper";
import commonStyles from "../../styles/common.module.scss";

interface SwissScoreProps {
  tournamentData: ITournament;
}

const SwissScore: React.FC<SwissScoreProps> = (props) => {
  const [rows, setRows] = React.useState([]);
  const [scoreReady, setScoreReady] = React.useState<boolean>(false);

  const showDivider = (position: number) => {
    const dividers: number[] = [];

    props.tournamentData.roundRules.cups?.forEach((c) => {
      if (dividers.length === 0) {
        dividers.push(c.maxTeams);
      } else {
        dividers.push(c.maxTeams + dividers[dividers.length - 1]);
      }
    });

    return dividers.some((d) => d === position);
  };

  React.useEffect(() => {
    let scoreRows: any = [];

    let scores = getSwissScore(
      Math.max(...props.tournamentData.rounds.filter((r) => r.status === RoundStatus.completed).map((r) => r.roundNumber), 0),
      props.tournamentData
    );

    scores.forEach((s, index) => {
      scoreRows.push({
        id: index + 1,
        team: printTeamName(props.tournamentData.teams.teams.find((t) => t.teamId === s.teamId)!),
        wins: s.wins,
        b1: s.b1,
        b2: s.b2,
        plus: s.pointsDone,
        minus: s.pointsAgainst,
      });
    });
    setRows(scoreRows);
    setScoreReady(true);
  }, []);

  return (
    <>
      {scoreReady && (
        <>
          <Grid item xs={12}>
            <p>
              Kokonaistilanne kierroksen{" "}
              {Math.max(...props.tournamentData.rounds.filter((r) => r.status === RoundStatus.completed).map((r) => r.roundNumber), 0)} jälkeen.
            </p>
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={1} className={commonStyles.leftAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>#</b>
              </p>
            </Grid>
            <Grid item xs={5} className={commonStyles.leftAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>Joukkue</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>V</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>B1</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>B2</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>+</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>-</b>
              </p>
            </Grid>
            <Grid item xs={1} className={commonStyles.rightAlign}>
              <p className={commonStyles.noEmptyTopBottom}>
                <b>+/-</b>
              </p>
            </Grid>
            <Grid item xs={12}>
              <hr className={commonStyles.noEmptyTopBottom} />
            </Grid>
          </Grid>

          {rows.map((r: any, i) => {
            return (
              <Grid container spacing={0} key={`swissscores-container-${i + 1}`} justifyContent="flex-start">
                <Grid item xs={1} className={`${commonStyles.leftAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{i + 1}</p>
                </Grid>
                <Grid item xs={5} className={`${commonStyles.leftAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.team}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.wins}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.b1}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.b2}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.plus}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.minus}</p>
                </Grid>
                <Grid item xs={1} className={`${commonStyles.rightAlign} ${(i + 1) % 2 === 1 ? commonStyles.alterBackgroundColor : ""}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{r.plus - r.minus}</p>
                </Grid>
                <Grid item xs={12}>
                  <hr className={`${commonStyles.noEmptyTopBottom} ${showDivider(i+1) ? commonStyles.thickDivider : ""}`} />
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

export default SwissScore;
