import { User } from "@auth0/auth0-react";

export const ROLES = {
  admin: "admin",
  judge: "judge",
  organizer: "organizer",
};

const getRoles = (user: User | undefined) => {
  // console.log(user);
  if (user) {
    return user["petanque/roles"];
  }
  return [];
};

export const getClubs = (user: User | undefined) => {
  if (user) {
    return user["petanque/club"];
  }
  return [];
};

export const hasRole = (user: User | undefined, role?: string): boolean => {
  if (user) {
    return (getRoles(user) as []).some((r) => r === role || r === ROLES.admin);
  }

  return false;
};

export const isFromClub = (user: User | undefined, club: string[]): boolean => {
  if (user) {
    return hasRole(user) || (getClubs(user) as []).some((r) => club.some((c) => c === r));
  }

  return false;
};

export const isClubOrganizerOrAdmin = (user: User | undefined, club: string[]): boolean => {
  if (user) {
    const isAdmin = hasRole(user);
    const isOrganizer = hasRole(user, ROLES.organizer);
    const correctClub = isFromClub(user, club);

    return isAdmin || (isOrganizer && correctClub);
  }

  return false;
};
