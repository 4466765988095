import { Grid } from "@mui/material";
import * as React from "react";
import { getCircelBouleCupTeams, getSwissScore } from "../../../services/tournamentService";
import TournamentFormat from "../../enums/tournamentFormat";
import { ICup, ICupRound, ITournament } from "../../interfaces/tournamentModels";
import commonStyles from "../../styles/common.module.scss";
import { printTeamName } from "../../utils/teamHelper";
import { TOURNAMENT } from "../../consts/tournamentConsts";

interface FinalResultProps {
  tournamentData: ITournament;
  isReady: boolean;
}

const FinalResult: React.FC<FinalResultProps> = (props) => {
  const [cupPlayers, setCupPlayers] = React.useState<ICup[]>(props.tournamentData.roundRules.cups ?? []);

  const getTeam = (position: number, round: ICupRound, offset: number): string => {
    if (position === 1 || position === 3) {
      const match = round.matches[position === 1 ? 0 : 1];
      return printTeamName(
        (match.team1Points ?? 0) > (match.team2Points ?? 0)
          ? props.tournamentData.teams.teams.find((t) => t.teamId === match.team1Id)!
          : props.tournamentData.teams.teams.find((t) => t.teamId === match.team2Id)!
      );
    } else if (position === 2 || position === 4) {
      const match = round.matches[position === 2 ? 0 : 1];
      return printTeamName(
        (match.team1Points ?? 0) < (match.team2Points ?? 0)
          ? props.tournamentData.teams.teams.find((t) => t.teamId === match.team1Id)!
          : props.tournamentData.teams.teams.find((t) => t.teamId === match.team2Id)!
      );
    } else if (props.tournamentData.playAllCupMatches === true) {
      const match = round.matches.find((m) => m.winnerPosition === position + offset || m.loserPosition === position + offset)!;
      if (match.winnerPosition === position + offset) {
        return printTeamName(
          (match.team1Points ?? 0) > (match.team2Points ?? 0)
            ? props.tournamentData.teams.teams.find((t) => t.teamId === match.team1Id)!
            : props.tournamentData.teams.teams.find((t) => t.teamId === match.team2Id)!
        );
      } else {
        return printTeamName(
          (match.team1Points ?? 0) < (match.team2Points ?? 0)
            ? props.tournamentData.teams.teams.find((t) => t.teamId === match.team1Id)!
            : props.tournamentData.teams.teams.find((t) => t.teamId === match.team2Id)!
        );
      }
    } else {
      const match = round.matches[position - round.amountOfTeamsInRound / 2 - 1];
      return printTeamName(
        (match.team1Points ?? 0) < (match.team2Points ?? 0)
          ? props.tournamentData.teams.teams.find((t) => t.teamId === match.team1Id)!
          : props.tournamentData.teams.teams.find((t) => t.teamId === match.team2Id)!
      );
    }

    return "";
  };

  const printPosition = (position: number, round: ICupRound, maxTeams: number): string => {
    if (position <= 4 || props.tournamentData.playAllCupMatches === true) return position.toString();

    return `${round.amountOfTeamsInRound / 2 + 1} - ${Math.min(round.amountOfTeamsInRound, maxTeams)}`;
  };

  const renderStandings = () => {
    const elements: any[] = [];

    let offset = 0;

    cupPlayers.forEach((cp) => {
      elements.push(
        <Grid
          container
          spacing={0.5}
          justifyContent="flex-start"
          alignItems="flex-start"
          key={`result-container-${cp.name}`}
          className={commonStyles.noEmptyTopBottom}
        >
          <Grid item xs={2} key={`result-${cp.name}`} style={{ fontWeight: "bold" }}>
            <p className={commonStyles.noEmptyTopBottom}>{cp.name === TOURNAMENT.CHAMPION_CUP_NAME ? "A" : cp.name} - CUP</p>
          </Grid>
          <Grid item xs={10} key={`result-${cp.name}-title`}>
            <p className={commonStyles.noEmptyTopBottom}></p>
          </Grid>
          <Grid item xs={12} key={`result-${cp.name}-divider`}>
            <hr key={`result-${cp.name}-divider-element`} />
          </Grid>
        </Grid>
      );

      let cupRound = 2;
      let position = 1;

      while (position <= cp.amountOfPlayers) {
        if (props.tournamentData.playAllCupMatches === true) {
          cupRound = 2;
        } else {
          if (position > 4 && position <= 8) {
            cupRound = 8;
          } else if (position > 8 && position > cupRound) {
            cupRound = cupRound * 2;
          }
        }

        const round = props.tournamentData.cupRounds.find((cr) => cr.amountOfTeamsInRound === cupRound && cr.name === cp.name);

        if (round) {
          let teamName = getTeam(position, round, offset);
          if (teamName !== "") {
            elements.push(
              <Grid
                container
                spacing={0.5}
                justifyContent="flex-start"
                alignItems="flex-start"
                key={`result-container-${position}-${cp.name}`}
                className={commonStyles.noEmptyTopBottom}
              >
                <Grid item xs={2} key={`result-${position}`}>
                  <p className={commonStyles.noEmptyTopBottom}>{printPosition(position, round, cp.maxTeams)}</p>
                </Grid>
                <Grid item xs={10} key={`result-${position}-team`}>
                  <p className={commonStyles.noEmptyTopBottom}>{teamName}</p>
                </Grid>
                <Grid item xs={12} key={`result-${position}-divider`}>
                  <hr key={`result-${position}-divider-element`} />
                </Grid>
              </Grid>
            );
          } else {
            offset--;
          }
        }
        position++;
      }
      offset += position - 1;
    });

    let scores =
      props.tournamentData.roundRules.format === TournamentFormat.circelBoule
        ? getCircelBouleCupTeams(props.tournamentData)
        : getSwissScore(props.tournamentData.roundRules.amountOfRounds, props.tournamentData);

    const teamsAmount = props.tournamentData.teams.teams.length;

    if (offset < teamsAmount) {
      elements.push(
        <Grid
          container
          spacing={0.5}
          justifyContent="flex-start"
          alignItems="flex-start"
          key={`result-container-rest`}
          className={commonStyles.noEmptyTopBottom}
        >
          <Grid item xs={2} key={`result-rest`} style={{ fontWeight: "bold" }}>
            <p className={commonStyles.noEmptyTopBottom}>Alkusarjaan jääneet</p>
          </Grid>
          <Grid item xs={10} key={`result-rest-title`}>
            <p className={commonStyles.noEmptyTopBottom}></p>
          </Grid>
          <Grid item xs={12} key={`result-rest-divider`}>
            <hr key={`result-rest-divider-element`} />
          </Grid>
        </Grid>
      );
    }

    while (offset < teamsAmount) {
      offset++;
      elements.push(
        <Grid container spacing={0.5} justifyContent="flex-start" alignItems="flex-start" key={`result-container-${offset}`}>
          <Grid item xs={2} key={`result-${offset}`}>
            <p className={commonStyles.noEmptyTopBottom}>{offset}</p>
          </Grid>
          <Grid item xs={10} key={`result-${offset}-team`}>
            <p className={commonStyles.noEmptyTopBottom}>
              {printTeamName(props.tournamentData.teams.teams.find((t) => t.teamId === scores[offset - 1].teamId)!)}
            </p>
          </Grid>
          <Grid item xs={12} key={`result-${offset}-divider`}>
            <hr key={`result-${offset}-divider-element`} />
          </Grid>
        </Grid>
      );
    }

    return elements;
  };

  return (
    <>
      {!props.isReady && <p>Turnaus vielä kesken.</p>}
      {props.isReady && (
        <>
          <p className={commonStyles.printOnly}>LOPPUTULOKSET</p>
          {renderStandings()}
        </>
      )}
    </>
  );
};

export default FinalResult;
