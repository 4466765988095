import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../common/components/tabPanel";
import TournamentModes from "../common/components/tournamentModes";
import { Category } from "../common/enums/category";
import store from "../stores/eventStore";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchData } from "../services/blobService";
import { IEvents } from "../common/interfaces/IEvent";

function a11yProps(index: number) {
  return {
    id: `category-tab-${index}`,
    "aria-controls": `category-tabpanel-${index}`,
  };
}

const LandingPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const [eventId, setEventId] = React.useState<string>(store.eventId);

  const blobName = "events.json";
  const containerName = "events";

  const [searchParams, setSearchParams] = useSearchParams();

  autorun(() => {
    if (eventId !== store.eventId) {
      setEventId(store.eventId);
    }
  });

  React.useEffect(() => {
    let id = searchParams.get("id");
    if (id === null || !id) {
      id = eventId;
    }

    let goToTeams = searchParams.get("showTeams");
    if(goToTeams != null) {
      store.updateGoToTeams(true);
    } else {
      store.updateGoToTeams(false);
    }

    if (!id) {
      navigate("/");
    } else {
      fetchData(blobName, containerName)
        .then((item) => {
          let events = JSON.parse(item as string) as IEvents;
          const event = events.events.find((e) => e.eventId === id);
          if (event) {
            store.updateEventId(event);
          } else {
            navigate("/");
          }
        })
        .catch((e: any) => {
          navigate("/");
        });
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const decideIndex = (cat: Category) => {
    const hasYleinen = store.types.some((t) => t.category === Category.yleinen);
    const hasNaiset = store.types.some((t) => t.category === Category.naiset);
    const has55 = store.types.some((t) => t.category === Category.vet55);
    const has65 = store.types.some((t) => t.category === Category.vet65);

    let howMany = 0;
    switch (cat) {
      case Category.yleinen:
        return 0;
      case Category.naiset:
        return hasYleinen ? 1 : 0;
      case Category.vet55:
        howMany += hasYleinen ? 1 : 0;
        howMany += hasNaiset ? 1 : 0;
        return howMany;
      case Category.vet65:
        howMany += hasYleinen ? 1 : 0;
        howMany += hasNaiset ? 1 : 0;
        howMany += has55 ? 1 : 0;
        return howMany;
      case Category.juniorit:
        howMany += hasYleinen ? 1 : 0;
        howMany += hasNaiset ? 1 : 0;
        howMany += has55 ? 1 : 0;
        howMany += has65 ? 1 : 0;
        return howMany;
      default:
        return -1;
    }
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label=""
          variant={store.types.length > 3 ? "scrollable" : "fullWidth"}
          scrollButtons={true}
          allowScrollButtonsMobile
        >
          {store.types.some((t) => t.category === Category.yleinen) && (
            <Tab label="Yleinen" {...a11yProps(0)} />
          )}
          {store.types.some((t) => t.category === Category.naiset) && (
            <Tab label="Naiset" {...a11yProps(1)} />
          )}
          {store.types.some((t) => t.category === Category.vet55) && (
            <Tab label="+55" {...a11yProps(2)} />
          )}
          {store.types.some((t) => t.category === Category.vet65) && (
            <Tab label="+65" {...a11yProps(3)} />
          )}
          {store.types.some((t) => t.category === Category.juniorit) && (
            <Tab label="Alle 18" {...a11yProps(4)} />
          )}
        </Tabs>
      </Box>
      {store.types.some((t) => t.category === Category.yleinen) && (
        <TabPanel
          value={value}
          index={decideIndex(Category.yleinen)}
          name="yleinen"
        >
          <TournamentModes
            category={Category.yleinen}
            containerName={store.blobContainer}
          />
        </TabPanel>
      )}
      {store.types.some((t) => t.category === Category.naiset) && (
        <TabPanel
          value={value}
          index={decideIndex(Category.naiset)}
          name="naiset"
        >
          <TournamentModes
            category={Category.naiset}
            containerName={store.blobContainer}
          />
        </TabPanel>
      )}
      {store.types.some((t) => t.category === Category.vet55) && (
        <TabPanel value={value} index={decideIndex(Category.vet55)} name="+55">
          <TournamentModes
            category={Category.vet55}
            containerName={store.blobContainer}
          />
        </TabPanel>
      )}
      {store.types.some((t) => t.category === Category.vet65) && (
        <TabPanel value={value} index={decideIndex(Category.vet65)} name="+65">
          <TournamentModes
            category={Category.vet65}
            containerName={store.blobContainer}
          />
        </TabPanel>
      )}
      {store.types.some((t) => t.category === Category.juniorit) && (
        <TabPanel
          value={value}
          index={decideIndex(Category.juniorit)}
          name="Alle 18"
        >
          <TournamentModes
            category={Category.juniorit}
            containerName={store.blobContainer}
          />
        </TabPanel>
      )}
    </>
  );
});

export default LandingPage;
