import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, TextField } from "@mui/material";
import * as React from "react";
import { IMatch, IRound, ITournament } from "../../interfaces/tournamentModels";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { pairBoule, pairRound } from "../../../services/tournamentService";
import RoundStatus from "../../enums/roundStatus";
import Match from "./match";
import commonStyles from "../../styles/common.module.scss";
import MatchStatus from "../../enums/matchStatus";
import { TOURNAMENT } from "../../consts/tournamentConsts";
import YesNoModal from "../../components/yesNoModal";
import ManualPair from "./manualPair";
import FindFieldModal from "../../components/findFieldModal";
import { isClubOrganizerOrAdmin } from "../../../services/authorizationService";
import store from "../../../stores/eventStore";
import TournamentFormat from "../../enums/tournamentFormat";

interface RoundProps {
  tournamentData: ITournament;
  roundNumber: number;
  lastFinishedRound: number;
  onEdit: (round: number, data: IRound) => void;
}

const Round: React.FC<RoundProps> = (props) => {
  const { isAuthenticated, user } = useAuth0();

  const [loading, setLoading] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [roundData, setRound] = React.useState<IRound | undefined>(
    props.tournamentData.rounds.find((r) => r.roundNumber === props.roundNumber)
  );
  const [hasChanges, setHasChanges] = React.useState(false);
  const [canBeCancelled, setCanBeCancelled] = React.useState<boolean>(
    props.tournamentData.rounds.findIndex(
      (r) => r.roundNumber === props.roundNumber + 1
    ) === -1 ||
      props.tournamentData.rounds.find(
        (r) => r.roundNumber === props.roundNumber + 1
      )?.status === RoundStatus.notStarted
  );
  const [showConfirmCancel, setShowConfirmCancel] =
    React.useState<boolean>(false);
  const [showManualPair, setShowManualPair] = React.useState<boolean>(false);
  const [showFindFieldModal, setShowFindFieldModal] =
    React.useState<boolean>(false);
  const [roundTime, setRoundTime] = React.useState<string>("");

  const handleRoundPair = () => {
    setLoading(true);
    const matches =
      props.tournamentData.roundRules.format === TournamentFormat.swiss
        ? pairRound(props.roundNumber, props.tournamentData)
        : pairBoule(props.roundNumber, props.tournamentData);

    const newRoundData: IRound = {
      matches: matches,
      name: "",
      roundNumber: props.roundNumber,
      status: RoundStatus.paired,
      startingTime: roundTime,
    };
    setRound(newRoundData);
    props.onEdit(props.roundNumber, newRoundData);

    setLoading(false);
  };

  const handleManualPair = (matches: IMatch[]) => {
    if (isAuthenticated && isClubOrganizerOrAdmin(user, store.organizerClubs)) {
      const newRoundData: IRound = {
        matches: matches,
        name: "",
        roundNumber: props.roundNumber,
        status: RoundStatus.paired,
        startingTime: roundTime,
      };
      setRound(newRoundData);
      props.onEdit(props.roundNumber, newRoundData);
    }
  };

  const clearRound = () => {
    const newRoundData: IRound = {
      matches: [],
      name: "",
      roundNumber: props.roundNumber,
      status: RoundStatus.notStarted,
    };
    setRound(newRoundData);
    props.onEdit(props.roundNumber, newRoundData);
    setShowConfirmCancel(false);
  };

  const handleMatchEdit = (matchData: IMatch) => {
    const newRoundData = props.tournamentData.rounds.find(
      (r) => r.roundNumber === props.roundNumber
    )!;
    newRoundData.matches.forEach((m) => {
      if (m.matchId === matchData.matchId) {
        m.fieldNumber = matchData.fieldNumber;
        m.team1Points = matchData.team1Points;
        m.team2Points = matchData.team2Points;

        if (m.team1Points !== undefined && m.team2Points !== undefined) {
          if (m.team1Points > m.team2Points) {
            m.status = MatchStatus.team1Wins;
          } else if (m.team2Points > m.team1Points) {
            m.status = MatchStatus.team2Wins;
          } else {
            m.status = MatchStatus.notStarted;
          }
          // else draw support
        } else {
          m.status = MatchStatus.notStarted;
        }

        if (m.team2Id === TOURNAMENT.BYE_ID) {
          m.status = MatchStatus.byeWin;
        }
      }
    });

    if (
      newRoundData.matches.every((m) => m.status !== MatchStatus.notStarted)
    ) {
      newRoundData.status = RoundStatus.completed;
    } else {
      newRoundData.status = RoundStatus.paired;
    }

    setRound(newRoundData);
    setHasChanges(true);
  };

  const handleRoundTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoundTime(event.target.value);
  };

  const updateRoundData = () => {
    if (roundData) {
      props.onEdit(props.roundNumber, roundData);
      setHasChanges(false);
    }
    setUpdating(false);
  };

  React.useEffect(() => {
    setRound(
      props.tournamentData.rounds.find(
        (r) => r.roundNumber === props.roundNumber
      )
    );
    setCanBeCancelled(
      props.tournamentData.rounds.findIndex(
        (r) => r.roundNumber === props.roundNumber + 1
      ) === -1 ||
        props.tournamentData.rounds.find(
          (r) => r.roundNumber === props.roundNumber + 1
        )?.status === RoundStatus.notStarted
    );
  }, [props.roundNumber]);

  return (
    <>
      <Grid
        item
        xs={12}
        key={`tournament-${props.roundNumber.toString()}-chosen-round`}
      >
        {roundData && roundData.status !== RoundStatus.notStarted ? (
          <>
            <p>
              Kierros {props.roundNumber} peliparit.{" "}
              {roundData.startingTime
                ? `Peliaika ${roundData.startingTime}`
                : ""}{" "}
            </p>

            <Grid
              container
              spacing={0.5}
              key={`tournament-${props.roundNumber.toString()}-pairs`}
              justifyContent="flex-start"
            >
              <Grid
                item
                xs={12}
                key={`tournament-match-update`}
                className={`${commonStyles.leftAlign} ${commonStyles.noPrint}`}
              >
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SearchIcon />}
                  variant="outlined"
                  onClick={() => {
                    setShowFindFieldModal(true);
                  }}
                >
                  Hae joukkueen kenttää
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
                key={`hastag`}
                className={`${commonStyles.leftAlign} ${commonStyles.noPrint}`}
              >
                <p># = Kenttänumero</p>
              </Grid>
              {isAuthenticated &&
                isClubOrganizerOrAdmin(user, store.organizerClubs) && (
                  <>
                    <Grid
                      item
                      xs={6}
                      key={`tournament-match-update`}
                      className={`${commonStyles.leftAlign} ${commonStyles.noPrint}`}
                    >
                      <LoadingButton
                        disabled={!hasChanges}
                        loading={updating}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        onClick={() => {
                          setUpdating(true);
                          updateRoundData();
                        }}
                      >
                        Vie muutokset
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      key={`tournament-match-cancel`}
                      className={`${commonStyles.rightAlign} ${commonStyles.noPrint}`}
                    >
                      <LoadingButton
                        disabled={!canBeCancelled}
                        loading={updating}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setShowConfirmCancel(true);
                        }}
                      >
                        Hylkää peliparit
                      </LoadingButton>
                    </Grid>
                  </>
                )}
              <Grid item xs={0.5} key={`tournament-match-field-header`}>
                <p className={commonStyles.noEmptyTopBottom}>
                  <b>#</b>
                </p>
              </Grid>
              <Grid item xs={4} key={`tournament-match-team1-header`}>
                <p className={commonStyles.noEmptyTopBottom}></p>
              </Grid>
              <Grid item xs={1} key={`tournament-match-points1-header`}>
                <p className={commonStyles.noEmptyTopBottom}></p>
              </Grid>
              <Grid item xs={0.5} key={`tournament-match-separator-header`}>
                <p className={commonStyles.noEmptyTopBottom}></p>
              </Grid>
              <Grid item xs={1} key={`tournament-match-points2-header`}>
                <p className={commonStyles.noEmptyTopBottom}></p>
              </Grid>
              <Grid item xs={4} key={`tournament-match-team2-header`}>
                <p className={commonStyles.noEmptyTopBottom}></p>
              </Grid>
              <Grid item xs={12} key={`tournament-match-divider-header`}>
                <hr />
              </Grid>
              {roundData.matches.map((m) => {
                return (
                  <Match
                    byePoints={props.tournamentData.roundRules.byePoints}
                    matchData={m}
                    teamData={props.tournamentData.teams}
                    key={`tournament-match-${m.matchId}`}
                    onMatchEdit={handleMatchEdit}
                  />
                );
              })}
            </Grid>
          </>
        ) : (
          <>
            {isAuthenticated &&
              isClubOrganizerOrAdmin(user, store.organizerClubs) &&
              props.lastFinishedRound === props.roundNumber - 1 && (
                <>
                  <Grid
                    container
                    spacing={0.5}
                    key={`tournament-${props.roundNumber.toString()}-pairing`}
                    justifyContent="flex-start"
                  >
                    {(props.tournamentData.roundRules.format ===
                      TournamentFormat.swiss ||
                      (props.tournamentData.roundRules.format ===
                        TournamentFormat.circelBoule &&
                        props.tournamentData.boules &&
                        props.tournamentData.boules.every(
                          (b) => b.teamIds.length === 4
                        ))) && (
                      <>
                        <Grid
                          item
                          xs={12}
                          key={`tournament-${props.roundNumber.toString()}-round-starting-time`}
                          className={commonStyles.leftAlign}
                        >
                          <FormControl fullWidth>
                            <TextField
                              id="roundTime"
                              label="Kierrosaika"
                              value={roundTime}
                              onChange={handleRoundTimeChange}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          key={`tournament-${props.roundNumber.toString()}-pair-round`}
                          className={commonStyles.rightAlign}
                        >
                          <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            onClick={handleRoundPair}
                          >
                            Luo peliparit
                          </LoadingButton>
                        </Grid>
                      </>
                    )}
                    {props.tournamentData.roundRules.format ===
                      TournamentFormat.circelBoule &&
                      props.tournamentData.boules &&
                      props.tournamentData.boules.some(
                        (b) => b.teamIds.length !== 4
                      ) && <p>Poulet eivät ole vielä täynnä.</p>}
                    {props.tournamentData.roundRules.format ===
                      TournamentFormat.swiss && (
                      <Grid
                        item
                        xs={6}
                        key={`tournament-${props.roundNumber.toString()}-manual-pair-round`}
                        className={commonStyles.leftAlign}
                      >
                        <LoadingButton
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="outlined"
                          onClick={() => {
                            setShowManualPair(true);
                          }}
                        >
                          Manuaalinen paritus
                        </LoadingButton>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            {(!isAuthenticated ||
              !isClubOrganizerOrAdmin(user, store.organizerClubs)) &&
              props.roundNumber === 1 && (
                <p>Kierroksen pelipareja ei ole pielä muodostettu.</p>
              )}
            {props.lastFinishedRound !== props.roundNumber - 1 && (
              <p>Edellinen kierros ei ole vielä päättynyt.</p>
            )}
          </>
        )}
      </Grid>
      <YesNoModal
        id="cancel-round"
        label="Haluatko varmasti hylätä peliparit?"
        onCancel={() => {
          setShowConfirmCancel(false);
        }}
        onNo={() => {
          setShowConfirmCancel(false);
        }}
        onYes={() => {
          clearRound();
        }}
        open={showConfirmCancel}
      />
      <ManualPair
        onCancel={() => {
          setShowManualPair(false);
        }}
        onSave={handleManualPair}
        open={showManualPair}
        tournamentData={props.tournamentData}
        roundNumber={props.roundNumber}
      />
      <FindFieldModal
        id="find-field"
        open={showFindFieldModal}
        onClose={() => {
          setShowFindFieldModal(false);
        }}
        roundData={roundData}
        teams={props.tournamentData.teams}
      />
    </>
  );
};

export default Round;
