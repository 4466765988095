import { Box, Button, FormControl, Grid, IconButton, Modal } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import * as React from 'react';
import commonStyles from '../styles/common.module.scss';

interface YesNoModalProps {
    id: string;
    label: string;
    open: boolean;
    onCancel: () => void;
    onYes: () => void;
    onNo: () => void;
}

const YesNoModal: React.FC<YesNoModalProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(props.open);

    const handleClose = (_event: object, reason: string) => {
        if (reason === 'backdropClick') return;

        if (reason === 'yes') {
            props.onYes();
        }

        if (reason === 'no') {
            props.onNo();
        }

        if (reason === 'cancel') {
            props.onCancel();
        }

        setOpen(false);
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    return (
        <Modal
            key={`confirmation-modal-${props.id}`}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                className={commonStyles.modalStyle}
            >
                <Grid container spacing={2} key={`confirmation-modal-container-${props.id}`} justifyContent='flex-start'>
                    <Grid item xs={12} key={`confirmation-modal-cancel-${props.id}`} className={commonStyles.rightAlign}>
                        <IconButton aria-label="cancel"
                            className={commonStyles.noEmptyTopBottom}
                            size="medium"
                            color="error"
                            onClick={(event) => {
                                handleClose(event, 'cancel');
                            }}>
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} key={`confirmation-modal-label-${props.id}`}>
                        <FormControl fullWidth>
                            <p className={commonStyles.centered}>{props.label}</p>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} key={`confirmation-modal-yes-${props.id}`} className={commonStyles.rightAlign}>
                        <Button
                            variant="outlined"
                            startIcon={<SaveIcon />}
                            aria-label="yes"
                            onClick={(event) => {
                                handleClose(event, 'yes');
                            }}
                        >
                            Kyllä
                        </Button>
                    </Grid>
                    <Grid item xs={6} key={`confirmation-modal-no-${props.id}`} className={commonStyles.leftAlign}>
                        <Button
                            variant="outlined"
                            startIcon={<ClearIcon />}
                            aria-label="no"
                            color='error'
                            onClick={(event) => {
                                handleClose(event, 'no');
                            }}
                        >
                            Ei
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

export default YesNoModal;