import * as React from "react";
import Event from "../common/components/event";
import { IEvent, IEvents } from "../common/interfaces/IEvent";
import { fetchData } from "../services/blobService";
import store from "../stores/eventStore";

interface AddEventPageProps {}

const AddEventPage: React.FC<AddEventPageProps> = () => {
  const eventsBlobName = "events.json";
  const eventsContainerName = "events";

  const [eventData, setEventData] = React.useState<IEvent | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (store.eventId) {
      fetchData(eventsBlobName, eventsContainerName).then((item) => {
        const events = JSON.parse(item as string) as IEvents;
        const event = events.events.find((e) => e.eventId === store.eventId)!;
        setEventData(event);
      });
    }
  }, []);

  const getEventData = () => {
    return eventData;
  };

  return (
    <>
      {eventData ? (
        <Event add={false} eventInfo={getEventData()}></Event>
      ) : (
        <Event add={true}></Event>
      )}
    </>
  );
};

export default AddEventPage;
